import React, { useState, useEffect } from "react";
import Header from "../../../components/header";
import { useLocation, useNavigate } from "react-router-dom";
import { Container, Row, Col, Form, Button } from "bootstrap-4-react";
import axios from "axios";
import API_PATHS from "../../../constants/apiPaths";
import MySelect from "../../../components/mySelect";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { url } from "../../../constants/url";
import "./cardLoad.css";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import { ProgressBar } from "react-loader-spinner";
import { getCookie } from "../../../Utils/cookieHandling";
import moment from "moment";
toast.configure();

const options = [
  { value: 1, label: "01" },
  { value: 2, label: "02" },
  { value: 3, label: "03" },
  { value: 4, label: "04" },
  { value: 5, label: "05" },
  { value: 6, label: "06" },
  { value: 7, label: "07" },
  { value: 8, label: "08" },
  { value: 9, label: "09" },
  { value: 10, label: "10" },
  { value: 11, label: "11" },
  { value: 12, label: "12" },
];

const CardLoad = (props) => {
  const { email, userType, accessToken } = useSelector((state) => state);
  const { formik } = props;
  const { values, errors, touched } = formik;
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);
  const details = props.data;
  const location = useLocation();
  const [cvv, setCvv] = useState();
  const [cardNumber, setCardNumber] = useState();
  let card = " ";
  const [amount, setAmount] = useState(0);
  const navigate = useNavigate();

  const [month, setMonth] = useState();
  const [year, setYear] = useState();
  const [yearList, setYearList] = useState([]);
  const [pass, setPass] = useState(false);
  const [response, setResponse] = useState("");

  useEffect(() => {
    values.expiryMonth = month;
    values.expiryYear = year;
    card = location.state.applicationDetails.card_type || " ";
    setAmount(card === "JDBC Platinum card" ? 130 : 250);
    getDropList();
  }, [location.state.applicationDetails.card_type, month, year]);

  const getDropList = () => {
    const year = new Date().getFullYear();

    for (let i = 0; i <= 6; i++) {
      yearList.push({
        value: year + i,
        label: year + i,
      });
    }
  };

  useEffect(() => {
    values.cardNumber = cardNumber;
    values.cvv = cvv;
  }, [cvv, cardNumber]);

  const handlePayment = () => {
    if (!values.cardNumber) {
      toast.error("Card required");
      return;
    }
    if (values.cardNumber.replace(/\s/g, "").length != 16) {
      toast.error("Card Number Must be Of 16 Digits");
      return;
    }
    if (!values.cvv) {
      toast.error("CVV required");
      return;
    }
    if (!values.expiryMonth || !values.expiryYear) {
      toast.error("Expiry Date Required");
      return;
    }
    if (!values.cardHolderName) {
      toast.error("Card Holder Name required");
      return;
    }
    // setIsLoading(true);
    setPass(true);
    const obj = {
      email: email,
      cardNumber: Number(values.cardNumber.replace(/\s+/g, "")),
      payment_type: "stripe",
      month: Number(values.expiryMonth),
      year: Number(values.expiryYear),
      cvv: Number(values.cvv),
      amount: Number(details.amount),
      load_amount: Number(details.load_amount),
      percentage_amount: Number(details.percentage_amount),
      status: "Pending",
    };
    axios
      .post(API_PATHS.CARD_LOAD, obj, {
        headers: {
          accepts: "application/json",
          Authorization: `${getCookie("c4")} ${getCookie("c3")}`,
        },
      })
      .then((res) => {
        if (res.data.message) {
          if (res.data.message === "Payment Completed") {
            toast(res.data.message);
            setResponse(res.data);
            setShow(true);
          } else {
            toast(JSON.stringify(res.data.message));
          }
        } else {
          toast("Incorrect details");
        }
        setPass(false);
      });
  };

  return (
    <>
      <Header title="Payment Process" />
      <Container fluid>
        {/* <span
          style={{
            justifyContent: "center",
            fontSize: "18px",
            lineHeight: "27px",
            fontWeight: "400",
            paddingTop: "30px",
          }}
        >
          JDB Payment Process for {location.state.applicationDetails.card_type}
        </span> */}
        <Row>
          <Col lg={4} sm={12} style={{ padding: "15px", paddingTop: "50px" }}>
            <span>Amount</span>
            <Form.Input
              type="text"
              value={`${details.amount} USD`}
              readOnly
              style={{ padding: 20 }}
            />
          </Col>
          {/* <Col lg={4} sm={12} style={{ padding: "15px", paddingTop: "50px" }}>
            <span>Base Amount</span>
            <Form.Input
              type="text"
              value={`${details.load_amount} USD`}
              readOnly
              style={{ width: "50%" }}
            />
          </Col>
          <Col lg={4} sm={12} style={{ padding: "15px", paddingTop: "50px" }}>
            <span>Fee</span>
            <Form.Input
              type="text"
              value={`${details.percentage_amount} USD`}
              readOnly
              style={{ width: "50%" }}
            />
          </Col> */}
        </Row>
        <Row>
          <Col lg={6} sm={12} style={{ padding: "15px", paddingTop: "20px" }}>
            <span>Card Number </span>
            <br />
            <input
              type="text"
              // pattern="[0-9]*"
              value={cardNumber}
              onChange={(e) => {
                if (e.target.value.length > 22) {
                } else {
                  setCardNumber(
                    e.target.value
                      .replace(/\D/g, "")
                      .replace(/\s?/g, "")
                      .replace(/(\d{4})/g, "$1  ")
                      .trim()
                  );
                }
              }}
              onBlur={formik.handleBlur}
              style={{
                width: "70%",
                height: "35px",
                borderRadius: "10px",
                padding: "10px",
              }}
              maxLength="22"
              id="cardNumber"
              name="cardNumber"
              className={
                errors.cardNumber && touched.cardNumber
                  ? "text-input-error"
                  : "text-input"
              }
            />
            {errors.cardNumber && touched.cardNumber && (
              <div style={{ color: "red" }}>{errors.cardNumber}</div>
            )}
          </Col>
        </Row>
        <Row>
          <Col lg={4} sm={6} style={{ padding: "15px", paddingTop: "20px" }}>
            <span>CVV</span>
            <br />
            <input
              type="text"
              value={cvv}
              onChange={(e) => {
                if (e.target.value.length > 3) {
                } else {
                  setCvv(e.target.value.replace(/\D/g, ""));
                }
              }}
              onBlur={formik.handleBlur}
              id="cvv"
              maxLength="3"
              style={{
                width: "70%",
                height: "35px",
                borderRadius: "10px",
                padding: "10px",
                width: "40%",
              }}
              name="cvv"
              className={
                errors.cvv && touched.cvv ? "text-input-error" : "text-input"
              }
            />
            {errors.cvv && touched.cvv && (
              <div style={{ color: "red", fontSize: "12px" }}>{errors.cvv}</div>
            )}
          </Col>
        </Row>
        <Row>
          <Col
            lg={2}
            sm={6}
            style={{
              padding: "15px",
              paddingTop: "20px",
              flexDirection: "column",
            }}
          >
            <span>Expiry Date</span>
            <MySelect
              id="expiryMonth"
              options={options}
              value={values.expiryMonth}
              onChange={props.formik.setFieldValue}
              onBlur={props.formik.setFieldTouched}
              error={errors.expiryMonth}
              touched={touched.expiryMonth}
            />
          </Col>

          <Col lg={3} sm={6} style={{ padding: "15px", paddingTop: "45px" }}>
            <MySelect
              id="expiryYear"
              options={yearList}
              value={values.expiryYear}
              onChange={props.formik.setFieldValue}
              onBlur={props.formik.setFieldTouched}
              error={errors.expiryYear}
              touched={touched.expiryYear}
            />
            {/* {errors.expiryYear && touched.expiryYear && (
              <div style={{ color: "red", fontSize: "12px" }}>
                {errors.expiryYear}
              </div>
            )} */}
          </Col>
        </Row>
        <Row>
          <Col lg={6} sm={12} style={{ padding: "15px", paddingTop: "50px" }}>
            <span>Name on the Card </span>
            <Form.Input
              type="text"
              value={values.cardHolderName}
              onChange={props.formik.handleChange}
              onBlur={props.formik.handleBlur}
              style={{ width: "70%" }}
              id="cardHolderName"
              name="cardHolderName"
              className={
                errors.cardHolderName && touched.cardHolderName
                  ? "text-input-error"
                  : "text-input"
              }
            />
            {errors.cardHolderName && touched.cardHolderName && (
              <div style={{ color: "red" }}>{errors.cardHolderName}</div>
            )}
          </Col>
          <Col lg={6} sm={12} style={{ padding: "15px", paddingTop: "70px" }}>
            {pass ? (
              <ProgressBar
                height="80"
                width="80"
                ariaLabel="progress-bar-loading"
                wrapperStyle={{}}
                wrapperClass="progress-bar-wrapper"
                borderColor="#F4442E"
                barColor="#51E5FF"
              />
            ) : (
              <Button
                id="login-button"
                // disabled={!(props.formik.isValid && props.formik.dirty)}
                onClick={() => handlePayment()}
              >
                Next
              </Button>
            )}
          </Col>
        </Row>

        <Modal show={show}>
          <Modal.Header>
            <Modal.Title>Payment Successful</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p></p>
            <Row>
              <Col>Email : </Col>
              <Col className="response">
                {response ? response.data.email : ""}
              </Col>
            </Row>
            <Row>
              <Col> Transaction ID : </Col>
              <Col className="response">
                {response ? response.data.transaction_id : ""}
              </Col>
            </Row>
            <Row>
              <Col> Card Number : </Col>
              <Col className="response">
                {response ? response.data.cardNumber : ""}
              </Col>
            </Row>
            <Row>
              <Col>Loaded Amount : </Col>
              <Col className="response">
                {response ? response.data.load_amount : ""}
              </Col>
            </Row>
            <Row>
              <Col> Currency : </Col>
              <Col className="response">
                {response ? response.data.currency.toUpperCase() : ""}
              </Col>
            </Row>
            <Row>
              <Col> Status : </Col>
              <Col className="response">
                {response ? response.data.status : ""}{" "}
              </Col>
            </Row>
            <Row>
              <Col> Transaction Date : </Col>
              <Col className="response">
                {response ? `${moment(response?.data?.createdAt).format(
                  "DD MM YYYY"
                )}, ${moment(response?.data?.createdAt).format("hh:mm A")}` : ""}
              </Col>
            </Row>
            <p></p>
            <p style={{ textAlign: "center" }}>
              {/* Please wait while the admin approves your Payment. */}
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={() => navigate("/logged/dashboard")}
            >
              Click to Dashboard
            </Button>
          </Modal.Footer>
        </Modal>
        <ToastContainer />
      </Container>
    </>
  );
};

export default CardLoad;
