import { Container, Row, Col, Form } from "bootstrap-4-react";
import { Button } from "bootstrap-4-react/lib/components";
import { sendSignInLinkToEmail } from "firebase/auth";
import React, { useState, useEffect } from "react";
import "./updateEmail.css";
import axios from "axios";
import API_PATHS from "../../../constants/apiPaths";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector, useDispatch } from "react-redux";
import { ProgressBar } from "react-loader-spinner";
import { getCookie, setCookie } from "../../../Utils/cookieHandling";
import { addEmail } from "../../../redux/actions";

const UpdateEmail = (formik) => {
  const { values, errors, touched } = formik;
  const { email, id, accessToken, userType } = useSelector((state) => state);
  const notify = () => toast.success("OTP sent successfully");
  const [pass, setPass] = useState(false);
  const dispatch = useDispatch();
  const [sso, setSso] = useState(false);
  const [singpass, SetSingPass] = useState(false);
  useEffect(() => {
    if ((userType === "ssoUser") || (userType === "SSO_USER")) {
      setSso(true);
    }
    else {
      setSso(false);
    }
    if (userType === "SINGPASS") {
      SetSingPass(true);
    } else {
      SetSingPass(false);
    }
  }, [userType]);

  const sendOTP = () => {
    if (!values.newEmail) {
      toast("New Email Required");
      return false;
    }
    const obj = {
      id: id,
      email: values.newEmail,
    };
    axios
      .request({
        method: "post",
        url: API_PATHS.UPDATE_OTP,
        data: obj,
        crossDomain: "true",
        headers: {
          accepts: "application/json",
          Authorization: `${getCookie("c4")} ${getCookie("c3")}`,
        },
      })
      .then((res) => {
        notify();
      });
  };

  const updateEmail = () => {
    if (!values.newEmail) {
      toast("New Email Required");
      return false;
    }
    if (!values.confirmEmail) {
      toast("Confirm New Email Required");
      return false;
    }
    if (values.newEmail !== values.confirmEmail) {
      toast("New Email and Confirm New Email Should be same");
      return false;
    }
    if (!values.otp) {
      toast("OTP Required");
      return false;
    }
    setPass(true);
    const obj = {
      id: id,
      email: values.newEmail,
      OTP: values.otp,
    };

    axios
      .request({
        method: "post",
        url: API_PATHS.UPDATE_EMAIL,
        data: obj,
        crossDomain: "true",
        headers: {
          accepts: "application/json",
          Authorization: `${getCookie("c4")} ${getCookie("c3")}`,
        },
      })
      .then((res) => {
        toast(res.data.message);
        setPass(false);
        if (res.data.message === "Email updated successfully") {
          setPass(false);
          setCookie("c1", values.newEmail);
          dispatch(addEmail(values.newEmail));
        }
      });
  };
  return (
    <>
      <Container fluid>
        <Row>
          <Col lg={6} sm={6} style={{ marginTop: "30px" }}>
            <span>Current Email Address</span>
            <Form.Input type="text" value={email} readOnly />
          </Col>
          <Col lg={6} sm={6} style={{ paddingTop: "30px" }}>
            <span>New Email</span>
            <Form.Input
              type="text"
              readOnly={sso || singpass}
              value={values.newEmail}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={
                errors.newEmail && touched.newEmail && !sso && !singpass
                  ? "text-input-error"
                  : "text-input"
              }
              name="newEmail"
            />
            {errors.newEmail && !sso && !singpass && touched.newEmail && (
              <div style={{ color: "red" }}>{errors.newEmail}</div>
            )}
            <span style={{ float: "right", padding: 4 }}>
              {values.newEmail && (
                <Button
                  style={{
                    backgroundColor: "#de2834 ",
                    color: "#fff",
                    float: "right",
                    fontSize: "7px",
                    height: "20px",
                    padding: "5px",
                  }}
                  onClick={(e) => sendOTP(e)}
                >
                  Send OTP
                </Button>
              )}
            </span>
          </Col>
          <Col lg={6} sm={6} style={{ paddingTop: "30px" }}>
            <span>Confirm New Email</span>
            <Form.Input
              type="text"
              value={values.confirmEmail}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="confirmEmail"
              readOnly={sso || singpass}
              className={
                errors.confirmEmail && touched.confirmEmail && !sso && !singpass
                  ? "text-input-error"
                  : "text-input"
              }
            />
            {errors.confirmEmail && !sso && !singpass && touched.confirmEmail && (
              <div style={{ color: "red" }}>{errors.confirmEmail}</div>
            )}
          </Col>
          <Col lg={6} sm={6} style={{ paddingTop: "30px" }}>
            <span>OTP</span>
            <Form.Input
              type="number"
              value={values.otp}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              readOnly={sso || singpass}
              name="otp"
              className={
                errors.otp && touched.otp && !sso && !singpass ? "text-input-error" : "text-input"
              }
            />
            {errors.otp && !sso && !singpass && touched.otp && (
              <div style={{ color: "red" }}>{errors.otp}</div>
            )}
          </Col>
          <Col className="text-center" style={{ paddingTop: "30px" }}>
            {pass ? (
              <ProgressBar
                height="80"
                width="80"
                ariaLabel="progress-bar-loading"
                wrapperStyle={{}}
                wrapperClass="progress-bar-wrapper"
                borderColor="#F4442E"
                barColor="#51E5FF"
              />
            ) : (
              <button
                style={{
                  background:
                    "linear-gradient(180deg, #F74D58 0%, #E93944 54.91%, #DE2834 100%)",
                  border: "1px solid #DE2834",
                  boxshadow: "2px 3px 28px rgba(222, 40, 52, 0.45)",
                  borderRadius: "60px",
                  color: "#fff",
                  width: "100px",
                  padding: 8,
                  opacity: formik.isValid && formik.dirty ? 1 : 0.5,
                }}
                disabled={!(formik.isValid && formik.dirty)}
                onClick={() => updateEmail()}
              >
                Update
              </button>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default UpdateEmail;
