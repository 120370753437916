import React from "react";
import Header from "../../../components/header";
import { Image, Container, Row, Col } from "react-bootstrap";

const CardActivation = () => {
  return (
    <>
      <Header title="Congratulations" />
      <Container fluid>
        <div style={{ textAlign: "center", display: "block" }}>
          <Image
            src={require("../../../assets/success.jpeg")}
            height={400}
            width={450}
            style={{
              resizeMode: "contain",
              alignSelf: "center",
              padding: "10px",
            }}
          />
        </div>
        <Row justifyContent="center">
          <Col style={{ justifyContent: "center", textAlign: "center", marginBottom:"40px" }}>
            <span style={{ fontSize: "29px", fontWeight: "800" }}>
              Congratulations
            </span>
            <br />
            <span>Your Card is being Activated</span>
            <br />
            <span>
              Lorem ipsum dolor sit amet . The graphic and typographic operators
              know this well, 
            </span>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CardActivation;
