import React, { useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import UpdatePassword from "./updatePassword";


const UpdatePasswordProcess = () => {
  const initialValues = {
    password:"",
    newPassword: "",
    confirmPassword: "",
    otp: "",
  };

  const validationSchema = Yup.object({
    password: Yup
    .string()
    .matches(/\w*[a-z]\w*/, "Current Password must have a lowercase letter")
    .matches(/\w*[A-Z]\w*/, "Current Password must have a uppercase letter")
    .matches(/\d/, "Current Password must have a number")
    .matches(
      /[!@#$%^&*()\-_"=+{};:,<.>]/,
      "Current Password must have a special character"
    )
    .min(8, ({ min }) => `Current Password must be at least 8 characters`)
    .required("Current Password is required"),
  newPassword: Yup
    .string()
    .matches(/\w*[a-z]\w*/, "New Password must have a lowercase letter")
    .matches(/\w*[A-Z]\w*/, "New Password must have a uppercase letter")
    .matches(/\d/, "New Password must have a number")
    .matches(
      /[!@#$%^&*()\-_"=+{};:,<.>]/,
      "New Password must have a special character"
    )
    .min(8, ({ min }) => `New Password must be at least 8 characters`)
    .required("New Password is required"),
  confirmPassword: Yup
    .string()
    .matches(/\w*[a-z]\w*/, "Confirm Password must have a lowercase letter")
    .matches(/\w*[A-Z]\w*/, "Confirm Password must have a uppercase letter")
    .matches(/\d/, "Confirm Password must have a number")
    .matches(
      /[!@#$%^&*()\-_"=+{};:,<.>]/,
      "Confirm Password must have a special character"
    )
    .min(8, ({ min }) => `Confirm Password must be at least 8 characters`)
    .oneOf([Yup.ref("newPassword"), null], "Confirm Password must match")
    .required("Confirm Password is required"),
  });
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
        {(formik) => <UpdatePassword {...formik} />}
    </Formik>
  );
};

export default UpdatePasswordProcess;
