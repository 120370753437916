import "./App.css";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import PaymentPlanetLanding from "./Views/paymentPlanet/paymentPlanetLanding";
import LoginProcess from "./Views/login/loginProcess";
import RegisterProcess from "./Views/register/registerProcess";
import SideBarRoutes from "./sideBarRoutes";
import ForgotPasswordProcess from "./Views/forgotpassword/forgotPasswordProcess";
import ResetPasswordProcess from "./Views/resetPassword/resetPasswordProcess";
import GProcess from "./Views/register/gAuth/gProcess";
import Singpass from "./Views/register/singpass/singpass";
import Verify2FAProcess from "./Views/register/verify2FAProcess";
import Privacypolicy from "./Views/privacyPolicy/privacyPolicies";
import TermsConditions from "./Views/termsConditions/termsCondition";

const App = () => {
  return (
    <>
      <Routes>
        <Route exact path="/" element={<PaymentPlanetLanding />} />
        <Route path="/login" element={<LoginProcess />} />
        <Route path="/privacyPolicy" element={<Privacypolicy />} />
        <Route path="/terms-and-conditions" element={<TermsConditions />} />
        <Route path="/register" element={<RegisterProcess />} />
        <Route path="/logged/*" element={<SideBarRoutes />} />
        <Route path="/forgotPassword" element={<ForgotPasswordProcess />} />
        <Route path="/passwordReset" element={<ResetPasswordProcess />} />
        <Route path="/singpassVerify" element={<Singpass />} />
        <Route path="2fa" element={<GProcess />} />
        <Route path="verify2fa" element={<Verify2FAProcess />} />
      </Routes>
    </>
  );
};

export default App;
