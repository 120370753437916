export const JDBPrograms = [
	{
		id: 1,
		programTitle: "JDB Card Program",
		cardType: "JDB Premium Black",
		benefits: ["High limits ", "Visa", "ATM Cash Withdrawls"],
		image: require("../assets/Premium Black.png"),
		cardPrice: " 130 USD",
		max_limit: "30000",
	},
	{
		id: 2,
		programTitle: "JDB Card Program",
		cardType: "JDB Black Metal",
		benefits: ["High limits", "Visa", "ATM Cash Withdrawls"],
		image: require("../assets/black metal.png"),
		cardPrice: " 250 USD",
		max_limit: "100000",
	},
	{
		id: 3,
		programTitle: "JDB Card Program",
		cardType: "JDB Silver Metal",
		benefits: ["High limits", "Visa", "ATM Cash Withdrawls"],
		image: require("../assets/Silver metal.png"),
		cardPrice: " 250 USD",
		max_limit: "120000",
	},
	{
		id: 4,
		programTitle: "JDB Card Program",
		cardType: "JDB Gold Metal",
		benefits: ["High limits", "Visa", "ATM Cash Withdrawls"],
		image: require("../assets/Gold metal.png"),
		cardPrice: " 250 USD",
		max_limit: "150000",
	},
];
