import React, { useState, useEffect } from "react";
import Header from "../../../components/header";
import { Container, Row, Col, Form, Button } from "bootstrap-4-react";
import PhoneInput from "react-phone-number-input";
import "./nium.css";
import { useNavigate } from "react-router-dom";
import ReactSelect from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

const NIUMApplication = (formik) => {
  const { values, errors, touched } = formik;
  const [phone, setPhone] = useState(values.phone);
  const [titles, setTitles] = useState({ value: "mr", label: "Mr" });
  const navigate = useNavigate();

  const title = [
    { value: "mr", label: "Mr" },
    { value: "mrs", label: "Mrs" },
    { value: "ms", label: "Ms" },
  ];

  useEffect(() => {
    values.phone = phone;
    values.title = titles;
  }, [phone]);

  const handleClick = () => {
    const obj = {
      title: values.title.value,
      first_name: values.firstName,
      last_name: values.lastName,
      email: values.email,
      card_type: " ",
      dob: values.dob,
      nationality: values.nationality,
      contactNumber:Number(values.phone),
      address1: values.address1,
      address2: values.address2,
      pincode: values.postalCode,
      countryCode: 0,
      unit_number: values.unitNumber,
      floor: values.floor,
    };

    navigate("/logged/card/paymentNium", { state: { application: obj } });
  };
  return (
    <>
      <Header title="Card Application" />
      <Container fluid>
        <span
          style={{
            justifyContent: "center",
            fontSize: "18px",
            lineHeight: "27px",
            fontWeight: "400",
          }}
        >
          {" "}
          NIUM
        </span>
      </Container>
      <Container fluid>
        <Row style={{ paddingTop: "40px" }}>
          <Col lg={6} sm={6}>
            <span
              style={{
                fontSize: "26px",
                lineHeight: "39px",
                fontWeight: "600",
              }}
            >
              Personal Details
            </span>
          </Col>
        </Row>
        <Row style={{ paddingTop: "20px" }}>
          <Col lg={6} sm={12} style={{ padding: "15px" }}>
            <span>Title</span>
            <ReactSelect options={title} value={titles} onChange={setTitles} />
            {errors.title && touched.title && (
              <div style={{ color: "red" }}>{errors.title}</div>
            )}
          </Col>
          <Col lg={6} sm={12} style={{ padding: "15px" }}>
            <span>First name </span>
            <Form.Input
              type="text"
              value={values.firstName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              id="firstName"
              name="firstName"
              className={
                errors.firstName && touched.firstName
                  ? "text-input-error"
                  : "text-input"
              }
            />
            {errors.firstName && touched.firstName && (
              <div style={{ color: "red" }}>{errors.firstName}</div>
            )}
          </Col>
        </Row>
        <Row style={{ marginTop: "20px" }}>
          <Col lg={6} sm={12} style={{ padding: "15px" }}>
            <span>Last name</span>
            <Form.Input
              type="text"
              value={values.lastName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              id="lastName"
              name="lastName"
              className={
                errors.lastName && touched.lastName
                  ? "text-input-error"
                  : "text-input"
              }
            />
            {errors.lastName && touched.lastName && (
              <div style={{ color: "red" }}>{errors.lastName}</div>
            )}
          </Col>
          <Col lg={6} sm={12} style={{ padding: "15px" }}>
            <span>Mobile Number</span>
            <PhoneInput
              international
              defaultCountry="SG"
              value={phone}
              limitMaxLength={10}
              onChange={setPhone}
              onBlur={formik.handleBlur}
              style={{
                lineHeight: "30px",
                border: "0px",
                borderRadius: "25px",
              }}
              id="phone"
              name="phone"
              className={
                errors.phone && touched.phone
                  ? "text-input-error"
                  : "text-input"
              }
            />
            {errors.phone && touched.phone && (
              <div style={{ color: "red" }}>{errors.phone}</div>
            )}
          </Col>
        </Row>
        <Row style={{ marginTop: "20px" }}>
          <Col lg={6} sm={12} style={{ padding: "15px" }}>
            <span>Nationality</span>
            <Form.Input
              type="text"
              value={values.nationality}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              id="nationality"
              name="nationality"
              className={
                errors.nationality && touched.nationality
                  ? "text-input-error"
                  : "text-input"
              }
            />
            {errors.nationality && touched.nationality && (
              <div style={{ color: "red" }}>{errors.nationality}</div>
            )}
          </Col>
          <Col lg={6} sm={12} style={{ padding: "15px" }}>
            <span>Date of Birth</span>
            <Form.Input
              type="date"
              value={values.dob}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              id="dob"
              name="dob"
              className={
                errors.dob && touched.dob ? "text-input-error" : "text-input"
              }
            />
            {errors.dob && touched.dob && (
              <div style={{ color: "red" }}>{errors.dob}</div>
            )}
          </Col>
        </Row>
        <Row style={{ paddingTop: "40px" }}>
          <Col lg={6} sm={6}>
            <span
              style={{
                fontSize: "26px",
                lineHeight: "39px",
                fontWeight: "600",
              }}
            >
              Residental Details
            </span>
          </Col>
        </Row>
        <Row>
          <Col lg={12} sm={12} style={{ padding: "15px" }}>
            <span>Floor</span>
            <Form.Textarea
              type="text"
              value={values.floor}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              id="floor"
              name="floor"
              className={
                errors.floor && touched.floor
                  ? "text-input-error"
                  : "text-input"
              }
            />
            {errors.floor && touched.floor && (
              <div style={{ color: "red" }}>{errors.floor}</div>
            )}
          </Col>
        </Row>
        <Row>
          <Col lg={6} sm={12} style={{ padding: "15px" }}>
            <span>Address 1 :</span>
            <Form.Textarea
              type="text"
              value={values.address1}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              id="address1"
              name="address1"
              className={
                errors.address1 && touched.address1
                  ? "text-input-error"
                  : "text-input"
              }
            />
            {errors.address1 && touched.address1 && (
              <div style={{ color: "red" }}>{errors.address1}</div>
            )}
          </Col>
          <Col lg={6} sm={12} style={{ padding: "15px" }}>
            <span> Address 2 :</span>
            <Form.Textarea
              type="text"
              value={values.address2}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              id="address2"
              name="address2"
              className={
                errors.address2 && touched.address2
                  ? "text-input-error"
                  : "text-input"
              }
            />
            {errors.address2 && touched.address2 && (
              <div style={{ color: "red" }}>{errors.address2}</div>
            )}
          </Col>
        </Row>
        <Row>
          <Col lg={6} sm={12} style={{ padding: "15px" }}>
            <span>Postal Code</span>
            <Form.Input
              type="text"
              value={values.postalCode}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              id="postalCode"
              name="postalCode"
              className={
                errors.postalCode && touched.postalCode
                  ? "text-input-error"
                  : "text-input"
              }
            />
            {errors.postalCode && touched.postalCode && (
              <div style={{ color: "red" }}>{errors.postalCode}</div>
            )}
          </Col>
          <Col lg={6} sm={12} style={{ padding: "15px" }}>
            <span>Unit Number</span>
            <Form.Input
              type="text"
              value={values.unitNumber}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              id="unitNumber"
              name="unitNumber"
              className={
                errors.unitNumber && touched.unitNumber
                  ? "text-input-error"
                  : "text-input"
              }
            />
            {errors.unitNumber && touched.unitNumber && (
              <div style={{ color: "red" }}>{errors.unitNumber}</div>
            )}
          </Col>
        </Row>
        <Row justifyContent="center" style={{ paddingTop: "20px" }}>
          <Button
            id="next-button"
            disabled={!(formik.isValid && formik.dirty)}
            onClick={handleClick}
          >
            Next
          </Button>
        </Row>
      </Container>
      <ToastContainer />
    </>
  );
};

export default NIUMApplication;
