import React from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import StripeJDB from "./stripe";
import valid from "card-validator";
import { useLocation } from "react-router-dom";

const validationSchema = Yup.object({
  cardNumber: Yup.string().test(
    "len",
    "Must be exactly 16 digits",
    (val) => val && val.toString().replace(/\s+/g, '').length === 16)
    .required("Card Number cannot be blank"),
  // .string()
  // .test('test-number', // this is used internally by yup
  // 'Credit Card number is invalid', //validation message
  //  value => valid.number(value).isValid),// return true false based on validation
  cvv:  Yup.string().test(
    "len",
    "Must be exactly 3 digits",
    (val) => val && val.toString().length === 3)
    .required("Card Number cannot be blank"),
  expiryMonth: Yup.number().required("Month cannot be empty"),
  expiryYear: Yup.number().required("Year cannot be blank"),
  cardHolderName: Yup.string().required("Enter the correct Card holder name"),
});


const StripePaymentProcess = () => {
  const location = useLocation()
  const details = location.state.applicationDetails
  const attachments = location.state.attachments
  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        cardNumber: "",
        cvv: "",
        expiryMonth: "",
        expiryYear :"",
        cardHolderName: "",
      }}
    >
      {(formik) => <StripeJDB  formik={formik} data={details} attachments={attachments}  />}
    </Formik>
  );
};

export default StripePaymentProcess;
