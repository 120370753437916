import React from 'react'
import * as Yup from "yup"
import{ Formik }from "formik"
import GoogleAuthenticator from './gauthenticator'
import { useNavigate, useLocation } from "react-router-dom";

const GProcess = () => {
  const location = useLocation();

  const validationSchema = Yup.object({
    otp: Yup.string().required("Please enter valid OTP"),
  });



  return (
    <Formik
    validationSchema={validationSchema}
    initialValues={{
      otp: "",
      type:location.state ? location.state.type : "",
      firstName: location.state ? location.state.firstName : "",
      lastName: location.state ? location.state.lastName : "",
      dob: location.state ? location.state.dob : "",
      email: location.state ? location.state.email : "",
      uinfin: location.state ? location.state.uinfin : "",
      gender: location.state ? location.state.gender : "",
      mobileCode: location.state ? location.state.mobileCode : "",
      mobile: location.state ? location.state.mobile : "",
      profilePic: location.state? location.state.profilePic : "",
      address1: location.state? location.state.address1 : "",
      address2: location.state? location.state.address2 : "",
      postalCode: location.state? location.state.postalCode : "",
      country: location.state? location.state.country : "",
      d_country: location.state? location.state.d_country : "",
      nationality: location.state? location.state.nationality : "",
    }}
  >
    {(formik) => <GoogleAuthenticator {...formik} />}
  </Formik>
  )
}

export default GProcess
