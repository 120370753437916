import React from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import ForgotPassword from "./forgotPassword";

const validationSchema = Yup.object({
  email: Yup.string().email().required("Please enter valid email"),
});

const ForgotPasswordProcess = () => {
  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        email: "",
      }}
    >
      {(formik) => <ForgotPassword {...formik} />}
    </Formik>
  );
};

export default ForgotPasswordProcess;
