import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import DashboardProcess from "./Views/dashboard/dashboardProcess.jsx";
import SideBar from "./components/sidebar";
import Profile from "./Views/profile/profile";
import CardsProgram from "./Views/cardsProgram";
import KycProcess from "./Views/Kyc/kycProcess.jsx";
import JDBProcess from "./Views/cardApplication/jdb/jdbProcess.jsx";
import NiumProcess from "./Views/cardApplication/nium/niumProcess.jsx";
import IdVerify from "./Views/cardApplication/cardSuccess/idVerify.jsx";
import CardVerify from "./Views/cardApplication/cardSuccess/cardVerify.jsx";
import CardActivation from "./Views/cardApplication/cardSuccess/card_activation.jsx";
import CardSuccess from "./Views/cardApplication/cardSuccess/cardSuccess.jsx";
import CardApplicationSucess from "./Views/cardApplication/cardSuccess/card_application_success";
import KycHoldingPage from "./Views/Kyc/kycHold.jsx";
import JDBProgram from "./Views/cardPrograms/jdb.jsx";
import NIUMProgram from "./Views/cardPrograms/nium.jsx";
import StripePaymentProcess from "./Views/stripe/jdbPayment/stripePaymentProcess";
import StripePaymentProcessNium from "./Views/stripe/NiumPayment/stripeProcess.jsx";
import CardStatus from "./Views/cardApplication/cardSuccess/cardStatus.jsx";
import { useNavigate, Navigate } from "react-router-dom";
import CardLoadProcess from "./Views/stripe/cardLoad/cardLoadProcess.jsx";
import API_PATHS from "./constants/apiPaths.js";
import axios from "axios";
import {
  addEmail,
  addFullName,
  addAccessToken,
  addPhotoURL,
  addFirstName,
  addLastName,
  addDOB,
  addCallingCode,
  addMobile,
  addGender,
  addUinfin,
  addId,
  addUserType,
  addKYC,
  addIsKycApproved,
} from "./redux/actions.js";
import { useDispatch } from "react-redux";
import { getCookie } from "./Utils/cookieHandling.js";
import { clearEverything } from "./Utils/clearAll.js";
import PendingPaymentProcess from "./Views/stripe/pendingPayment/PendingPaymentProcess.jsx";
import Pending from "./Views/cardPrograms/pending.jsx";

const SideBarRoutes = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const email = getCookie("c1");
  const name = getCookie("c2");
  const token = getCookie("c3");
  const userType = getCookie("c4");
  const SINGPASS_WITHOUT_EMAIL = getCookie("c6")
  dispatch(addEmail(email));
  dispatch(addFullName(name));
  dispatch(addAccessToken(token));
  // dispatch(addUserType(userType));
  useEffect(() => {
    if (SINGPASS_WITHOUT_EMAIL ?? "" === "") {
      if (email) {
        const timer = setTimeout(() => {
          axios
            .get(API_PATHS.USER_VERIFY + email, {
              headers: {
                Authorization: `${getCookie("c4")} ${getCookie("c3")}`,
              },
            })
            .then((res) => {
              if (!res.data.message) {
                logout()
              }
            }).catch((err) => {
              logout()
            })
        }, 2000);
      }
    }
    // return () => clearTimeout(timer);
  });
  const logout = () => {
    dispatch(addEmail(""));
    dispatch(addFullName(""));
    dispatch(addAccessToken(""));
    dispatch(addPhotoURL(""));
    dispatch(addFirstName(""));
    dispatch(addLastName(""));
    dispatch(addDOB(""));
    dispatch(addCallingCode(""));
    dispatch(addMobile(""));
    dispatch(addGender(""));
    dispatch(addUinfin(""));
    dispatch(addId(""));
    dispatch(addUserType(""));
    dispatch(addKYC(""));
    dispatch(addIsKycApproved(""));
    clearEverything();
    alert("You are logged out");
    navigate("/");
  }

  return (
    <>
      {email || SINGPASS_WITHOUT_EMAIL  ? (
        <SideBar>
          <Routes>
            <Route path="cardsProgram" element={<CardsProgram />} />
            <Route path="/card">
              <Route path="jdb" element={<JDBProgram />} />
              <Route path="jdbApply" element={<JDBProcess />} />
              <Route path="niumApply" element={<NiumProcess />} />
              <Route path="nium" element={<NIUMProgram />} />
              <Route path="verify" element={<IdVerify />} />
              <Route path="cardVerify" element={<CardVerify />} />
              <Route path="cardActivation" element={<CardActivation />} />
              <Route path="cardSuccess" element={<CardApplicationSucess />} />
              <Route path="paymentJdb" element={<StripePaymentProcess />} />
              <Route
                path="pendingPayment"
                element={<PendingPaymentProcess />}
              />
              <Route path="pending" element={<Pending />} />
              <Route
                path="paymentNium"
                element={<StripePaymentProcessNium />}
              />
              <Route path="cardLoad" element={<CardLoadProcess />} />
              <Route path="cardStatus" element={<CardStatus />} />
              <Route path="cardSuccess1" element={<CardSuccess />} />
            </Route>
            <Route path="dashboard" element={<DashboardProcess />} />
            <Route path="profile" element={<Profile />} />
            <Route path="kyc" element={<KycProcess />} />
            <Route path="kycHold" element={<KycHoldingPage />} />
          </Routes>
        </SideBar>
      ) : (
        <>
          <Navigate to="/login" replace />
        </>
      )}
    </>
  );
};

export default SideBarRoutes;
