import React from "react";
import { Card, Container, Row, Col } from "react-bootstrap";
import Header from "../components/header";
import { cardPrograms } from "../data/cardProgram";
import CardProgramDisplay from "../components/cardDisplay";

const CardsProgram = () => {
  return (
    <>
      <Header title="Cards Program" />
      <Container fluid>
        <CardProgramDisplay data={cardPrograms} />
      </Container>
    </>
  );
};

export default CardsProgram;
