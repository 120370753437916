import React, { useState, useEffect } from "react";
import { Container, Card, Row, Col, Image } from "react-bootstrap";
import { Form } from "bootstrap-4-react";
import "./header.css";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import API_PATHS from "../constants/apiPaths";
import axios from "axios";
import Select from "react-select";
import { Dropdown } from "bootstrap-4-react";
import SignOut from "../Views/signOut";
import { url } from "../constants/url";
import { useSelector, useDispatch } from "react-redux";
import { getCookie, setCookie } from "../Utils/cookieHandling";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  addFirstName,
  addLastName,
  addPhotoURL,
  addId,
  addKYC,
  addUinfin,
  addCallingCode,
  addDOB,
  addGender,
  addMobile,
  addUserType,
  addCardPath,
  addCardActivated,
  addCardNo,
  addIsKycApproved,
  setFirstLogin,
  addAddress1,
  addAddress2,
  addPostalCode,
  addNationality,
  addFullName,
  addCountry
} from "../redux/actions";

const Header = ({ title }) => {
  const [loaded, setLoaded] = useState(false);
  const navigate = useNavigate();
  const {
    photoURL,
    fullName,
    accessToken,
    userType,
    firstName,
    firstLogin,
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [cardPath, setCardPath] = useState("");
  const [show, setShow] = useState(false);
  const SINGPASS_WITHOUT_EMAIL = getCookie("c6")
  const email = getCookie("c1")

  const pic =
    "https://previews.123rf.com/images/apoev/apoev1904/apoev190400012/124108711-person-gray-photo-placeholder-woman-in-costume-on-white-background.jpg?fj=1";

  useEffect(() => {
    if (!firstName) {
      setLoaded(true);

      if (SINGPASS_WITHOUT_EMAIL != undefined || SINGPASS_WITHOUT_EMAIL != null || SINGPASS_WITHOUT_EMAIL != "") {
        getDetailsFromLocalStorage()
      }
    }
  });

  const handleClose = () => {
    setShow(false);
    axios.post(API_PATHS.ADMIN_USER + email, {
      headers: {
        Authorization: `${getCookie("c4")} ${getCookie("c3")}`,
      },
    });
    dispatch(setFirstLogin(false));
  };
  useEffect(() => {
    if (firstLogin === true) {
      setShow(true);
      dispatch(setFirstLogin(false));
    }
  }, []);
  useEffect(() => {
    if (email) {
      axios
        .get(API_PATHS.GET_DETAILS + email, {
          headers: {
            Authorization: `${getCookie("c4")} ${getCookie("c3")}`,
          },
        })
        .then((res) => {
          if (res?.data?.suspended == "1") {
            toast("Your Account has been suspended");
            setTimeout(() => navigate("/login"), 5000);
            return false;
          }
          dispatch(addUserType(res?.data?.user_type ? res?.data?.user_type : " "));
          if (res?.data?.first_name) { dispatch(addFirstName(res?.data?.first_name)); }
          if (res?.data?.last_name) { dispatch(addLastName(res?.data?.last_name)); }
          if (res?.data?.first_name) {
            dispatch(addFullName(`${res?.data?.first_name} ${res?.data?.last_name ? res?.data?.last_name : ""}`));
            setCookie(
              "c2",
              res?.data?.first_name
                ? `${res?.data?.first_name} ${res?.data?.last_name ? res?.data?.last_name : ""}`
                : "user "
            );
          }
          dispatch(addKYC(res?.data?.kyc));
          dispatch(addId(res?.data?.id));
          if (res?.data?.profile_pic) {
            dispatch(
              addPhotoURL(
                res?.data?.profile_pic
                  ? res?.data?.profile_pic
                  : photoURL
                    ? photoURL
                    : pic
              )
            );
          } else {
            dispatch(
              addPhotoURL(photoURL
                ? photoURL
                : pic
              )
            );
          }
          dispatch(
            addCardPath(
              res?.data?.payment_status === "paid"
                ? res?.data?.is_kyc_approved == "1"
                  ? res?.data?.card_activated === 1 || res?.data?.card_activated === 2
                    ? "/logged/card/jdb"
                    : "/logged/card/cardVerify"
                  : "/logged/kycHold"
                : res?.data?.payment_status === "pending"
                  ? "/logged/card/pending"
                  : "/logged/cardsProgram"
            )
          );
          dispatch(addIsKycApproved(res?.data?.is_kyc_approved));
          if (res?.data?.address1) { dispatch(addAddress1(res?.data?.address1)); }
          if (res?.data?.address2) { dispatch(addAddress2(res?.data?.address2)); }
          if (res?.data?.pincode) { dispatch(addPostalCode(res?.data?.pincode)); }
          if (res?.data?.countryName) { dispatch(addCountry(res.data?.countryName)); }
          if (res?.data?.jdbCardNumber1) { dispatch(addCardNo(res?.data?.jdbCardNumber1)); }
          if (res?.data?.card_activated === 1 || res?.data?.card_activated === 2) {
            dispatch(addCardActivated(true));
          }
          dispatch(addUserType(res?.data?.user_type));
          // dispatch(addState(res?.data?.state));
          // dispatch(addCity(res?.data?.city));
          // dispatch(addCountryFlag(res?.data?.d_country));
          // dispatch(addCardActivated(res?.data?.card_activated));



          if (res?.data?.nationality) { dispatch(addNationality(res?.data?.nationality)); }
          if (res?.data?.dob) { dispatch(addDOB(res?.data?.dob)); }
          if (res?.data?.uinfin) { dispatch(addUinfin(res?.data?.uinfin)); }
          if (res?.data?.gender) { dispatch(addGender(res?.data?.gender)); }
          if (res?.data?.contactNumber) { dispatch(addMobile(res?.data?.contactNumber?.toString())); }
          if (res?.data?.countryCode) { dispatch(addCallingCode(res?.data?.countryCode?.toString())); }
        });
    } else {
      getDetailsFromLocalStorage()
    }
  }, [loaded]);

  const getDetailsFromLocalStorage = () => {
    const loadedState = loadStateFromLocalStorage();
    if (loadedState) {
      dispatch(addFullName(`${loadedState?.firstName} ${loadedState?.lastName}`));
      dispatch(addFirstName(loadedState?.firstName));
      dispatch(addLastName(loadedState?.lastName));
      dispatch(addDOB(loadedState?.dob));
      dispatch(addUinfin(loadedState?.uinfin));
      dispatch(addGender(loadedState?.gender?.toLowerCase()));
      dispatch(addMobile(loadedState?.mobile));
      dispatch(addCallingCode(loadedState?.mobileCode));
      dispatch(addAddress1(loadedState?.address1));
      dispatch(addAddress2(loadedState?.address2));
      dispatch(addPostalCode(loadedState?.postalCode));
      dispatch(addCountry(loadedState?.country));
      dispatch(addNationality(loadedState?.nationality));
      dispatch(addPhotoURL(photoURL ? photoURL : pic));
      dispatch(addIsKycApproved(""));
      dispatch(addKYC(""));
      dispatch(addUserType("SINGPASS"))
    }
  }

  const loadStateFromLocalStorage = () => {
    try {
      const serializedState = localStorage.getItem('singpassUserState');
      if (serializedState === null) {
        // If there is no saved state, return undefined
        return undefined;
      }
      return JSON.parse(serializedState);
    } catch (error) {
      console.error('Error loading state from localStorage:', error);
      return undefined;
    }
  };
  return (
    <Container fluid style={{ paddingTop: "5px" }}>
      <Card
        body
        style={{
          // backgroundColor: "#fff",
          flexDirection: "row",
          width: "100%",
          margin: 0,
        }}
      >
        <Row>
          <Col>
            <h1
              style={{
                fontWeight: "600",
                fontSize: "34px",
                lineHeight: "51px",
                fontFamily: "poppins",
              }}
            >
              {title}
            </h1>
          </Col>
          <Col style={{ float: "right" }}>
            <ul
              style={{
                flexDirection: "row",
                display: "flex",
                listStyle: "none",
                float: "right",
              }}
            >
              {/* <li style={{ paddingTop: "5px" ,marginRight:"0.5rem" }}>
                <Form.Input
                  placeholder="Search"
                  style={{
                    width: "100%",
                    border: "1px solid border",
                    float: "right",

                  }}
                />
              </li> */}
              <li
                style={{
                  paddingRight: "15px",
                  float: "right",
                  paddingTop: "5px",
                }}
              >
                <div className="image-holder">
                  <Image
                    src={photoURL}
                    width={40}
                    height={40}
                    style={{
                      border: "1px border black",
                      float: "right",
                      borderStyle: "solid",
                      borderRadius: "20px",
                    }}
                  />
                </div>
              </li>
              <li style={{ paddingRight: "15px", paddingTop: "10px" }}>
                <Dropdown>
                  <Dropdown.Button id="dropdownMenuButton">
                    {fullName}
                  </Dropdown.Button>
                  <Dropdown.Menu aria-labelledby="dropdownMenuButton">
                    <Dropdown.Item backgroundColor={"none"} >
                      <SignOut />
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
            </ul>
          </Col>
        </Row>
      </Card>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ textAlign: "center" }}>
          Welcome {fullName}.<p></p>
          <p></p>
          We have created your account.
          <p></p>
          We request you to change your password first.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer />
    </Container>
  );
};

export default Header;
