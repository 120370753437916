import Cookies from "universal-cookie";
import { useDispatch } from "react-redux";
import { signOut } from "firebase/auth";
import { auth } from "../firebase.config";
import {
  addEmail,
  addFullName,
  addAccessToken,
  addPhotoURL,
  addFirstName,
  addLastName,
  addDOB,
  addCallingCode,
  addMobile,
  addGender,
  addUinfin,
  addId,
  addUserType,
  addKYC,
  addIsKycApproved,
  addCardPath,
  addCardNo,
  addCardActivated,
  reset,
  setFirstLogin
} from "../redux/actions";

export const clearEverything = () => {
  ClearRedux();
  firebaseSignOut();
  clearCookies();
  localStorage.clear();
};

export const clearCookies = () => {
  const cookies = new Cookies();
  cookies.remove("c1", { path: "/" });
  cookies.remove("c2", { path: "/" });
  cookies.remove("c3", { path: "/" });
  cookies.remove("c4", { path: "/" });
  cookies.remove("c5", { path: "/" });
};

export const ClearRedux = async () => {
  const dispatch = useDispatch();
  dispatch(addEmail(""));
  dispatch(addFullName(""));
  dispatch(addAccessToken(""));
  dispatch(addPhotoURL(""));
  dispatch(addFirstName(""));
  dispatch(addLastName(""));
  dispatch(addDOB(""));
  dispatch(addCallingCode(""));
  dispatch(addMobile(""));
  dispatch(addGender(""));
  dispatch(addUinfin(""));
  dispatch(addId(""));
  dispatch(addUserType(""));
  dispatch(addKYC(""));
  dispatch(addIsKycApproved(""));
  dispatch(addCardPath(""));
  dispatch(addCardNo(""));
  dispatch(addCardActivated(""));
  dispatch(reset());
  dispatch(setFirstLogin(""));
  // return true;
};

export const firebaseSignOut = () => {
  signOut(auth)
    .then(() => {})
    .catch((error) => {});
};
