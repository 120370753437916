import React, { useMemo, useState } from "react";
// import  from "./columns";
import { useTable } from "react-table";
import { Container, Row, Col } from "react-bootstrap";
import { useEffect } from "react";
import "./react-table.css";

const ReactTable = ({ data , columns }) => {
  const [items, setItems] = useState();

  useEffect(() => {
  }, [data]);

  const {
    getTableProps,
    defaultColumn,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  });

  const rerenderData = () => {
    return (
      <Container
        fluid
        style={{ fontSize: "12px", justifyContent: "center", width: "100%" }}
      >
        <Row style={{ justifyContent: "center" }}>
          <table
            {...getTableProps()}
            style={{ border: "1px black solid", width: "100%" }}
          >
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps()}
                      style={{ border: "1px black solid", padding: "15px" }}
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody
              {...getTableBodyProps()}
              style={{ border: "1px black solid" }}
            >
              {rows.map((row, i) => {
                prepareRow(row);
                return (
                  <tr
                    id="row-table"
                    {...row.getRowProps()}
                    style={{ border: "1px black solid", padding: "5px" }}
                  >
                    {row.cells.map((cell) => {
                      return (
                        <td
                          {...cell.getCellProps()}
                          style={{ border: "1px black solid", padding: "5px" }}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Row>
      </Container>
    );
  };

  return <>{data ? <>{rerenderData()}</> : <div>No data available</div>}</>;
};

export default ReactTable;
