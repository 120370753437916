import React from "react";
import Select from "react-select";

const MySelect = ({ id, options, onChange, onBlur, value, error, touched ,className }) => {
    const handleChange = (val) => {
      onChange(id, val.value);
    };
  
    const handleBlur = () => {
      onBlur(id, true);
    };
    const defaultValue = (options,value)=>{
      return options? options.find(option => option.value  ===value) : "";
    }
  
    return (
      <div>
        <Select
          // className={className}
          id={id}
          options={options}
          onChange={handleChange}
          onBlur={handleBlur}
          value={defaultValue(options, value)}
          style={{textAlign:"left"}}
        />
        {error && touched && (
          <div style={{ color: "red", fontSize: "12px" }}>{error}</div>
        )}
      </div>
    );
  };

  export default MySelect