import React, { useMemo, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Container, Form, Col, Row } from "bootstrap-4-react";
import { useTable } from "react-table";
import { COLUMNS } from "../components/columns";
import DATA from "../data/jdbc.json";
import { Button } from "bootstrap-4-react";

const CardFullDetails = ({ purchased }) => {
  const columns = useMemo(() => COLUMNS, [COLUMNS]);
  const data = useMemo(() => DATA, [COLUMNS]);
  //const location = useLocation();
  const [cardSelected, setCardSelected] = useState("");
  const navigate = useNavigate();

  // useEffect(() => {
  //   setCardSelected(location.state.card);
  // }, [location.state.card]);

  const {
    getTableProps,
    defaultColumn,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  });

  return (
    <>
      {purchased.cardPurchased === true ? (
        <div
          style={{
            fontSize: "1.675rem",
            fontWeight: "bolder",
            color: "white",
            position: "absolute",
            top: 180,
            width:"95%",
            textAlign : 'center',
          }}
        >
          <p>Successfully Activated</p>
          <p>"Your Card has been Successfully Activated"</p>
        </div>
      ) : null}

      <Container
        fluid
        style={{
          fontSize: ".75rem",
          justifyContent: "center",
          marginTop: purchased.cardPurchased === true ? 60 : 0,
          position: purchased.cardPurchased === true ? "absolute" : "relative",
          top: purchased.cardPurchased === true ? 250 : 0,
          backgroundColor: "white",
          width: purchased.cardPurchased === true ? "90%" : "100%",
          marginLeft: purchased.cardPurchased === true  ? "5%":  "0%",
          marginRight: "5%",
          right:0
        }}
      >
        <Row>
          <Col
            lg={4}
            sm={6}
            className="text-center"
            style={{ paddingTop: "30px", paddingBottom: "30px" }}
          >
            <span style={{ fontSize: "30px", fontWeight: "bolder", color:"black"  }}>
              Card Details
            </span>
          </Col>
        </Row>
        <Row style={{ justifyContent: "center", paddingBottom: "30px" }}>
          <table {...getTableProps()} style={{ border: "1px black solid" }}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps()}
                      style={{ border: "1px solid", padding: "5px", color:"black" }}
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody
              {...getTableBodyProps()}
              style={{ border: "1px black solid" }}
            >
              {rows.map((row, i) => {
                prepareRow(row);
                return (
                  <tr
                    {...row.getRowProps()}
                    style={{ border: "1px black solid", padding: "5px", color:"black"  }}
                  >
                    {row.cells.map((cell) => {
                      return (
                        <td
                          {...cell.getCellProps()}
                          style={{ border: "1px black solid", padding: "5px" }}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Row>
      </Container>
    </>
  );
};

export default CardFullDetails;
