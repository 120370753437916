import React, { useEffect, useState } from "react";
import Header from "../../../components/header";
import { Container, Row, Col } from "bootstrap-4-react/lib/components/layout";
import { Image } from "react-bootstrap";

const CardSuccess = () => {
  return (
    <>
      <Header title="Card Success" />
      <Container fluid style={{ margin: "50px" }}>
        <Row>
          <Col lg={7} sm={12}>
            <span style={{ fontSize: "29px", fontWeight: "800" , lineHeight:"40px" }}>
              Successfully Activated!
            </span>
            <br />
            <br />
            <p></p>
            <div style={{lineHeight:"2"}}>
              <p>Your Card Has been Successfully Activated.</p>
              <p></p>
            </div>
          </Col>
          <Col lg={3} sm={12}>
            <Image
              src={require("../../../assets/girl_dance.jpeg")}
              width={200}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CardSuccess;
