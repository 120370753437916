import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "bootstrap-4-react";
import { Image } from "react-bootstrap";
import Header from "../../../components/header";
import OTPInput, { ResendOTP } from "otp-input-react";
import { FileUploader } from "react-drag-drop-files";
import axios from "axios";
import API_PATHS from "../../../constants/apiPaths";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import KycHoldingPage from "../../Kyc/kycHold";
import CardStatus from "./cardStatus";
import { useSelector } from "react-redux";
import { getCookie } from "../../../Utils/cookieHandling";
toast.configure();

const CardVerify = () => {
  const { email, userType, accessToken, card_no, is_kyc_approved } =
    useSelector((state) => state);
  const navigate = useNavigate();
  const [cardImage, setCardImage] = useState(null);
  const [cardNumber, setCardNumber] = useState("");
  const fileTypes = ["JPG", "PNG", "GIF"];
  const [isKycApproved, setIsKycApproved] = useState();
  const [isJdbCardGiven, setJdbCardGiven] = useState();
  const [cardSuccess, setCardSuccess] = useState(false);

  useEffect(() => {
    card_no ? setJdbCardGiven(true) : setJdbCardGiven(false);
    is_kyc_approved === "1" ? setIsKycApproved(true) : setIsKycApproved(false);
  });
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const verifyCardNumber = async () => {
    if (!cardNumber) {
      toast.error("Card Number is required");
      return;
    }
    if (!cardImage) {
      toast.error("Card Image is required");
      return;
    }
    const obj = {
      jdbCardNumber1: cardNumber,
      card_img: await convertBase64(cardImage),
      card_activated: 1,
      // is_cardVerified: "1",
    };
    axios
      .put(API_PATHS.VERIFY_CARD_NUMBER + email, obj, {
        headers: {
          Authorization: `${getCookie("c4")} ${getCookie("c3")}`,
        },
      })
      .then((res) => {
        toast(res.data.message);
        if (
          res.data.message === "Please check the card number, you have entered!"
        ) {
        } else {
          navigate("/logged/card/cardSuccess1");
        }
      });
  };
  function start_and_end(str) {
    if (str.length > 35) {
      return (
        str.substr(0, 15) + "..." + str.substr(str.length - 10, str.length)
      );
    }
    return str;
  }
  function checkSize(str) {
    if (Number(str) > 1048575) {
      return `${Math.round(str / 1024) / 1000} MB`;
    }
    if (Number(str) < 1048576) {
      return `${Math.round(str / 1024)} KB`;
    }
  }

  return (
    <>
      {!isKycApproved && <KycHoldingPage />}
      {isKycApproved && !isJdbCardGiven && <CardStatus />}
      {/* {!isJdbCardGiven && <CardStatus/>} */}
      {isJdbCardGiven && !cardSuccess && (
        <>
          <Header title="Verification" />
          <Container fluid>
            <Row style={{ marginTop: "30px", paddingLeft: "30px" }}>
              <Col lg={7} sm={12}>
                <span style={{ fontSize: "29px", fontWeight: "600" }}>
                  Card Number Verification
                </span>
                <br />
                <br />
                <span style={{ fontSize: "17px", color: "gray" }}>
                  Enter the 16 digit Card number
                </span>
                <br />
                <OTPInput
                  value={cardNumber}
                  onChange={setCardNumber}
                  style={{ paddingTop: "40px" }}
                  autoFocus
                  OTPLength={16}
                  otpType="number"
                />
                <br />
              </Col>
            </Row>
            <Row style={{ marginTop: "30px", paddingLeft: "40px" }}>
              <span style={{ fontSize: "17px", color: "gray" }}>
                Upload the card image with selfie
              </span>
            </Row>
            <br />
            <br />
            <Col lg={6} sm={12} style={{ paddingLeft: "30px" }}>
              <FileUploader
                classes="file-upload"
                handleChange={(file) => {
                  setCardImage(file);
                }}
                style={{ maxWidth: "100%", minWidth: "100%", width: "100%" }}
                name="file"
                maxSize={2}
                types={fileTypes}
                onSizeError={(file) => setCardImage(null)}
                onTypeError={(file) => setCardImage(null)}
              />
              <div className="document">
                <p className="docName">
                  {cardImage ? start_and_end(cardImage.name) : ""}
                </p>
                <p className="docSize">
                  {cardImage ? checkSize(cardImage.size) : ""}
                </p>
              </div>
              <p
                className="maxSize"
                style={{ bottom: cardImage ? "51px" : "26px" }}
              >
                Max Size : 2 MB
              </p>

              {/* <Row className="document">
                <p className="docName">
                  {cardImage ? cardImage.name : "Upload File"}
                </p>
              </Row>
              <Row className="document">
                <Col>
                  <p className="docType" style={{ textAlign: "left" }}>
                    {cardImage ? cardImage.name.split(".")[1] : ""}
                  </p>
                </Col>
                <Col>
                  <p className="docSize">
                    {cardImage ? `${Math.round(cardImage.size / 1024)} KB` : ""}
                  </p>
                </Col>
              </Row>
              <p className="maxSize">Max Size : 2 MB</p>
                         */}
            </Col>

            <Row justifyContent="md-center">
              <Col className="text-center">
                <Button id="login-button" onClick={() => verifyCardNumber()}>
                  Verify
                </Button>
              </Col>
            </Row>
          </Container>
        </>
      )}
      {/* {cardSuccess && <CardSuccess />} */}
      <ToastContainer />
    </>
  );
};

export default CardVerify;
