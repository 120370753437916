export const cardPrograms = [
	{
		id: 1,
		programTitle: "JDB Card Program",
		benefits: ["High limits ", "Visa", "ATM Cash Withdrawls"],
		image: require("../assets/Premium Black.png"),
		path: "/logged/card/jdb",
		max_limit: "30000",
	},
	{
		id: 2,
		programTitle: "NIUM Card Program",
		benefits: ["High limits", "Visa", "ATM Cash Withdrawls"],
		image: require("../assets/card2.jpg"),
		path: "/logged/card/nium",
		max_limit: "30000",
	},
];
