import React, { useEffect, useState } from "react";
import { Image, Container, Row, Col } from "react-bootstrap";
import { Form, Button } from "bootstrap-4-react";
import "./forgotPassword.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import API_PATHS from "../../constants/apiPaths";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ForgotPassword = (formik) => {
  const { values, errors, touched } = formik;
  const navigate = useNavigate();

  const [curEmail, setCurEmail] = useState();

  useEffect(() => {
    setCurEmail(() => values.email);
  }, [values.email]);

  const sendOTP = (e) => {
    e.preventDefault();
    const obj = {
      email: curEmail,
    };
    axios
      .post(API_PATHS.FORGOT_PASSWORD, obj, {
        headers: {
          accepts: "application/json",
        },
      })
      .then((res) => {
        toast(res.data.message);
        if (res.data.message === `Email sent successfully.`) {
          navigate("/passwordReset", { state: { email: curEmail } });
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <Container fluid className="myCon">
      <Row>
        <Col sm={12} lg={6}>
          <Image
            src={require("../../assets/paymentPlanet/forgot(2).jpeg")}
            width="100%"
          />
          {/* <Image
            src={require("../../assets/paymentPlanet/PaymentP.png")}
            width="50%"
            style={{ marginTop: "10px" }}
          />
          <Image
            src={require("../../assets/forgot.png")}
            width="100%"
            height="100%"
          /> */}
        </Col>
        <Col className="mycol2" sm={12} lg={6} style={{ padding: "90px" }}>
          <h2
            style={{
              fontSize: "3rem ",
              fontWeight: "700",
              lineHeight: "69px",
            }}
          >
            Forgot Password
          </h2>
          <span>
            Password reset instructions will be sent to the above email address
            if it is associated with an account on our platform
          </span>
          <br />
          <br />
          <br />
          <div
            style={{
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "17px",
              lineHeight: "26px",
              /* identical to box height */
              textTransform: "capitalize",
              float: "left",
              color: " #757575",
            }}
          >
            Email
          </div>
          <Form.Input
            type="text"
            value={values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            id="email"
            name="email"
            className={
              errors.email && touched.email ? "text-input-error" : "text-input"
            }
          />
          {errors.email && touched.email && (
            <div style={{ color: "red" }}>{errors.email}</div>
          )}
          <br />
          <br />
          <Row justifyContent="center">
            <Col className="text-center">
              <Button
                id="forgot-password"
                disabled={!(formik.isValid && formik.dirty)}
                onClick={(e) => sendOTP(e)}
              >
                Forgot Password
              </Button>
            </Col>
          </Row>
        </Col>
        <br />
      </Row>
      <ToastContainer />
    </Container>
  );
};

export default ForgotPassword;
