import React, { useState, useEffect } from "react";
import Header from "../../../components/header";
import { useLocation, useNavigate } from "react-router-dom";
import { Container, Row, Col, Form, Button } from "bootstrap-4-react";
import axios from "axios";
import API_PATHS from "../../../constants/apiPaths";
import MySelect from "../../../components/mySelect";
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from "react-redux";
import { getCookie } from "../../../Utils/cookieHandling";

const options = [
  { value: 1, label: "01" },
  { value: 2, label: "02" },
  { value: 3, label: "03" },
  { value: 4, label: "04" },
  { value: 5, label: "05" },
  { value: 6, label: "06" },
  { value: 7, label: "07" },
  { value: 8, label: "08" },
  { value: 9, label: "09" },
  { value: 10, label: "10" },
  { value: 11, label: "11" },
  { value: 12, label: "12" },
];

const StripeNium = (props) => {
  const { email, userType, accessToken } = useSelector((state) => state);
  const { formik } = props;
  const { values, errors, touched } = formik;
  const details = props.data;
  const location = useLocation();
  const [cvv, setCvv] = useState();
  const [cardNumber, setCardNumber] = useState();
  let card = " ";
  const [amount, setAmount] = useState(0);
  const navigate = useNavigate();

  const [month, setMonth] = useState();
  const [year, setYear] = useState();
  const [yearList, setYearList] = useState([]);

  useEffect(() => {
    values.expiryMonth = month;
    values.expiryYear = year;
    // card = location.state.applicationDetails.card_type || " ";
    setAmount(150);
    getDropList();
  }, [month, year]);

  const getDropList = () => {
    const year = new Date().getFullYear();

    for (let i = 0; i <= 6; i++) {
      yearList.push({
        value: year + i,
        label: year + i,
      });
    }
  };

  useEffect(() => {
    values.cardNumber = cardNumber;
    values.cvv = cvv;
  }, [cvv, cardNumber]);

  const handlePayment = () => {
    const obj = {
      email: email,
      amount: amount,
      cardNumber: Number(values.cardNumber),
      month: values.expiryMonth,
      year: values.expiryYear,
      // is_cardVerified: "0",
      cardType: "NIUM",
    };
    axios
      .post(API_PATHS.STRIPE, obj, {
        headers: {
          accepts: "application/json",
          Authorization: `${getCookie("c4")} ${getCookie("c3")}`,
        },
      })
      .then((res) => {
        toast(res.data.message);
        if (res.data.message === "Payment Completed") {
          const obj1 = location.state.application;
          axios
            .put(API_PATHS.NIUM_APPLICATION_SUBMIT, obj1, {
              headers: {
                accepts: "application/json",
                Authorization: `${getCookie("c4")} ${getCookie("c3")}`,
              },
            })
            .then((res) => {
              toast(res.data.message);
              if (res.data.message === "Card Applied successful") {
                setTimeout(() => {
                  navigate("/logged/card/cardStatus");
                }, 6000);
              } else {
                toast("Error Occured. Please try after some time");
              }
            });
        } else {
          toast("Error Occured. Please try after some time");
        }
      });
  };

  return (
    <>
      <Header title="Payment Process" />
      <Container fluid>
        <span
          style={{
            justifyContent: "center",
            fontSize: "18px",
            lineHeight: "27px",
            fontWeight: "400",
            paddingTop: "30px",
          }}
        >
          Nium Payment Process
        </span>
        <Row>
          <Col lg={6} sm={12} style={{ padding: "15px", paddingTop: "50px" }}>
            <span>Amount </span>
            <Form.Input
              type="text"
              value={`${amount} USD`}
              readOnly
              style={{ width: "30%" }}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={6} sm={12} style={{ padding: "15px", paddingTop: "20px" }}>
            <span>Card Number </span>
            <br />
            <input
              type="number"
              pattern="[0-9]*"
              value={cardNumber}
              onChange={(e) => {
                if (e.target.value.length > 16) {
                } else {
                  setCardNumber(e.target.value);
                }
              }}
              onBlur={formik.handleBlur}
              style={{
                width: "70%",
                height: "35px",
                borderRadius: "10px",
                padding: "10px",
              }}
              maxLength="16"
              id="cardNumber"
              name="cardNumber"
              className={
                errors.cardNumber && touched.cardNumber
                  ? "text-input-error"
                  : "text-input"
              }
            />
            {errors.cardNumber && touched.cardNumber && (
              <div style={{ color: "red" }}>{errors.cardNumber}</div>
            )}
          </Col>
        </Row>
        <Row>
          <Col lg={4} sm={6} style={{ padding: "15px", paddingTop: "20px" }}>
            <span>CVV</span>
            <br />
            <input
              type="number"
              pattern="[0-9]*"
              value={cvv}
              onChange={(e) => {
                if (e.target.value.length > 3) {
                } else {
                  setCvv(e.target.value);
                }
              }}
              onBlur={formik.handleBlur}
              id="cvv"
              maxLength="3"
              style={{
                width: "70%",
                height: "35px",
                borderRadius: "10px",
                padding: "10px",
                width: "40%",
              }}
              name="cvv"
              className={
                errors.cvv && touched.cvv ? "text-input-error" : "text-input"
              }
            />
            {errors.cvv && touched.cvv && (
              <div style={{ color: "red", fontSize: "12px" }}>{errors.cvv}</div>
            )}
          </Col>
        </Row>
        <Row>
          <Col
            lg={2}
            sm={6}
            style={{
              padding: "15px",
              paddingTop: "20px",
              flexDirection: "column",
            }}
          >
            <span>Expiry Date</span>
            <MySelect
              id="expiryMonth"
              options={options}
              value={values.expiryMonth}
              onChange={props.formik.setFieldValue}
              onBlur={props.formik.setFieldTouched}
              error={errors.expiryMonth}
              touched={touched.expiryMonth}
            />
          </Col>

          <Col lg={3} sm={6} style={{ padding: "15px", paddingTop: "45px" }}>
            <MySelect
              id="expiryYear"
              options={yearList}
              value={values.expiryYear}
              onChange={props.formik.setFieldValue}
              onBlur={props.formik.setFieldTouched}
              error={errors.expiryYear}
              touched={touched.expiryYear}
            />
            {/* {errors.expiryYear && touched.expiryYear && (
              <div style={{ color: "red", fontSize: "12px" }}>
                {errors.expiryYear}
              </div>
            )} */}
          </Col>
        </Row>
        <Row>
          <Col lg={6} sm={12} style={{ padding: "15px", paddingTop: "50px" }}>
            <span>Name on the Card </span>
            <Form.Input
              type="text"
              value={values.cardHolderName}
              onChange={props.formik.handleChange}
              onBlur={props.formik.handleBlur}
              style={{ width: "70%" }}
              id="cardHolderName"
              name="cardHolderName"
              className={
                errors.cardHolderName && touched.cardHolderName
                  ? "text-input-error"
                  : "text-input"
              }
            />
            {errors.cardHolderName && touched.cardHolderName && (
              <div style={{ color: "red" }}>{errors.cardHolderName}</div>
            )}
          </Col>
          <Col lg={6} sm={12} style={{ padding: "15px", paddingTop: "70px" }}>
            <Button
              id="login-button"
              disabled={!(props.formik.isValid && props.formik.dirty)}
              onClick={(e) => handlePayment(e)}
            >
              Next
            </Button>
          </Col>
        </Row>
        <ToastContainer />
      </Container>
    </>
  );
};

export default StripeNium;
