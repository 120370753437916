import React from "react";
import Header from "../../../components/header";
import { Container, Row, Col } from "bootstrap-4-react/lib/components/layout";
import { Image } from "react-bootstrap";
import { Button } from "bootstrap-4-react/lib/components";
import { useNavigate } from "react-router-dom";

const CardApplicationSucess = () => {
  const navigate = useNavigate();
  return (
    <>
      <Header title="Card Application" />
      <Container fluid style={{ margin: "50px" }}>
        <Row>
          <Col lg={7} sm={12}>
            <span style={{ fontSize: "29px", fontWeight: "800" }}>
              JDB Card Application Submitted Successfully
            </span>
            <br />
            <br />
            <span style={{ fontSize: "20px", fontWeight: "500" }}>
              We are reviewing your Application.
            </span>
            <br />
            <br />
            <span style={{ fontSize: "17px", color: "#585858" }}>
              You'll be notified once your application is approved. <br />
              This may take upto 24 hours.
            </span>
          </Col>
          <Col lg={3} sm={12}>
            <Image
              src={require("../../../assets/girl_dance.jpeg")}
              width={200}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CardApplicationSucess;
