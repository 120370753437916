import { Container, Row, Col, Form, Button } from "bootstrap-4-react";
import { Card } from "react-bootstrap";
import React, { useEffect, useRef, useState, useMemo } from "react";
import Header from "../../../components/header";
import countryList from "react-select-country-list";
import "react-phone-number-input/style.css";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./jdb.css";
import { FileUploader } from "react-drag-drop-files";
import SignatureCanvas from "react-signature-canvas";
import MySelect from "../../../components/mySelect";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router";
import { Allfields } from "../../../data/allfields";
import { getCode, getName } from "country-list";
import { useSelector } from "react-redux";
import { ProgressBar } from "react-loader-spinner";
import moment from "moment";

toast.configure();

const JDBApplication = (formik) => {
  const sigPad = useRef({});
  const { kyc } = useSelector((state) => state);
  const location = useLocation();
  const [cardType, setCardType] = useState(
    location.state.type || "JDBC Platinum card"
  );
  const options = useMemo(() => countryList().getData(), []);
  const { values, errors, touched } = formik;
  const [phone, setPhone] = useState(values.phone);
  const [ephone, setEPhone] = useState(null);
  const [selfie, setSelfie] = useState(null);
  const [passportBiopage, setPassportBioPage] = useState(null);
  const [spassportBiopage, setSPassportBioPage] = useState(null);
  // const [sigPad.current, setSigPad.current] = useState({});
  const [countryFlag, setCountryFlag] = useState("");
  const [trimmedDataURL, setTrimmedDataUrl] = useState(null);
  const [isAddressSame, setIsAddressSame] = useState(false);
  const [errorMsg, setErrorMsg] = useState([]);
  const [click, setClick] = useState(false);
  const [pass, setPass] = useState(false);
  const [error, setError] = useState([]);
  const [paddOff, setPaddOff] = useState(false);
  const [errorDate, setErrorDate] = useState(false);
  const navigate = useNavigate();
  const fileTypes = ["JPG", "PNG", "GIF"];
  const gender = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
  ];

  useEffect(() => {
    setTimeout(() => {
      if (values.phone) {
        setPhone(values.phone);
      }
    }, 2000);
  }, []);

  const title = [
    { value: "mr", label: "Mr" },
    { value: "mrs", label: "Mrs" },
    { value: "ms", label: "Ms" },
  ];

  const status = [
    { value: "married", label: "Married" },
    { value: "single", label: "Single" },
  ];

  const clear = () => {
    setTrimmedDataUrl(null);
    setPaddOff(false);
    sigPad.current.clear();
  };

  const trim = () => {
    setTrimmedDataUrl(sigPad.current.getTrimmedCanvas().toDataURL("image/png"));
  };
  useEffect(() => {
    values.phone = phone;
    values.ephone = ephone;
    values.passportBiopage = passportBiopage;
    values.spassportBiopage = spassportBiopage;
    values.selfie = selfie;
    values.trimmedDataURL = trimmedDataURL;
  }, [
    phone,
    ephone,
    passportBiopage,
    spassportBiopage,
    selfie,
    trimmedDataURL,
  ]);

  const addMailAddress = () => {
    values.mailAddress = values.address;
    values.mailCity = values.city;
    values.mailState = values.state;
    values.mailPostalCode = values.postalCode;
    values.mailCountry = values.country;
  };

  useEffect(() => {
    if (isAddressSame) {
      addMailAddress();
    }
  }, [isAddressSame]);

  const id_type = [{ value: "passport", label: "Passport" }];

  useEffect(() => {
    if (click == true) {
      setErrorMsg([]);
      Allfields.map((entry) =>
        values[entry.value]
          ? null
          : entry.value === "selfie"
          ? values["trimmedDataURL"]
            ? null
            : setErrorMsg((oldArray) => [
                ...oldArray,
                `Please select the ${entry.label}`,
              ])
          : entry.value === "trimmedDataURL"
          ? values["selfie"]
            ? null
            : setErrorMsg((oldArray) => [
                ...oldArray,
                `Please select the ${entry.label}`,
              ])
          : setErrorMsg((oldArray) => [
              ...oldArray,
              `Please select the ${entry.label}`,
            ])
      );
    }
  }, [errorMsg, click]);

  useEffect(() => {
    setError([]);
    Allfields.map((entry) =>
      values[entry.value]
        ? null
        : setError((oldArray) => [
            ...oldArray,
            `Please select the ${entry.label}`,
          ])
    );
  });

  function PaddStop() {
    sigPad.current.off();
  }
  function PadStart() {
    sigPad.current.on();
  }

  useEffect(() => {
    if (paddOff) {
      PaddStop();
    } else {
      PadStart();
    }
  }, [paddOff]);

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const convertBase64ToFile = function (image) {
    const byteString = atob(image.split(",")[1]);
    const name = `${Math.random().toString(36).slice(-5)}.png`;
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i += 1) {
      ia[i] = byteString.charCodeAt(i);
    }
    const newBlob = new Blob([ab], {
      name,
      type: "image/png",
    });
    return newBlob;
  };

  // useEffect(()=>{
  //   const filecode1 = convertBase64ToFile(
  // 		sigPad.current.getTrimmedCanvas().toDataURL("image/png")
  // 	);
  // },[trimmedDataURL])

  const handlePayment = async () => {
    const date1 = new Date(values.pIssuedDate);
    const date2 = new Date(values.pExpiryDate);
    const timeDiff = date2.getTime() - date1.getTime();
    if (timeDiff / (1000 * 3600 * 24) < 180) {
      setPass(false);
      toast.error("Passport Issue/Expire Date gap less than 6 Months");
      setErrorDate(true);
      return;
    }
    setPass(true);
    const obj = {
      title: values.title,
      id_type: values.IDType,
      card_type: cardType,
      id_no: " ",
      id_issued_date: moment(values.pIssuedDate).format("DD-MM-YYYY"),
      gender: values.gender,
      marital_status: values.mStatus,
      mailing_address_line_1: values.mailAddress,
      emergency_contact_person: values.ePerson,
      emergency_contact_telephone_number: Number(
        String(values.ephone).substring(3, 13)
      ),
      place_of_id_issued: values.IDCountry,
      passport_expiry_date: moment(values.pExpiryDate).format("DD-MM-YYYY"),
      first_name: values.firstName,
      last_name: values.lastName,
      email: values.email,
      card_email: " ",
      dob: moment(values.dob).format("DD-MM-YYYY"),
      nationality: values.nationality,
      passport_id: values.passportNo,
      contactNumber: Number(String(values.phone).substring(3, 13)),
      countryCode: Number(String(values.phone).substring(0, 3)),
      countryFlag: countryFlag,
      countryName: getName(values.country),
      address1: values.address,
      city: values.city,
      state: values.state,
      pincode: Number(values.postalCode),
      img_sign: trimmedDataURL
        ? sigPad.current.getTrimmedCanvas().toDataURL("image/png")
        : await convertBase64(selfie),
      selfie: selfie ? await convertBase64(selfie) : "",
      passport_file_signature: await convertBase64(passportBiopage),
      passport_file_signature_biopic: await convertBase64(spassportBiopage),
      residence_city: values.mailCity,
      residence_countryName: values.mailCountry,
      residence_pincode: Number(values.mailPostalCode),
      residence_state: values.mailState,
      emergencycountryCode: Number(String(values.ephone).substring(0, 3)),
    };
    const attachments = {
      trimmedDataURL: trimmedDataURL
        ? convertBase64ToFile(
            sigPad.current.getTrimmedCanvas().toDataURL("image/png")
          )
        : "",
      passportBiopage: passportBiopage,
      spassportBiopage: spassportBiopage,
      selfie: selfie ? selfie : "",
    };

    navigate("/logged/card/paymentJdb", {
      state: { applicationDetails: obj, attachments: attachments },
    });
  };

  useEffect(() => {
    if (pass === true) {
      handlePayment();
    }
  }, [pass]);
  useEffect(() => {
    setPass(false);
  }, [errorDate]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setPhone(values.phone);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  function start_and_end(str) {
    if (str.length > 35) {
      return (
        str.substr(0, 15) + "..." + str.substr(str.length - 10, str.length)
      );
    }
    return str;
  }

  function checkSize(str) {
    if (Number(str) > 1048575) {
      return `${Math.round(str / 1024) / 1000} MB`;
    }
    if (Number(str) < 1048576) {
      return `${Math.round(str / 1024)} KB`;
    }
  }
  return (
    <>
      <Header title="Card Application" />
      <Container fluid>
        <span
          style={{
            justifyContent: "center",
            fontSize: "18px",
            lineHeight: "27px",
            fontWeight: "400",
          }}
        >
          JDB
        </span>
        <Row>
          <Col>
            <span
              style={{
                fontSize: "26px",
                fontWeight: "600",
                lineHeight: "39px",
              }}
            >
              Card Applied
            </span>
          </Col>
        </Row>
        <Row>
          <Col style={{ flexDirection: "row" }}>
            <Card
              body
              style={{
                backgroundColor: "#fff",
                borderRadius: "25px",
                marginTop: "20px",
              }}
            >
              <Row>
                {[
                  "JDB Premium Black",
                  "JDB Black Metal",
                  "JDB Silver Metal",
                  "JDB Gold Metal",
                ].map((type, i) => {
                  return (
                    <Col key={i}>
                      <Form.Check>
                        <Form.Checkbox
                          inline
                          onChange={(e) => setCardType(e.target.name)}
                          value={cardType}
                          name={`${type}`}
                          type="radio"
                          id={`inline-${type}-1`}
                          style={{
                            display: "block",
                          }}
                          checked={cardType === type ? "false" : ""}
                        />
                        <Form.CheckLabel
                          style={{
                            color: cardType === type ? "#de2834" : "black",
                          }}
                          htmlFor={`inline-${type}-1`}
                        >
                          {type}
                        </Form.CheckLabel>
                      </Form.Check>
                    </Col>
                  );
                })}
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
      <Container fluid>
        <Row style={{ paddingTop: "40px" }}>
          <Col lg={6} sm={6}>
            <span
              style={{
                fontSize: "26px",
                lineHeight: "39px",
                fontWeight: "600",
              }}
            >
              Personal Details
            </span>
          </Col>
        </Row>
        <Row style={{ paddingTop: "20px" }}>
          <Col lg={6} sm={12} style={{ padding: "15px" }}>
            <span>Title</span>
            <MySelect
              id="title"
              options={title}
              value={values.title}
              onChange={formik.setFieldValue}
              onBlur={formik.setFieldTouched}
              error={errors.title}
              touched={touched.title}
            />
          </Col>
          <Col lg={6} sm={12} style={{ padding: "15px" }}>
            <span>First name </span>
            <Form.Input
              type="text"
              value={values.firstName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              id="firstName"
              name="firstName"
              className={
                errors.firstName && touched.firstName
                  ? "text-input-error"
                  : "text-input"
              }
            />
            {errors.firstName && touched.firstName && (
              <div style={{ color: "red" }}>{errors.firstName}</div>
            )}
          </Col>
        </Row>
        <Row style={{ marginTop: "20px" }}>
          <Col lg={6} sm={12} style={{ padding: "15px" }}>
            <span>Last name</span>
            <Form.Input
              type="text"
              value={values.lastName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              id="lastName"
              name="lastName"
              className={
                errors.lastName && touched.lastName
                  ? "text-input-error"
                  : "text-input"
              }
            />
            {errors.lastName && touched.lastName && (
              <div style={{ color: "red" }}>{errors.lastName}</div>
            )}
          </Col>
          <Col lg={6} sm={12} style={{ padding: "15px" }}>
            <span>Mobile Number</span>
            <PhoneInput
              international
              country={"sg"}
              // defaultCountry="SG"
              value={phone}
              limitMaxLength={10}
              onChange={setPhone}
              onBlur={formik.handleBlur}
              onCountryChange={(value) => {
                setCountryFlag(value);
              }}
              style={{
                lineHeight: "30px",
                border: "0px",
                borderRadius: "25px",
              }}
              id="phone"
              name="phone"
              className={
                errors.phone && touched.phone
                  ? "text-input-error"
                  : "text-input"
              }
            />
            {errors.phone && touched.phone && (
              <div style={{ color: "red" }}>{errors.phone}</div>
            )}
          </Col>
        </Row>
        <Row style={{ marginTop: "20px" }}>
          <Col lg={6} sm={12} style={{ padding: "15px" }}>
            <span>Date of Birth</span>
            <Form.Input
              type="date"
              dateFormat="dd/mm/yyyy"
              locale="en"
              value={values.dob}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              id="dob"
              name="dob"
              className={
                errors.dob && touched.dob ? "text-input-error" : "text-input"
              }
            />
            {errors.dob && touched.dob && (
              <div style={{ color: "red" }}>{errors.dob}</div>
            )}
          </Col>
          <Col lg={6} sm={12} style={{ padding: "15px" }}>
            <span> Email Address</span>
            <Form.Input type="email" value={values.email} readonly />
          </Col>
        </Row>
        <Row style={{ marginTop: "20px" }}>
          <Col lg={6} sm={12} style={{ padding: "15px" }}>
            <span>Gender</span>
            <MySelect
              id="gender"
              options={gender}
              value={values.gender}
              onChange={formik.setFieldValue}
              onBlur={formik.setFieldTouched}
              error={errors.gender}
              touched={touched.gender}
            />
          </Col>
          <Col lg={6} sm={12} style={{ padding: "15px" }}>
            <span> Martial Status</span>
            <MySelect
              id="mStatus"
              options={status}
              value={values.mStatus}
              onChange={formik.setFieldValue}
              onBlur={formik.setFieldTouched}
              error={errors.mStatus}
              touched={touched.mStatus}
            />
          </Col>
        </Row>
        <Row style={{ marginTop: "20px" }}>
          <Col lg={6} sm={12} style={{ padding: "15px" }}>
            <span>Emergency Contact Person</span>
            <Form.Input
              type="text"
              value={values.ePerson}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              id="ePerson"
              name="ePerson"
              className={
                errors.ePerson && touched.ePerson
                  ? "text-input-error"
                  : "text-input"
              }
            />
            {errors.ePerson && touched.ePerson && (
              <div style={{ color: "red" }}>{errors.ePerson}</div>
            )}
          </Col>
          <Col lg={6} sm={12} style={{ padding: "15px" }}>
            <span>Emergency Number</span>
            <PhoneInput
              international
              country={"sg"}
              value={ephone}
              limitMaxLength={10}
              onChange={setEPhone}
              style={{
                lineHeight: "30px",
                border: "0px",
                borderRadius: "25px",
              }}
              onBlur={formik.handleBlur}
              countryCallingCodeEditable={false}
              id="ephone"
              name="ephone"
              className={
                errors.ephone && touched.ephone
                  ? "text-input-error"
                  : "text-input"
              }
            />
            {errors.ephone && touched.ephone && (
              <div style={{ color: "red" }}>{errors.ephone}</div>
            )}
          </Col>
        </Row>
        <Row style={{ marginTop: "20px" }}>
          <Col lg={6} sm={12} style={{ padding: "15px" }}>
            <span>Passport No</span>
            <Form.Input
              type="text"
              value={values.passportNo}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              id="passportNo"
              name="passportNo"
              className={
                errors.passportNo && touched.passportNo
                  ? "text-input-error"
                  : "text-input"
              }
            />
            {errors.passportNo && touched.passportNo && (
              <div style={{ color: "red" }}>{errors.passportNo}</div>
            )}
          </Col>
          <Col lg={6} sm={12} style={{ padding: "15px" }}>
            <span>Passport Issued Date</span>
            <Form.Input
              type="date"
              dateFormat="dd/mm/yyyy"
              locale="en"
              value={values.pIssuedDate}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              id="pIssuedDate"
              name="pIssuedDate"
              className={
                errors.pIssuedDate && touched.pIssuedDate
                  ? "text-input-error"
                  : "text-input"
              }
            />
            {errors.pIssuedDate && touched.pIssuedDate && (
              <div style={{ color: "red" }}>{errors.pIssuedDate}</div>
            )}
          </Col>
        </Row>
        <Row style={{ marginTop: "20px" }}>
          <Col lg={6} sm={12} style={{ padding: "15px" }}>
            <span>ID Type</span>
            <Form.Input
              id="IDType"
              options={id_type}
              value={values.IDType}
              readonly
            />
          </Col>
          <Col lg={6} sm={12} style={{ padding: "15px" }}>
            <span> Passport Expiry Date</span>
            <Form.Input
              type="date"
              dateFormat="dd/mm/yyyy"
              locale="en"
              value={values.pExpiryDate}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              id="pExpiryDate"
              name="pExpiryDate"
              className={
                errors.pExpiryDate && touched.pExpiryDate
                  ? "text-input-error"
                  : "text-input"
              }
            />
            {errors.pExpiryDate && touched.pExpiryDate && (
              <div style={{ color: "red" }}>{errors.pExpiryDate}</div>
            )}
          </Col>
        </Row>
        <Row style={{ marginTop: "20px" }}>
          <Col lg={6} sm={12} style={{ padding: "15px" }}>
            <span>Country ID Issued</span>
            <MySelect
              options={options}
              value={values.IDCountry}
              onChange={formik.setFieldValue}
              onBlur={formik.setFieldTouched}
              id="IDCountry"
              // name="IDCountry"
              className={
                errors.IDCountry && touched.IDCountry
                  ? "text-input-error"
                  : "text-input"
              }
            />
            {errors.IDCountry && touched.IDCountry && (
              <div style={{ color: "red" }}>{errors.IDCountry}</div>
            )}
          </Col>
          <Col lg={6} sm={12} style={{ padding: "15px" }}>
            <span>Nationality</span>
            <Form.Input
              type="text"
              value={values.nationality}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              id="nationality"
              name="nationality"
              className={
                errors.nationality && touched.nationality
                  ? "text-input-error"
                  : "text-input"
              }
            />
            {errors.nationality && touched.nationality && (
              <div style={{ color: "red" }}>{errors.nationality}</div>
            )}
          </Col>
        </Row>
      </Container>
      <Container fluid>
        <Row style={{ paddingTop: "40px" }}>
          <Col lg={6} sm={6} style={{ padding: "15px" }}>
            <span
              style={{
                fontSize: "26px",
                lineHeight: "39px",
                fontWeight: "600",
              }}
            >
              Residential Address
            </span>
          </Col>
        </Row>
        <Row style={{ paddingTop: "20px", justifyContent: "center" }}>
          <Col>
            <span>Address :</span>
            <Form.Textarea
              type="text"
              value={values.address}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              id="address"
              name="address"
              className={
                errors.address && touched.address
                  ? "text-input-error"
                  : "text-input"
              }
            />
            {errors.address && touched.address && (
              <div style={{ color: "red" }}>{errors.address}</div>
            )}
          </Col>
        </Row>
        <Row style={{ marginTop: "20px" }}>
          <Col lg={6} sm={12} style={{ padding: "15px" }}>
            <span>City</span>
            <Form.Input
              type="text"
              value={values.city}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              id="city"
              name="city"
              className={
                errors.city && touched.city ? "text-input-error" : "text-input"
              }
            />
            {errors.city && touched.city && (
              <div style={{ color: "red" }}>{errors.city}</div>
            )}
          </Col>
          <Col lg={6} sm={12} style={{ padding: "15px" }}>
            <span>State</span>
            <Form.Input
              type="text"
              value={values.state}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              id="state"
              name="state"
              className={
                errors.state && touched.state
                  ? "text-input-error"
                  : "text-input"
              }
            />
            {errors.state && touched.state && (
              <div style={{ color: "red" }}>{errors.state}</div>
            )}
          </Col>
        </Row>
        <Row style={{ marginTop: "20px" }}>
          <Col lg={6} sm={12} style={{ padding: "15px" }}>
            <span>Postal Code</span>
            <Form.Input
              type="text"
              value={values.postalCode}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              id="postalCode"
              name="postalCode"
              className={
                errors.postalCode && touched.postalCode
                  ? "text-input-error"
                  : "text-input"
              }
            />
            {errors.postalCode && touched.postalCode && (
              <div style={{ color: "red" }}>{errors.postalCode}</div>
            )}
          </Col>
          <Col lg={6} sm={12} style={{ padding: "15px" }}>
            <span>Country</span>
            <MySelect
              options={options}
              value={values.country}
              onChange={formik.setFieldValue}
              onBlur={formik.setFieldTouched}
              id="country"
              // name="country"
              className={
                errors.country && touched.country
                  ? "text-input-error"
                  : "text-input"
              }
            />
            {errors.country && touched.country && (
              <div style={{ color: "red" }}>{errors.country}</div>
            )}
          </Col>
        </Row>
        <Row style={{ paddingTop: "40px" }}>
          <Col lg={5} sm={12} style={{ padding: "15px" }}>
            <span
              style={{
                fontSize: "26px",
                lineHeight: "39px",
                fontWeight: "600",
              }}
            >
              Card Mailing Address
            </span>
          </Col>
          <Col lg={7} sm={12} style={{ padding: "25px" }}>
            <input
              type={"checkbox"}
              style={{ marginRight: 10 }}
              disabled={
                !(
                  values.address &&
                  values.city &&
                  values.state &&
                  values.postalCode &&
                  values.country
                )
              }
              id="terms"
              className="input-check"
              checked={isAddressSame}
              onChange={() => setIsAddressSame(!isAddressSame)}
            />
            <Form.CheckLabel htmlFor="terms" id="isAddressSame">
              Same as Residential Address
            </Form.CheckLabel>
          </Col>
        </Row>
        {!isAddressSame && (
          <>
            <Row style={{ marginTop: "20px" }}>
              <Col>
                <span>Address :</span>
                <Form.Textarea
                  type="text"
                  value={values.mailAddress}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  id="mailAddress"
                  name="mailAddress"
                  className={
                    errors.mailAddress && touched.mailAddress
                      ? "text-input-error"
                      : "text-input"
                  }
                />
                {errors.mailAddress && touched.mailAddress && (
                  <div style={{ color: "red" }}>{errors.mailAddress}</div>
                )}
              </Col>
            </Row>

            <Row style={{ marginTop: "20px" }}>
              <Col lg={6} sm={12} style={{ padding: "15px" }}>
                <span>City</span>
                <Form.Input
                  type="text"
                  value={values.mailCity}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  id="mailCity"
                  name="mailCity"
                  className={
                    errors.mailCity && touched.mailCity
                      ? "text-input-error"
                      : "text-input"
                  }
                />
                {errors.mailCity && touched.mailCity && (
                  <div style={{ color: "red" }}>{errors.mailCity}</div>
                )}
              </Col>
              <Col lg={6} sm={12} style={{ padding: "15px" }}>
                <span>State</span>
                <Form.Input
                  type="text"
                  value={values.mailState}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  id="mailState"
                  name="mailState"
                  className={
                    errors.mailState && touched.mailState
                      ? "text-input-error"
                      : "text-input"
                  }
                />
                {errors.mailState && touched.mailState && (
                  <div style={{ color: "red" }}>{errors.mailState}</div>
                )}
              </Col>
            </Row>
            <Row style={{ marginTop: "20px" }}>
              <Col lg={6} sm={12} style={{ padding: "15px" }}>
                <span>Postal Code </span>
                <Form.Input
                  type="text"
                  value={values.mailPostalCode}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  id="mailPostalCode"
                  name="mailPostalCode"
                  className={
                    errors.mailPostalCode && touched.mailPostalCode
                      ? "text-input-error"
                      : "text-input"
                  }
                />
                {errors.mailPostalCode && touched.mailPostalCode && (
                  <div style={{ color: "red" }}>{errors.mailPostalCode}</div>
                )}
              </Col>
              <Col lg={6} sm={12} style={{ padding: "15px" }}>
                <span>Country</span>
                <MySelect
                  options={options}
                  value={values.mailCountry}
                  onChange={formik.setFieldValue}
                  onBlur={formik.setFieldTouched}
                  id="mailCountry"
                  // name="mailCountry"
                  className={
                    errors.mailCountry && touched.mailCountry
                      ? "text-input-error"
                      : "text-input"
                  }
                />
                {errors.mailCountry && touched.mailCountry && (
                  <div style={{ color: "red" }}>{errors.mailCountry}</div>
                )}
              </Col>
            </Row>
          </>
        )}
        <Row style={{ marginTop: "30px" }}>
          <Col lg={6} sm={12} style={{ padding: "15px" }}>
            <span>
              Upload Signature
              <span style={{ color: "gray", fontSize: "10px" }}>
                {" "}
                (Please Upload Signature or Sign below)
              </span>
            </span>
            <FileUploader
              style={{
                maxWidth: "100%",
                minWidth: "100%",
                width: "100%",
                border: "dashed 2px #de2834",
                height: "30px",
              }}
              disabled={trimmedDataURL ? true : false}
              classes="file-upload"
              handleChange={(file) => {
                setSelfie(file);
                setPaddOff(true);
              }}
              name="file"
              maxSize={2}
              types={fileTypes}
              className="uploader"
              onSizeError={(file) => setSelfie(null)}
              onTypeError={(file) => setSelfie(null)}
            />

            <div className="document">
              <p className="docName">
                {selfie ? start_and_end(selfie.name) : ""}
              </p>
              <p className="docSize">{selfie ? checkSize(selfie.size) : ""}</p>
            </div>
            <p className="maxSize" style={{ bottom: selfie ? "51px" : "26px" }}>
              Max Size : 2 MB
            </p>

            {/* <Row className="document">
              <p className="docName">{selfie ? selfie.name : "Upload File"}</p>
            </Row>
            <Row className="document">
              <Col>
                <p className="docType" style={{ textAlign: "left" }}>
                  {selfie ? selfie.name.split(".")[1] : ""}
                </p>
              </Col>
              <Col>
                <p className="docSize" style={{ textAlign: "left" }}>
                  {selfie ? `${Math.round(selfie.size / 1024)} KB` : ""}
                </p>
              </Col>
            </Row> */}
          </Col>
          <Col lg={6} sm={12} style={{ padding: "15px" }}>
            <span>Passport Bio Page</span>
            <FileUploader
              style={{ maxWidth: "100%", minWidth: "100%", width: "100%" }}
              classes="file-upload"
              handleChange={(file) => {
                setPassportBioPage(file);
              }}
              name="file"
              maxSize={2}
              types={fileTypes}
              className="uploader"
              onSizeError={(file) => setPassportBioPage(null)}
              onTypeError={(file) => setPassportBioPage(null)}
            />

            <div className="document">
              <p className="docName">
                {passportBiopage ? start_and_end(passportBiopage.name) : ""}
              </p>
              <p className="docSize">
                {passportBiopage ? checkSize(passportBiopage.size) : ""}
              </p>
            </div>
            <p
              className="maxSize"
              style={{ bottom: passportBiopage ? "51px" : "26px" }}
            >
              Max Size : 2 MB
            </p>

            {/* <Row className="document">
              <p className="docName">
                {passportBiopage ? passportBiopage.name : "Upload File"}
              </p>
            </Row>
            <Row className="document">
              <Col>
                <p className="docType" style={{ textAlign: "left" }}>
                  {passportBiopage ? passportBiopage.name.split(".")[1] : ""}
                </p>
              </Col>
              <Col>
                <p className="docSize">
                  {passportBiopage
                    ? `${Math.round(passportBiopage.size / 1024)} KB`
                    : ""}
                </p>
              </Col>
            </Row> */}
          </Col>
        </Row>
        <Row>
          <Col lg={6} sm={12} style={{ padding: "15px" }}>
            <span>
              Digital Signature
              <span style={{ color: "gray", fontSize: "10px" }}>
                {" "}
                (Please Sign or Upload Signature )
              </span>
            </span>
            <div style={{ border: "2px solid black", width: "270px" }}>
              <SignatureCanvas
                penColor="green"
                canvasProps={{
                  width: 260,
                  height: 100,
                  backgroundcolor: "rgb(255,0,0)",
                  className: "signatureCanvas",
                }}
                style={{ border: "1px solid black" }}
                ref={sigPad}
                onEnd={trim}
              />
            </div>
            <div style={{ padding: "20px", paddingLeft: "10px" }}>
              <Button onClick={clear}>Clear</Button>
              {""}
              {/* <Button onClick={trim}>Trim</Button> */}
            </div>
          </Col>
          <Col lg={6} sm={12} style={{ padding: "15px" }}>
            <span>Selfie with Passport Bio Page</span>
            <FileUploader
              style={{ maxWidth: "100%", minWidth: "100%", width: "100%" }}
              classes="file-upload"
              handleChange={(file) => {
                setSPassportBioPage(file);
              }}
              name="file"
              maxSize={2}
              types={fileTypes}
              className="uploader"
              onSizeError={(file) => setSPassportBioPage(null)}
              onTypeError={(file) => setSPassportBioPage(null)}
            />

            <div className="document">
              <p className="docName">
                {spassportBiopage ? start_and_end(spassportBiopage.name) : ""}
              </p>
              <p className="docSize">
                {spassportBiopage ? checkSize(spassportBiopage.size) : ""}
              </p>
            </div>
            <p
              className="maxSize"
              style={{ bottom: spassportBiopage ? "51px" : "26px" }}
            >
              Max Size : 2 MB
            </p>

            {/* <Row className="document">
              <p className="docName">
                {spassportBiopage ? spassportBiopage.name : "Upload File"}
              </p>
            </Row>
            <Row className="document">
              <Col>
                <p className="docType" style={{ textAlign: "left" }}>
                  {spassportBiopage ? spassportBiopage.name.split(".")[1] : ""}
                </p>
              </Col>
              <Col>
                <p className="docSize">
                  {spassportBiopage
                    ? `${Math.round(spassportBiopage.size / 1024)} KB`
                    : ""}
                </p>
              </Col>
            </Row> */}
          </Col>
        </Row>
        <div>
          {errorMsg.length === 1 &&
          (errorMsg[0] === "Please select the Digital Signature" ||
            errorMsg[0] === "Please select the Upload Signature")
            ? ""
            : errorMsg.map((entry) => (
                <div>
                  <span style={{ color: "red", lineHeight: "0.5px" }}>
                    {entry}
                  </span>
                </div>
              ))}
        </div>
        <Row justifyContent="md-center">
          <Col className="text-center">
            {pass && (error.length == 0 || error.length == 1) ? (
              <ProgressBar
                height="80"
                width="80"
                ariaLabel="progress-bar-loading"
                wrapperStyle={{}}
                wrapperClass="progress-bar-wrapper"
                borderColor="#F4442E"
                barColor="#51E5FF"
              />
            ) : (
              <button
                id="login-button"
                onClick={() => {
                  if (
                    error.length == 0 ||
                    error.length == 1 ||
                    errorDate === true
                  ) {
                    setPass(true);
                  } else {
                    setClick(true);
                  }
                }}
              >
                Update
              </button>
            )}
          </Col>
        </Row>
      </Container>
      <ToastContainer />
    </>
  );
};

export default JDBApplication;
