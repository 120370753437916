import {
  USER_EMAIL,
  ACCESS_TOKEN,
  FIRST_NAME,
  LAST_NAME,
  FULL_NAME,
  PHOTO_URL,
  ID,
  KYC,
  DOB,
  CALLING_CODE,
  MOBILE,
  UINFIN,
  GENDER,
  USER_TYPE,
  IS_KYC_APPROVED,
  CARD_PATH,
  CARD_NO,
  CARD_ACTIVATED,
  RESET,
  FIRST_LOGIN,
  ADDRESS_ONE,
  ADDRESS_TWO,
  POSTAL_CODE,
  COUNTRY,
  NATIONALITY
} from "./actions";

const initialValues = {
  email: "",
  access_token: "",
  firstName: "",
  lastName: "",
  fullName: "",
  photoURL: "",
  id: "",
  kyc: false,
  dob: "",
  gender: "",
  callingCode: "",
  mobile: "",
  uinfin: "",
  userType: "",
  is_kyc_approved: "",
  card_path: "",
  card_no: "",
  card_activated: false,
  reset: "",
  firstLogin: false,
  address1: "",
  address2: "",
  postalCode: "",
  country: "",
  nationality:""
};

const userReducer = (state = initialValues, action) => {
  switch (action.type) {
    case USER_EMAIL: {
      return { ...state, email: action.payload };
    }
    case ACCESS_TOKEN: {
      return { ...state, access_token: action.payload };
    }
    case FIRST_NAME: {
      return { ...state, firstName: action.payload };
    }
    case LAST_NAME: {
      return { ...state, lastName: action.payload };
    }
    case FULL_NAME: {
      return { ...state, fullName: action.payload };
    }
    case PHOTO_URL: {
      return { ...state, photoURL: action.payload };
    }
    case ID: {
      return { ...state, id: action.payload };
    }
    case DOB: {
      return { ...state, dob: action.payload };
    }
    case CALLING_CODE: {
      return { ...state, callingCode: action.payload };
    }
    case MOBILE: {
      return { ...state, mobile: action.payload };
    }
    case GENDER: {
      return { ...state, gender: action.payload };
    }
    case UINFIN: {
      return { ...state, uinfin: action.payload };
    }
    case KYC: {
      return { ...state, kyc: action.payload };
    }
    case USER_TYPE: {
      return { ...state, userType: action.payload };
    }
    case IS_KYC_APPROVED: {
      return { ...state, is_kyc_approved: action.payload };
    }
    case CARD_PATH: {
      return { ...state, card_path: action.payload };
    }
    case CARD_NO: {
      return { ...state, card_no: action.payload };
    }
    case CARD_ACTIVATED: {
      return { ...state, card_activated: action.payload };
    }
    case FIRST_LOGIN: {
      return { ...state, firstLogin: action.payload };
    }
    case ADDRESS_ONE: {
      return { ...state, address1: action.payload };
    }
    case ADDRESS_TWO: {
      return { ...state, address2: action.payload };
    }
    case POSTAL_CODE: {
      return { ...state, postalCode: action.payload };
    }
    case COUNTRY: {
      return { ...state, country: action.payload };
    }
    case NATIONALITY: {
      return { ...state, nationality: action.payload };
    }
    case RESET:
      return initialValues;
    default:
      return state;
  }
};

export default userReducer;
