import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Figure } from "bootstrap-4-react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button } from "bootstrap-4-react/lib/components";

const GoogleLoginComponent = ({ googleSignIn }) => {
  const navigate = useNavigate();
  const [isSignedIn, setIsSignedIn] = useState(false);
  const successRegister = (note) => toast.success(note);

  const handleSignIn = async () => {
    try {
      await googleSignIn();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
    <Button
      style={{
        background: " #FFFFFF",
        border: "1px solid #F3DEDC",
        boxShadow: "2px 3px 26px rgba(225, 225, 225, 0.17)",
        borderRadius: "12px",
      }}
        onClick={handleSignIn}
      >
        <Figure.Image
          src={require("../../../assets/google.png")}
          width="25"
          
          style={{ paddingTop: "1px" }}
        />{" "}
        <span
          style={{ fontSize: "15px", paddingLeft: "2px", fontWeight: "500",color:"black" }}
        >
          Google
        </span>
      </Button>
      {/* <ToastContainer autoClose={1000} /> */}
    </div>
  );
};

export default GoogleLoginComponent;
