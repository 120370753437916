export const Allfields = [
    { value: "title", label: "Title" },
    { value: "firstName", label: "First Name" },
    { value: "lastName", label: "Last Name" },
    { value: "phone", label: "Mobile Number" },
    { value: "dob", label: "Date Of Birth" },
    { value: "gender", label: "Gender" },
    { value: "mStatus", label: "Martial Status" },
    { value: "ePerson", label: "Emergency Contact Person" },
    { value: "ephone", label: "Emergency Number" },
    { value: "passportNo", label: "Passport No" },
    { value: "country", label: "Country" },
    { value: "mailCountry", label: "Mailing Country" },
    { value: "IDCountry", label: "Country ID Issued" },
    { value: "pIssuedDate", label: "Passport Issued Date" },
    { value: "IDType", label: "ID Type" },
    { value: "pExpiryDate", label: "Passport Expiry Date" },
    { value: "address", label: "Address" },
    { value: "state", label: "State" },
    { value: "city", label: "City" },
    { value: "postalCode", label: "Postal Code" },
    { value: "mailAddress", label: "Mailing Address" },
    { value: "mailCity", label: "Mailing City" },
    { value: "mailState", label: "Mailing State" },
    { value: "mailPostalCode", label: "Mailing PostalCode" },
    { value: "nationality", label: "Nationality" },
    { value: "passportBiopage", label: "Passport Bio Page" },
    { value: "spassportBiopage", label: "Stealth With Passport Bio Page" },
    { value: "selfie", label: "Upload Signature" },
    { value: "trimmedDataURL", label: "Digital Signature" },
  ];