import Cookies from "universal-cookie";

import CryptoJS from "crypto-js";
import { clearEverything } from "./clearAll";
const cookies = new Cookies();

// c1 = email
// c2 = name
// c3 = token
// c4 = user-type
// c5 = singpass-state

const secretPass = process.env.REACT_APP_SECRET;

export const setCookie = (name, value) => {
  const data = CryptoJS.AES.encrypt(
    JSON.stringify(value),
    secretPass
  ).toString();

  cookies.set(name, data, {
    path: "/",
    expires: new Date(Date.now() + 2592000),
    // httpOnly: true,
    // secure: true,
    // domain: "payment-planet.netlify.app or staging.paymentplanet.co",
  });
};
export const getCookie = (name) => {
  if(name == 'c6'){
    const c6 = cookies.get('c6')
    if(c6){
      return c6
    }else{
      return ""
    }
  }
  const text = cookies.get(name);
  if (!text) {
    // alert("Cookie not found or Tampered");
      clearEverything();
      window.location.href = "/";
  }
  const bytes = CryptoJS.AES.decrypt(text, secretPass);
  const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  return data;
};
export const deleteCookie = (name) => {
  setCookie(name, "", -1);
};
