export const NIUMPrograms = [
    {
        id:1,
        programTitle :"NIUM Card Program",
        benefits : [
            "High limits ",
            "Visa",
            "ATM Cash Withdrawls"
        ],
        image: require("../assets/card.jpg"),
        // path : "/logged/card/niumApply"
    },
    {
        id:2,
        programTitle :"NIUM Card Program",
        benefits : [
            "High limits",
            "Visa",
            "ATM Cash Withdrawls"
        ],
        image: require("../assets/card2.jpg"),
        // path : "/logged/card/niumApply"        
    }]