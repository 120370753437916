import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { getCookie, setCookie } from "../../../Utils/cookieHandling";
import { ProgressBar } from "react-loader-spinner";
import API_PATHS from "../../../constants/apiPaths";
import { clearEverything } from "../../../Utils/clearAll";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";

import {
  addEmail,
  addFullName,
  addAccessToken,
  addFirstName,
  addLastName,
  addGender,
  addDOB,
  addMobile,
  addCallingCode,
  addUinfin,
  addAddress1,
  addAddress2,
  addCountry,
  addPostalCode,
  addNationality,
  addPhotoURL,
  addId,
  addKYC
} from "../../../redux/actions";

function Singpass() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState("");

  const capitalize = (str) => {
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  useEffect(() => {
    const state = getCookie("c5");
    if (state != "") {
    axios.get(API_PATHS.SINGPASS_GET_DETAILS + state).then((singpass) => {
    if (singpass?.data?.details?.uinfin?.value && !singpass?.data?.details?.email?.value) {
      let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: API_PATHS.SINGPASS_USER + singpass?.data?.details?.uinfin?.value,
        headers: {}
      };

      axios.request(config)
        .then((response) => {
          if (response?.data?.email) {
            handleData(singpass, response?.data?.email)
          }
          else {
            handleUserWithoutEmail(singpass)
            // setShow(true)
          }
        })
        .catch((error) => {
          if (error?.response?.data?.message == "No Signpass User Found!") {
            handleUserWithoutEmail(singpass)
          }
        });

    }
    else if (singpass?.data?.details?.email?.value) {
      handleData(singpass, singpass?.data?.details?.email?.value)
    } else {
      setTimeout(() => {
        toast("Something went wrong");
        setPage("Login");
        setTimeout(() => navigate("/login"), 3000);
      }, 30000);
    }
    });
    } else {
      toast("Something went wrong");
      setPage("Login");
      setTimeout(() => navigate("/login"), 3000);
    }
  }, [])
  const handleData = (singpassData, emailValue) => {
    try {
      if (emailValue) {
        const singpassEmail = emailValue?.toLowerCase()
        const token = singpassData?.data?.token
        if (singpassData?.data?.token) {
          setCookie("c4", "SINGPASS");
          setCookie("c3", singpassData?.data?.token);
          dispatch(addAccessToken(singpassData?.data?.token))
        }
        axios
          .get(API_PATHS.USER_VERIFY + singpassEmail, {
            headers: {
              accepts: "application/json",
            },
          })
          .then((res1) => {
            setCookie("c1", singpassEmail);
            const type = getCookie("c4");
            const splitSingpass = type.split(" ");
            const signpassType = splitSingpass[1];
            const userName = capitalize(singpassData?.data?.details?.name.value);
            const splitOnSpace = userName.split(" ");
            setCookie("c2", userName);
            setCookie("c6", userName);
            const firstname = splitOnSpace.shift();
            const lastname = splitOnSpace.join(" ");
            dispatch(addEmail(singpassEmail));
            dispatch(addFullName(userName));
            dispatch(addFirstName(firstname));
            dispatch(addLastName(lastname));
            dispatch(addDOB(singpassData?.data?.details?.dob.value));
            dispatch(addUinfin(singpassData?.data?.details?.uinfin.value));
            dispatch(addGender(singpassData?.data?.details?.sex?.desc?.toLowerCase()));
            dispatch(addMobile(singpassData?.data?.details?.mobileno.nbr.value));
            dispatch(addCallingCode(singpassData?.data?.details?.mobileno.areacode.value));
            setCookie("c4", "SINGPASS");

            dispatch(
              addAddress1(
                `${singpassData?.data?.details?.regadd.block.value}, ${singpassData?.data?.details?.regadd.street.value}`
              )
            );
            dispatch(
              addAddress2(
                `${singpassData?.data?.details?.regadd.floor.value}, ${singpassData?.data?.details?.regadd.classification}-${singpassData?.data?.details?.regadd.unit.value}, ${singpassData?.data?.details?.regadd.building.value}`
              )
            );
            dispatch(addPostalCode(singpassData?.data?.details?.regadd.postal.value));
            dispatch(addCountry(singpassData?.data?.details?.regadd.country.desc));
            dispatch(addNationality(singpassData?.data?.details?.nationality?.desc));
            if (res1.data.message) {
              axios
                .get(API_PATHS.GET_DETAILS + singpassEmail, {
                  headers: {
                    Authorization: `SINGPASS ${token}`,
                  },
                })
                .then((response) => {
                  if (response?.data?.suspended == "1") {
                    toast("Your Account has been suspended");
                    setPage("Login");
                    setTimeout(() => navigate("/login"), 3000);
                    return false;
                  }
                  if (response?.data?.ascii_value === ("" || null)) {
                    navigate("/verify2fa", {
                      state: {
                        email: singpassEmail,
                        type: "SINGPASS",
                      },
                    });
                    return false;
                  }
                  if (response?.data?.first_name) {
                    dispatch(
                      addFullName(
                        response?.data?.first_name
                          ? response?.data?.first_name + " " + response?.data?.last_name
                          : "new user"
                      )
                    );
                  }
                  dispatch(addId(response?.data?.id));
                  dispatch(addPhotoURL(response?.data?.profile_pic));
                  dispatch(addKYC(response?.data?.kyc));


                  const state = {
                    firstName: firstname,
                    lastName: lastname,
                    dob: singpassData?.data?.details?.dob.value,
                    email: "",
                    uinfin: singpassData?.data?.details?.uinfin.value,
                    gender: singpassData?.data?.details?.sex?.desc?.toLowerCase(),
                    mobileCode: singpassData?.data?.details?.mobileno.areacode.value,
                    mobile: singpassData?.data?.details?.mobileno.nbr.value,
                    address1: `${singpassData?.data?.details?.regadd.block.value}, ${singpassData?.data?.details?.regadd.street.value}`,
                    address2: `${singpassData?.data?.details?.regadd.floor.value}, ${singpassData?.data?.details?.regadd.classification}-${singpassData?.data?.details?.regadd.unit.value}, ${singpassData?.data?.details?.regadd.building.value}`,
                    postalCode: singpassData?.data?.details?.regadd.postal.value,
                    country: singpassData?.data?.details?.regadd.country.desc,
                    d_country: singpassData?.data?.details?.regadd?.country?.code || "",
                    nationality: singpassData?.data?.details?.nationality?.desc || "",
                    type: response?.data?.user_type,
                  };

                  saveStateToLocalStorage(state);

                  if (response?.data?.id) {
                    response?.data?.card_activated === 1 ||
                      response?.data?.card_activated === 2
                      ? navigate("/logged/dashboard")
                      : response?.data?.jdbCardNumber1
                        ? navigate("/logged/card/cardVerify")
                        : response?.data?.payment_status === "paid"
                          ? navigate("/logged/card/cardStatus")
                          : response?.data?.kyc
                            ? navigate("/logged/kycHold")
                            : navigate("/logged/kyc");
                  }
                });
            } else {
              navigate("/2fa", {
                state: {
                  firstName: firstname,
                  lastName: lastname,
                  dob: singpassData?.data?.details?.dob.value,
                  email: singpassEmail,
                  uinfin: singpassData?.data?.details?.uinfin.value,
                  gender: singpassData?.data?.details?.sex?.desc?.toLowerCase(),
                  mobileCode: singpassData?.data?.details?.mobileno.areacode.value,
                  mobile: singpassData?.data?.details?.mobileno.nbr.value,
                  address1: `${singpassData?.data?.details?.regadd.block.value}, ${singpassData?.data?.details?.regadd.street.value}`,
                  address2: `${singpassData?.data?.details?.regadd.floor.value}, ${singpassData?.data?.details?.regadd.classification}-${singpassData?.data?.details?.regadd.unit.value}, ${singpassData?.data?.details?.regadd.building.value}`,
                  postalCode: singpassData?.data?.details?.regadd.postal.value,
                  country: singpassData?.data?.details?.regadd.country.desc,
                  d_country: singpassData?.data?.details?.regadd?.country?.code || "",
                  nationality: singpassData?.data?.details?.nationality?.desc || "",
                  type: "SINGPASS",
                },
              });
            }
          });
      } else {
        setTimeout(() => {
          toast("Something went wrong");
          setPage("Login");
          setTimeout(() => navigate("/login"), 3000);
        }, 30000);
      }
    }
    catch (error) {
      toast.error("An error occurred. Please try again.");
      console.error('An error occurred:', error);
    }
  }

  const handleUserWithoutEmail = (singpassData) => {
    try {
      const token = singpassData?.data?.token
      if (singpassData?.data?.token) {
        setCookie("c4", "SINGPASS");
        setCookie("c6", "SINGPASS_WITHOUT_EMAIL");
        setCookie("c3", singpassData?.data?.token);
        dispatch(addAccessToken(singpassData?.data?.token))
      }
      const type = getCookie("c4");
      const splitSingpass = type.split(" ");
      const signpassType = splitSingpass[1];
      const userName = capitalize(singpassData?.data?.details?.name.value);
      const splitOnSpace = userName.split(" ");
      setCookie("c2", userName);
      setCookie("c1", "");
      const firstname = splitOnSpace.shift();
      const lastname = splitOnSpace.join(" ");
      // dispatch(addEmail(singpassEmail));
      dispatch(addFullName(userName));
      dispatch(addFirstName(firstname));
      dispatch(addLastName(lastname));
      dispatch(addDOB(singpassData?.data?.details?.dob.value));
      dispatch(addUinfin(singpassData?.data?.details?.uinfin.value));
      dispatch(addGender(singpassData?.data?.details?.sex?.desc?.toLowerCase()));
      dispatch(addMobile(singpassData?.data?.details?.mobileno.nbr.value));
      dispatch(addCallingCode(singpassData?.data?.details?.mobileno.areacode.value));
      setCookie("c4", "SINGPASS"); // its required for the token, not for the Profile

      dispatch(
        addAddress1(
          `${singpassData?.data?.details?.regadd.block.value}, ${singpassData?.data?.details?.regadd.street.value}`
        )
      );
      dispatch(
        addAddress2(
          `${singpassData?.data?.details?.regadd.floor.value}, ${singpassData?.data?.details?.regadd.classification}-${singpassData?.data?.details?.regadd.unit.value}, ${singpassData?.data?.details?.regadd.building.value}`
        )
      );
      dispatch(addPostalCode(singpassData?.data?.details?.regadd.postal.value));
      dispatch(addCountry(singpassData?.data?.details?.regadd.country.desc));
      dispatch(addNationality(singpassData?.data?.details?.nationality?.desc));
      const state = {
        firstName: firstname,
        lastName: lastname,
        dob: singpassData?.data?.details?.dob.value,
        email: "",
        uinfin: singpassData?.data?.details?.uinfin.value,
        gender: singpassData?.data?.details?.sex?.desc?.toLowerCase(),
        mobileCode: singpassData?.data?.details?.mobileno.areacode.value,
        mobile: singpassData?.data?.details?.mobileno.nbr.value,
        address1: `${singpassData?.data?.details?.regadd.block.value}, ${singpassData?.data?.details?.regadd.street.value}`,
        address2: `${singpassData?.data?.details?.regadd.floor.value}, ${singpassData?.data?.details?.regadd.classification}-${singpassData?.data?.details?.regadd.unit.value}, ${singpassData?.data?.details?.regadd.building.value}`,
        postalCode: singpassData?.data?.details?.regadd.postal.value,
        country: singpassData?.data?.details?.regadd.country.desc,
        d_country: singpassData?.data?.details?.regadd?.country?.code || "",
        nationality: singpassData?.data?.details?.nationality?.desc || "",
        type: "SINGPASS",
      };

      // Save state to localStorage
      saveStateToLocalStorage(state);

      // Navigate to "/logged/kyc" with the state
      navigate("/logged/kyc", {
        state: state,
      });
    }
    catch (error) {
      toast.error("An error occurred. Please try again.");
      console.error('An error occurred:', error);
    }
    // }
    // });
    // } else {
    //   setTimeout(() => {
    //     toast("Something went wrong");
    //     setPage("Login");
    //     setTimeout(() => navigate("/login"), 3000);
    //   }, 30000);
    // }
  }
  const saveStateToLocalStorage = (state) => {
    try {
      const serializedState = JSON.stringify(state);
      localStorage.setItem('singpassUserState', serializedState);
    } catch (error) {
      console.error('Error saving state to localStorage:', error);
    }
  };
  return (
    <div>
      <p
        style={{
          textAlign: "center",
          marginTop: "80px",
        }}
      >
        {page ? `Redirect to ${page} Page ...` : ""}
      </p>
      <div
        style={{
          alignSelf: "center",
          alignContent: "center",
          justifyContent: "center",
          width: "100%",
          height: "90%",
          margin: "auto",
          display: "flex",
          marginTop: "-30px",
        }}
      >
        <ProgressBar
          height="80"
          width="80"
          ariaLabel="progress-bar-loading"
          wrapperStyle={{}}
          wrapperClass="progress-bar-wrapper"
          borderColor="#F4442E"
          barColor="#51E5FF"
        />
        <ToastContainer />
      </div>
    </div>
  );
}

export default Singpass;
