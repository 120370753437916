import React, { useEffect, useState } from "react";
import Header from "../../../components/header";
import { Container, Row, Col } from "bootstrap-4-react/lib/components/layout";
import { Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import API_PATHS from "../../../constants/apiPaths";
import { getCookie } from "../../../Utils/cookieHandling";
const CardStatus = () => {
  const { email } = useSelector((state) => state);
  const navigate = useNavigate();
  const [accept, setAccept] = useState();

  useEffect(() => {
    axios
      .get(API_PATHS.GET_DETAILS + email, {
        headers: {
          Authorization: `${getCookie("c4")} ${getCookie("c3")}`,
        },
      })
      .then((res) => {
        const data = res.data;
        setAccept(data.is_kyc_approved);
      });
  });
  return (
    <>
      <Header title="Card Status" />
      <Container fluid style={{ margin: "50px" }}>
        <Row>
          <Col lg={7} sm={12}>
            <span
              style={{ fontSize: "29px", fontWeight: "800", lineHeight: "1.5" }}
            >
              Your Card Application has been successfully received.
            </span>
            <br />
            <br />
            <p></p>
            <div style={{ lineHeight: "2" }}>
              <p>Please be patient while you card is being processed...</p>
              <p>Refresh your screen periodically to know your card status</p>
            </div>
          </Col>
          <Col lg={3} sm={12}>
            <Image
              src={require("../../../assets/girl_dance.jpeg")}
              width={200}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CardStatus;
