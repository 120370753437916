import {
  Jumbotron,
  Container,
  Row,
  Col,
} from "bootstrap-4-react/lib/components";
import React from "react";
import Header from "../../../components/header";
import QRCode from "react-qr-code";

const IdVerify = () => {
  return (
    <>
      <Header title="ID Verification" />
      <Container fluid>
        <Jumbotron
          style={{
            marginTop: "30px",
            backgroundColor: "#fff",
          }}
        >
          <Row>
            <Col lg={8} sm={12}>
              <span
                style={{
                  fontSize: "20px",
                  fontWeight: "600",
                }}
              >
                Please provide photos of your card ( NRIC OR FIN ) in the next
                step.
              </span>
              <br />
              <span
                style={{
                  color: "#363636",
                  fontSize: "17px",
                  lineHeight: "25.5px",
                }}
              >
                This is required to complete your profile as directed by the
                singpore regulations.
                <br />
                Please keep your original id handy for scanning
              </span>
              <br />
              <br />
              <span
                style={{
                  backgroundColor: "rgba(222,40,52,0.25)",
                  padding: "5px",
                }}
              >
                {" "}
                Use your phone to proceed with{" "}
                <span style={{ color: "#DE2834" }}>KYC. Scan the QR Code</span>
              </span>
            </Col>
            <Col lg={4} sm={12}>
              <QRCode value="hey" size={190} />
            </Col>
          </Row>
        </Jumbotron>
      </Container>
    </>
  );
};

export default IdVerify;
