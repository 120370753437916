import React from "react";
import { Figure, Button } from "bootstrap-4-react";
import { useNavigate } from "react-router-dom";

const FacebookLoginComponent = ({ facebookSignIn }) => {
  const navigate = useNavigate();

  
  const handleSignIn = async () => {
    try {
      await facebookSignIn();
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Button
      style={{
        background: " #FFFFFF",
        border: "1px solid #F3DEDC",
        boxShadow: "2px 3px 26px rgba(225, 225, 225, 0.17)",
        borderRadius: "12px",
      }}
      onClick={handleSignIn}
    >
      <Figure.Image
        src={require("../../../assets/facebook.png")}
        width="25"       
        style={{paddingRight:"2px" ,paddingTop:"1px"}}
      />
      <span style={{ fontSize: "15px", paddingLeft: "3px",fontWeight:"500" ,color:"#363636"  }}>Facebook</span>
    </Button>
  );
};

export default FacebookLoginComponent;
