import React from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import Register from "./register";

const validationSchema = Yup.object({
  email: Yup.string().email().required("Please enter valid email"),
  password: Yup.string()
    .matches(/\w*[a-z]\w*/, "Password must have a lowercase letter")
    .matches(/\w*[A-Z]\w*/, "Password must have a uppercase letter")
    .matches(/\d/, "Password must have a number")
    .matches(
      /[!@#$%^&*()\-_"=+{};:,<.>]/,
      "Password must have a special character"
    )
    .min(8, ({ min }) => `Password must be at least 8 characters`)
    .required("Password is required"),
  confirmPassword: Yup.string()
    .matches(/\w*[a-z]\w*/, "Password must have a lowercase letter")
    .matches(/\w*[A-Z]\w*/, "Password must have a uppercase letter")
    .matches(/\d/, "Password must have a number")
    .matches(
      /[!@#$%^&*()\-_"=+{};:,<.>]/,
      "Password must have a special character"
    )
    .min(8, ({ min }) => `Password must be at least 8 characters`)
    .oneOf([Yup.ref("password"), null], "Password must match")
    .required("Password is required"),
  emailVerify: Yup.number()
    .typeError("you must specify a number")
    .test(
      "len",
      "Must be exactly 4 digits",
      (val) => val && val.toString().length === 4
    )
    .required("Enter the verification code"),
});

const RegistrationProcess = () => {
  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        email: "",
        password: "",
        confirmPassword: "",
        emailVerify: "",
      }}
    >
      {(formik) => <Register {...formik} />}
    </Formik>
  );
};

export default RegistrationProcess;
