import React, { useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import UpdateMobile from "./updateMobile";


const UpdateMobileProcess = () => {
  const initialValues = {
    phone:"",
    newPhone: "",
    confirmPhone: "",
    
  };

  const validationSchema = Yup.object({
    phone: Yup.number().required("Mobile number is required"),
    newPhone: Yup.string().required("Please enter new Mobile"),
    confirmPhone: Yup.string()
      .oneOf([Yup.ref("newPhone"), null], "Mobile must match")
      .required("Confirm the Number"),
    otp: Yup.number().required("Otp is required"),
  });
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
        {(formik) => <UpdateMobile {...formik} />}
    </Formik>
  );
};

export default UpdateMobileProcess;
