import React from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import Verify2FA from "./verify2FA";
import { useNavigate, useLocation } from "react-router-dom";

const Verify2FAProcess = () => {
  const location = useLocation();

  const validationSchema = Yup.object({
    otp: Yup.string().required("Please enter valid OTP"),
  });

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        otp: "",
        email: location.state ? location.state.email : "",
        type: location.state ? location.state.type : "",
      }}
    >
      {(formik) => <Verify2FA {...formik} />}
    </Formik>
  );
};

export default Verify2FAProcess;
