import React, { useState } from "react";
import { Container, Row, Col, Form, Button } from "bootstrap-4-react";
import { Image } from "react-bootstrap";
import "./reset.css";
import { FaRegHandPointRight } from "react-icons/fa";
import { useNavigate, useLocation } from "react-router-dom";
import API_PATHS from "../../constants/apiPaths";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ResetPassword = (formik) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [changed, setChanged] = useState(false);

  const { values, errors, touched } = formik;

  const resetPassword = () => {
    const obj = {
      email: location.state.email,
      password: values.password,
      OTP: Number(values.emailVerify),
    };

    axios
      .post(API_PATHS.RESET_PASSWORD, obj, {
        headers: {
          accepts: "application/json",
        },
      })
      .then((res) => {
        if (res.data.message === "Password reset successful") {
          setChanged(true);
          toast("Password Reset Successfully");
        } else {
          toast("Incorrect Details");
        }
      });
  };

  return (
    <Container fluid className="myCon">
      <Row>
        <Col sm={12} lg={6}>
          <Image
            src={require("../../assets/paymentPlanet/reset.jpeg")}
            width="100%"
          />
          {/* <Image
            src={require("../../assets/paymentPlanet/PaymentP.png")}
            width="40%"
            style={{ marginTop: "10px" }}
          />
          <Image
            src={require("../../assets/rename.png")}
            width="100%"
            height="100%"
          /> */}
        </Col>
        {changed === true ? (
          <Col className="mycol2" sm={12} lg={6} style={{ padding: "90px" }}>
            <h2
              style={{
                fontSize: "3rem",
                fontWeight: "700",
                lineHeight: "69px",
              }}
            >
              Password Reset Successfully
            </h2>

            <Row justifyContent="center" style={{ paddingTop: "20px" }}>
              <Col className="text-center">
                <Button onClick={() => navigate("/login")}>
                  Click to Login
                </Button>
              </Col>
            </Row>
          </Col>
        ) : (
          <Col className="mycol2" sm={12} lg={6} style={{ padding: "90px" }}>
            <h2
              style={{
                fontSize: "3rem",
                fontWeight: "700",
                lineHeight: "69px",
              }}
            >
              Reset Password
            </h2>

            <div
              style={{
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "17px",
                lineHeight: "26px",
                textTransform: "capitalize",
                float: "left",
                color: " #757575",
                padding: "15px",
              }}
            >
              New Password
            </div>
            <Form.Input
              type="password"
              value={values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              id="password"
              name="password"
              className={
                errors.password && touched.password
                  ? "text-input-error"
                  : "text-input"
              }
            />
            {errors.password && touched.password && (
              <div style={{ color: "red" }}>{errors.password}</div>
            )}

            <div
              style={{
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "17px",
                lineHeight: "26px",
                /* identical to box height */
                textTransform: "capitalize",
                float: "left",
                color: " #757575",
                padding: "15px",
              }}
            >
              Confirm Password
            </div>
            <Form.Input
              type="password"
              value={values.confirmPassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              id="confirmPassword"
              name="confirmPassword"
              className={
                errors.confirmPassword && touched.confirmPassword
                  ? "text-input-error"
                  : "text-input"
              }
            />
            {errors.confirmPassword && touched.confirmPassword && (
              <div style={{ color: "red" }}>{errors.confirmPassword}</div>
            )}
            <div
              style={{
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "17px",
                lineHeight: "26px",
                /* identical to box height */
                textTransform: "capitalize",
                float: "left",
                color: " #757575",
                padding: "15px",
              }}
            >
              OTP
            </div>
            <Form.Input
              type="number"
              value={values.emailVerify}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              id="emailVerify"
              name="emailVerify"
              className={
                errors.emailVerify && touched.emailVerify
                  ? "text-input-error"
                  : "text-input"
              }
            />
            {errors.emailVerify && touched.emailVerify && (
              <div style={{ color: "red" }}>{errors.emailVerify}</div>
            )}
            <Row justifyContent="center" style={{ paddingTop: "20px" }}>
              <Col className="text-center">
                <Button
                  id="forgot-password"
                  disabled={!(formik.isValid && formik.dirty)}
                  onClick={() => resetPassword()}
                >
                  Update
                </Button>
              </Col>
            </Row>
            <Row justifyContent="left" style={{ paddingTop: "20px" }}>
              <Col>
                <span>Password Requirements (At least 4 cominations )</span>
                <br />

                <ul id="ul-styles">
                  <Row>
                    <li style={{ padding: "9px" }}>
                      <FaRegHandPointRight size={20} color="#de2834" /> Minimum
                      8 characters
                    </li>
                    <li style={{ padding: "9px" }}>
                      <FaRegHandPointRight size={20} color="#de2834" /> Upper
                      Case
                    </li>
                    <li style={{ padding: "9px" }}>
                      <FaRegHandPointRight size={20} color="#de2834" /> Lower
                      Case
                    </li>
                    <li style={{ padding: "9px" }}>
                      <FaRegHandPointRight size={20} color="#de2834" /> Numbers
                    </li>
                    <li style={{ padding: "9px" }}>
                      <FaRegHandPointRight size={20} color="#de2834" /> Special
                      Characters
                    </li>
                  </Row>
                </ul>
              </Col>
            </Row>
          </Col>
        )}
      </Row>
      <ToastContainer />
    </Container>
  );
};

export default ResetPassword;
