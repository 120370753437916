import { Button } from "bootstrap-4-react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth } from "../firebase.config";
import { clearEverything } from "../Utils/clearAll";
import { useSelector, useDispatch } from "react-redux";

import {
  addEmail,
  addFullName,
  addAccessToken,
  addPhotoURL,
  addFirstName,
  addLastName,
  addDOB,
  addCallingCode,
  addMobile,
  addGender,
  addUinfin,
  addId,
  addUserType,
  addKYC,
  addIsKycApproved,
  addCardPath,
  addCardNo,
  addCardActivated,
  reset,
} from "../redux/actions";

const SignOut = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSignout = async () => {
    signOut(auth)
      .then(() => {
        dispatch(addEmail(""));
        dispatch(addFullName(""));
        dispatch(addAccessToken(""));
        dispatch(addPhotoURL(""));
        dispatch(addFirstName(""));
        dispatch(addLastName(""));
        dispatch(addDOB(""));
        dispatch(addCallingCode(""));
        dispatch(addMobile(""));
        dispatch(addGender(""));
        dispatch(addUinfin(""));
        dispatch(addId(""));
        dispatch(addUserType(""));
        dispatch(addKYC(""));
        dispatch(addIsKycApproved(""));
        dispatch(addCardPath(""));
        dispatch(addCardNo(""));
        dispatch(addCardActivated(""));
        dispatch(reset());
        clearEverything();
        navigate("/");
      })
      .catch((err) => console.log(err));
  };

  return (
    <Button
      link
      style={{ color: "#fff", backgroundColor: "red", width:"100%" }}
      onClick={handleSignout}
    >
      Sign out
    </Button>
  );
};

export default SignOut;
