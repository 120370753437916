import { Container, Row, Col, Figure } from "bootstrap-4-react";
import { Button, Dropdown } from "bootstrap-4-react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { FaRegHandPointRight } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./header.css"

const CardProgramDisplay = ({ data, pathName, purchased }) => {
  const navigate = useNavigate();
  const cardOptions = [
    "Physical Card",
    "Virtual Card",
    "Physical Card & Virtual Card",
  ];
  const onClickFunction = (curr, item) => {
    if (curr === "Physical Card") {
      if (item.path) {
        navigate(item.path, {
          state: { type: item.cardType || " " },
        });
      } else {
        if (purchased.cardPurchased === false) {
          navigate(pathName, {
            state: { type: item.cardType || " " },
          });
        }
      }
    } else {
      toast("Coming Soon!");
    }
  };
  return (
    <>
      {data.map((item, index) => {
        return index % 2 === 0 ? (
          <div
            style={{
              opacity: purchased
                ? purchased.cardPurchased === true && !item.path
                  ? 1
                  : 1
                : 1,
              backgroundColor: purchased
                ? purchased.cardPurchased === true && !item.path
                  ? "rgba(69, 71, 71, 0.5)"
                  : "transparent"
                : "transparent",
              zIndex: purchased
                ? purchased.cardPurchased === true && !item.path
                  ? -100
                  : 1
                : 1,
            }}
          >
            <Container fluid style={{ padding: "30px" }} key={index}>
              <Row style={{ justifyContent: "space-between" }}>
                <Col key={index}>
                  <span style={{ fontSize: 20, fontWeight: "bolder" }}>
                    {item.cardType ? item.cardType : item.programTitle}
                  </span>
                </Col>
              </Row>
              <br />
              {/* {
              item.programTitle === "JDB Card Program" &&  <ul style={{ listStyle: "none", flexDirection: "row" }}>
              <li style={{ padding: "9px" }}>
                <FaRegHandPointRight size={20} color="#de2834" /> 
                Easily manage your spending
              </li>
              <li style={{ padding: "9px" }}>
                <FaRegHandPointRight size={20} color="#de2834" /> Widely accepted worldwide
              </li>
              <li style={{ padding: "9px" }}>
                <FaRegHandPointRight size={20} color="#de2834" /> No risk of accumulating debt
              </li>
            </ul>
             } */}
              <Row style={{ justifyContent: "space-between" }}>
                <Col lg={6} sm={12}>
                  <ul style={{ listStyle: "none", flexDirection: "row" }}>
                    {/* <li style={{ padding: "9px" }}>
                  <FaRegHandPointRight size={20} color="#de2834" />
                  {item.cardPrice || "Minimum 12 characters"}
                </li> */}
                    {item.path === "/logged/card/jdb" ? (
                      <ul style={{ listStyle: "none", flexDirection: "row" }}>
                        <li style={{ padding: "9px" }}>
                          <FaRegHandPointRight size={20} color="#de2834" />{" "}
                          Easily manage your spending
                        </li>
                        <li style={{ padding: "9px" }}>
                          <FaRegHandPointRight size={20} color="#de2834" />{" "}
                          Widely accepted worldwide
                        </li>
                        <li style={{ padding: "9px" }}>
                          <FaRegHandPointRight size={20} color="#de2834" /> No
                          risk of accumulating debt
                        </li>
                      </ul>
                    ) : (
                      <li style={{ padding: "9px" }}>
                        <FaRegHandPointRight size={20} color="#de2834" />{" "}
                        Monthly Max Load - {item.max_limit}
                      </li>
                    )}
                  </ul>
                </Col>
                <Col lg={4} sm={12}>
                  <Figure>
                    <Figure.Image src={item.image} width={250} />
                  </Figure>
                </Col>

                <Col className="text-center">
                  {item.path ? (
                    <Button
                      disabled={
                        purchased
                          ? purchased.cardPurchased === true && !item.path
                          : null
                      }
                      id="login-button"
                      onClick={() =>
                        item.path
                          ? navigate(item.path, {
                              state: { type: item.cardType || " " },
                            })
                          : purchased.cardPurchased === false
                          ? navigate(pathName, {
                              state: { type: item.cardType || " " },
                            })
                          : null
                      }
                    >
                      Apply
                    </Button>
                  ) : (
                    <Dropdown className="mainDropped">
                      <Dropdown.Button id="dropdownMenuButton" disabled={purchased.cardPurchased}>
                        Select
                      </Dropdown.Button>
                      <Dropdown.Menu aria-labelledby="dropdownMenuButton" className="dropped">
                        {cardOptions.map((curr, index) => {
                          return (
                            <Dropdown.Item
                              backgroundColor={"none"}
                              onClick={(e) => {
                                onClickFunction(curr, item);
                              }}
                            >
                              {curr}
                            </Dropdown.Item>
                          );
                        })}
                      </Dropdown.Menu>
                    </Dropdown>
                  )}
                </Col>
              </Row>
              <hr
                style={{
                  color: "#fff",
                  backgroundColor: "#de2834",
                  height: 0.9,
                  width: "100%",
                  marginTop: 35,
                }}
              />
            </Container>
          </div>
        ) : (
          <div
            style={{
              opacity: purchased
                ? purchased.cardPurchased === true && !item.path
                  ? 1
                  : 1
                : 1,
              backgroundColor: purchased
                ? purchased.cardPurchased === true && !item.path
                  ? "rgba(69, 71, 71, 0.5)"
                  : "transparent"
                : "transparent",
              zIndex: purchased
                ? purchased.cardPurchased === true && !item.path
                  ? -100
                  : 1
                : 1,
            }}
          >
            <Container
              fluid
              style={{ justifyContent: "center", padding: "30px" }}
              key={index}
            >
              <Row style={{ padding: 4, paddingBottom: "20px" }}>
                <Col>
                  <span style={{ fontSize: 20, fontWeight: "bolder" }}>
                    {item.cardType ? item.cardType : item.programTitle}
                  </span>
                </Col>
              </Row>
              {item.programTitle !== "NIUM Card Program" ? (
                <Row style={{ justifyContent: "space-between" }}>
                  <Col lg={6} sm={12} key={index}>
                    <Figure>
                      <Figure.Image src={item.image} width={250} />
                    </Figure>
                  </Col>
                  <Col
                    lg={4}
                    sm={12}
                    style={{ justifyContent: "space-around" }}
                  >
                    <ul style={{ listStyle: "none", flexDirection: "row" }}>
                      <li style={{ padding: "9px" }}>
                        <FaRegHandPointRight size={20} color="#de2834" />{" "}
                        {item.cardPrice || "Minimum 12 characters"}
                      </li>
                      <li style={{ padding: "9px" }}>
                        <FaRegHandPointRight size={20} color="#de2834" />{" "}
                        Monthly Max Load - {item.max_limit}
                      </li>
                    </ul>
                  </Col>
                  <Col className="text-center">
                    {item.path ? (
                      <Button
                        disabled={
                          purchased
                            ? purchased.cardPurchased === true && !item.path
                            : null
                        }
                        id="login-button"
                        onClick={() =>
                          item.path
                            ? navigate(item.path, {
                                state: { type: item.cardType || " " },
                              })
                            : purchased.cardPurchased === false
                            ? navigate(pathName, {
                                state: { type: item.cardType || " " },
                              })
                            : null
                        }
                      >
                        Apply
                      </Button>
                    ) : (
                      <Dropdown className="mainDropped">
                        <Dropdown.Button id="dropdownMenuButton" disabled={purchased.cardPurchased}>
                          Select
                        </Dropdown.Button>
                        <Dropdown.Menu aria-labelledby="dropdownMenuButton" className="dropped">
                          {cardOptions.map((curr, index) => {
                            return (
                              <Dropdown.Item
                                backgroundColor={"none"}
                                onClick={(e) => {
                                  onClickFunction(curr, item);
                                }}
                              >
                                {curr}
                              </Dropdown.Item>
                            );
                          })}
                        </Dropdown.Menu>
                      </Dropdown>
                    )}
                  </Col>
                </Row>
              ) : (
                <p className="text-center">Coming Soon</p>
              )}

              <hr
                style={{
                  color: "#fff",
                  backgroundColor: "#de2834",
                  height: 0.9,
                  width: "100%",
                  marginTop: 35,
                }}
              />
            </Container>
          </div>
        );
      })}
    </>
  );
};

export default CardProgramDisplay;
