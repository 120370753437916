import React, { useEffect, useState } from "react";
import { Container, Col, Row, Image } from "react-bootstrap";
import "./register.css";
import { auth } from "../../firebase.config";
import {
  FacebookAuthProvider,
  GoogleAuthProvider,
  OAuthProvider,
  onAuthStateChanged,
  signInWithPopup,
} from "firebase/auth";
import axios from "axios";
import GoogleLoginComponent from "./socialMediaRegistration/google";
import FacebookLoginComponent from "./socialMediaRegistration/facebook";
import AppleLoginComponent from "./socialMediaRegistration/apple";
import { Form, Button } from "bootstrap-4-react";
import { useNavigate } from "react-router-dom";
import API_PATHS from "../../constants/apiPaths";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import nav from "bootstrap-4-react/lib/components/nav";
import { url } from "../../constants/url";
import { useDispatch } from "react-redux";
import { setCookie } from "../../Utils/cookieHandling";

import {
  addEmail,
  addFullName,
  addAccessToken,
  addPhotoURL,
  addFirstName,
  addLastName,
  addDOB,
  addCallingCode,
  addMobile,
  addGender,
  addUinfin,
  addId,
  addUserType,
  addKYC,
  addIsKycApproved,
  addCardPath,
  addCardNo,
  addCardActivated,
  reset,
  setFirstLogin,
} from "../../redux/actions";
import { clearEverything } from "../../Utils/clearAll";

toast.configure();
const Register = (formik) => {
  const dispatch = useDispatch();
  // var Window;
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(false);
  const { values, touched, dirty, isValid, errors } = formik;

  const notify = () => toast.success("OTP Send!");
  const registered = () => toast.success("Registered Successfully");

  const sendOTP = (e) => {
    if (!values.email) {
      return toast("Email required");
    }
    e.preventDefault();
    const obj = {
      email: values.email,
    };
    if (!values.email) {
      return toast("Email required");
    }
    axios
      .post(API_PATHS.OTP, obj, {
        headers: {
          accepts: "application/json",
        },
      })
      .then((res) => {
        if (res?.data?.message) {
          toast(res.data.message);
        } else {
          toast("Something went wrong");
        }
      })
      .catch((err) => console.log(err));
  };
  const registrationProcess = (e) => {
    if (!values.email) {
      return toast("Email required");
    }
    if (!values.password) {
      return toast("Password required");
    }
    if (!values.confirmPassword) {
      return toast("Confirm Password required");
    }
    if (!values.emailVerify) {
      return toast("OTP required");
    }
    if (!isChecked) {
      return toast("Please accept the terms and conditions");
    }
    clearRedux();
    clearEverything();
    e.preventDefault();
    const obj = {
      email: values.email,
      password: values.password,
      OTP: Number(values.emailVerify),
    };
    axios
      .post(API_PATHS.USER_REGISTER, obj, {
        headers: {
          accepts: "application/json",
        },
      })
      .then((res) => {
        if (res.data.message) {
          toast(res.data.message);
          if (res.data.message === "registration successful") {
            const headerData = res.data.token.split(" ");
            const token = headerData[1];
            setCookie("c1", values.email);
            setCookie("c2", "new user");
            setCookie("c3", token);
            setCookie("c4", "Bearer");
            dispatch(addEmail(values.email));
            navigate("/verify2fa", {
              state: {
                email: values.email,
                type: "Bearer",
              },
            });
            // toast("Registered Successfully. Please login");

            // navigate("/login");
          }
        } else {
          toast("Invalid OTP!");
        }
      })
      .catch((err) => console.log(err));
  };
  const capitalize = (str) => {
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };
  const clearRedux = async () => {
    dispatch(addEmail(""));
    dispatch(addFullName(""));
    dispatch(addAccessToken(""));
    dispatch(addPhotoURL(""));
    dispatch(addFirstName(""));
    dispatch(addLastName(""));
    dispatch(addDOB(""));
    dispatch(addCallingCode(""));
    dispatch(addMobile(""));
    dispatch(addGender(""));
    dispatch(addUinfin(""));
    dispatch(addId(""));
    dispatch(addUserType(""));
    dispatch(addKYC(""));
    dispatch(addIsKycApproved(""));
    dispatch(addCardPath(""));
    dispatch(addCardNo(""));
    dispatch(addCardActivated(""));
    dispatch(reset());
    dispatch(setFirstLogin(""));
  };
  const openSingPass = () => {
    clearRedux();
    clearEverything();
    let path;
    axios.get(API_PATHS.SINGPASS_CREATE_LINK).then((res) => {
      let paramString = res.data.url.split(".&")[1];
      let queryString = new URLSearchParams(paramString);
      const state = queryString.get("state");
      setCookie("c5", state);
      setCookie("c3", "empty");
      setCookie("c4", "SINGPASS REGISTER");
      Window = window.open(
        res.data.url,
        "_self",
        "width=700, height=500, top=230, left=540"
      );
      // repeat(state);
    });
  };
  const ssoRegister = async (provider) => {
    clearRedux();
    clearEverything();
    signInWithPopup(auth, provider)
      .then((result) => {
        const sso_token =
          result.user.email != null
            ? result.user.accessToken
            : result._tokenResponse.idToken;
        const currentUser =
          result.user.email != null ? result.user : result._tokenResponse;
        if (currentUser?.email) {
          axios
            .get(API_PATHS.USER_VERIFY + currentUser?.email, {
              headers: {
                accepts: "application/json",
              },
            })
            .then((res1) => {
              const { email, displayName, photoURL } = currentUser;
              const userName = capitalize(displayName);
              const splitOnSpace = userName.split(" ");
              const firstname = splitOnSpace.shift();
              const lastname = splitOnSpace.join(" ");
              setCookie("c1", email);
              setCookie("c2", userName || "New User");
              setCookie("c3", sso_token);
              setCookie("c4", "SSO_USER");
              dispatch(addEmail(email));
              dispatch(addFullName(userName));
              dispatch(addFirstName(firstname));
              dispatch(addLastName(lastname));
              dispatch(addPhotoURL(photoURL));
              if (res1.data.message) {
                axios
                  .get(API_PATHS.GET_DETAILS + currentUser?.email, {
                    headers: {
                      accepts: "application/json",
                      Authorization: `SSO_USER ${sso_token}`,
                    },
                  })
                  .then((res) => {
                    if (res.data.id) {
                      if (res.data.suspended == "1") {
                        toast("Your Account has been suspended");
                        return false;
                      }
                      res.data.card_activated === 1 ||
                        res.data.card_activated === 2
                        ? navigate("/logged/dashboard")
                        : res.data.jdbCardNumber1
                          ? navigate("/logged/card/cardVerify")
                          : res.data.payment_status === "paid"
                            ? navigate("/logged/card/cardStatus")
                            : res.data.kyc
                              ? navigate("/logged/kycHold")
                              : navigate("/logged/kyc");
                    } else {
                      toast("Something went wrong. Please try again");
                      clearRedux();
                      clearEverything();
                    }
                  });
              } else {
                navigate("/2fa", {
                  state: {
                    firstName: firstname,
                    lastName: lastname,
                    profilePic: photoURL,
                    type: "SSO_USER",
                  },
                });
              }
            });
        } else {
          toast("No user Found");
        }
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        const email = error.customData.email;
        const credential = GoogleAuthProvider.credentialFromError(error);
      });
  };
  const googleSignIn = async () => {
    const provider = await new GoogleAuthProvider();
    provider.addScope("email");
    ssoRegister(provider);
  };
  const appleSignIn = () => {
    const provider = new OAuthProvider("apple.com");
    provider.addScope("email");
    ssoRegister(provider);
  };
  const facebookSignIn = () => {
    const provider = new FacebookAuthProvider();
    provider.addScope("email");
    ssoRegister(provider);
  };
  return (
    <Container fluid className="myCon">
      <Row>
        <Col sm={12} lg={6}>
          <Image src={require("../../assets/log.jpeg")} width="100%" />
        </Col>
        <Col className="mycol2" sm={12} lg={6} style={{ padding: "40px" }}>
          <h2
            style={{
              fontSize: "3rem ",
              fontWeight: "700",
            }}
          >
            Create Account
          </h2>
          <Row style={{ marginTop: "10px", padding: "0.2rem" }}>
            <Col>
              <GoogleLoginComponent googleSignIn={googleSignIn} />
            </Col>

            <Col>
              <FacebookLoginComponent facebookSignIn={facebookSignIn} />
            </Col>
            <Col>
              <AppleLoginComponent appleSignIn={appleSignIn} />
            </Col>
          </Row>
          <Row justifyContent="md-center">
            <Col className="text-center">
              <span
                style={{
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "2vh",
                  lineHeight: "27px",
                  textTransform: "lowercase",
                  color: "#545454",
                }}
              >
                - or -
              </span>
            </Col>
          </Row>
          <Row
            justifyContent="left"
            style={{ margin: "10px", fontSize: "10px" }}
          >
            <Col style={{ textAlign: "left" }}>
              <span>Email</span>
              <Form.Input
                type="text"
                value={values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                id="email"
                name="email"
                className={
                  errors.email && touched.email
                    ? "text-input-error"
                    : "text-input"
                }
              />
              {errors.email && touched.email && (
                <div style={{ color: "red" }}>{errors.email}</div>
              )}
              <span style={{ float: "right", padding: 4 }}>
                {values.email && (
                  <Button id="otp-button" onClick={(e) => sendOTP(e)}>
                    Send OTP
                  </Button>
                )}
              </span>
            </Col>
          </Row>
          <Row justifyContent="left" style={{ margin: "10px" }}>
            <Col lg={6} sm={12} style={{ textAlign: "left", fontSize: "10px" }}>
              <span>Password</span>
              <Form.Input
                type="password"
                value={values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                id="password"
                name="password"
                className={
                  errors.password && touched.password
                    ? "text-input-error"
                    : "text-input"
                }
              />
              {errors.password && touched.password && (
                <div style={{ color: "red" }}>{errors.password}</div>
              )}
            </Col>

            <Col lg={6} sm={12} style={{ textAlign: "left", fontSize: "10px" }}>
              <span>Confirm Password</span>
              <Form.Input
                type="password"
                value={values.confirmPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                id="confirmPassword"
                name="confirmPassword"
                className={
                  errors.confirmPassword && touched.confirmPassword
                    ? "text-input-error"
                    : "text-input"
                }
              />
              {errors.confirmPassword && touched.confirmPassword && (
                <div style={{ color: "red" }}>{errors.confirmPassword}</div>
              )}
            </Col>
          </Row>
          <Row
            justifyContent="left"
            style={{ margin: "10px", fontSize: "10px" }}
          >
            <Col style={{ textAlign: "left" }}>
              <span>Email Verification</span>
              <Form.Input
                type="text"
                value={values.emailVerify}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                id="emailVerify"
                name="emailVerify"
                className={
                  errors.emailVerify && touched.emailVerify
                    ? "text-input-error"
                    : "text-input"
                }
              />
              {errors.emailVerify && touched.emailVerify && (
                <div style={{ color: "red", fontSize: "12px" }}>
                  {errors.emailVerify}
                </div>
              )}
            </Col>
          </Row>
          <Row justifyContent="left" style={{ margin: "10px" }}>
            <Col style={{ textAlign: "left" }}>
              <Form.Check className="form">
                <Form.Checkbox
                  id="terms"
                  className="input-check"
                  style={{ lineHeight: "2px" }}
                  onChange={() => {
                    setIsChecked(!isChecked);
                  }}
                />
                <Form.CheckLabel htmlFor="terms">
                  <span style={{ fontSize: "10px" }} className="capitalized">
                    By registering an account , I hereby agree to abide by the
                    platform{" "}
                    <span style={{ color: "#de2834", fontSize: "10px" }}>
                      Terms of use{" "}
                    </span>{" "}
                    and have read and understood the{" "}
                    <span style={{ color: "#de2834", fontSize: "10px" }}>
                      Privacy Policy
                    </span>
                  </span>
                </Form.CheckLabel>
              </Form.Check>
            </Col>
          </Row>
          <Row justifyContent="md-center">
            <Col className="text-center">
              <button
                id="register-button"
                disabled={!(formik.isValid && formik.dirty && isChecked)}
                onClick={(e) => registrationProcess(e)}
                style={{
                  opacity:
                    formik.isValid && formik.dirty && isChecked ? 10 : 0.5,
                }}
              >
                Register
              </button>
            </Col>
          </Row>
          <Row justifyContent="md-center">
            <Col className="text-center">
              Already have account ?{" "}
              <Button
                link
                style={{ color: "#de2834 " }}
                onClick={() => navigate("/login")}
              >
                Login
              </Button>
            </Col>
          </Row>
          <br />
          <Row justifyContent="md-center">
            <Col className="text-center">
              <Button id="singpass-button" onClick={() => openSingPass()}>
                Retrive MyInfo with{" "}
                <span style={{ color: "#de2834" }}>SINGPASS</span>
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <ToastContainer />
    </Container>
  );
};

export default Register;
