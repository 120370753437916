import React, { useEffect, useState } from "react";
import { Container, Col, Row, Image } from "react-bootstrap";
import "./login.css";
import { auth } from "../../firebase.config";
import {
  FacebookAuthProvider,
  GoogleAuthProvider,
  onAuthStateChanged,
  OAuthProvider,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import { setCookie } from "../../Utils/cookieHandling";
import Cookies from "universal-cookie";
import { useSelector, useDispatch } from "react-redux";
import {
  addEmail,
  addFullName,
  addAccessToken,
  addPhotoURL,
  addFirstName,
  addLastName,
  addDOB,
  addCallingCode,
  addMobile,
  addGender,
  addUinfin,
  addId,
  addUserType,
  addKYC,
  addIsKycApproved,
  addCardPath,
  addCardNo,
  addCardActivated,
  reset,
  setFirstLogin,
} from "../../redux/actions";
import axios from "axios";
import { MdOutlineEmail } from "react-icons/md";
import GoogleLoginComponent from "./socialMediaLogin/google";
import FacebookLoginComponent from "./socialMediaLogin/facebook";
import AppleLoginComponent from "./socialMediaLogin/apple";
import { Form, Button } from "bootstrap-4-react";
import { useNavigate } from "react-router-dom";
import API_PATHS from "../../constants/apiPaths";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-tippy/dist/tippy.css";
import { clearEverything } from "../../Utils/clearAll";
toast.configure();

const Login = (formik) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { values, touched, dirty, isValid, errors } = formik;
  const notify = (data) => toast.success(data);
  const capitalize = (str) => {
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };
  const clearRedux = async () => {
    dispatch(addEmail(""));
    dispatch(addFullName(""));
    dispatch(addAccessToken(""));
    dispatch(addPhotoURL(""));
    dispatch(addFirstName(""));
    dispatch(addLastName(""));
    dispatch(addDOB(""));
    dispatch(addCallingCode(""));
    dispatch(addMobile(""));
    dispatch(addGender(""));
    dispatch(addUinfin(""));
    dispatch(addId(""));
    dispatch(addUserType(""));
    dispatch(addKYC(""));
    dispatch(addIsKycApproved(""));
    dispatch(addCardPath(""));
    dispatch(addCardNo(""));
    dispatch(addCardActivated(""));
    dispatch(reset());
    dispatch(setFirstLogin(""));
  };
  const ssoLogin = async (provider) => {
    clearRedux();
    clearEverything();
    signInWithPopup(auth, provider)
      .then((result) => {
        const sso_token =
          result.user.email != null
            ? result.user.accessToken
            : result._tokenResponse.idToken;
        const currentUser =
          result.user.email != null ? result.user : result._tokenResponse;
        if (currentUser?.email) {
          axios
            .get(API_PATHS.USER_VERIFY + currentUser?.email, {
              headers: {
                accepts: "application/json",
              },
            })
            .then((res1) => {
              const { email, displayName, photoURL } = currentUser;
              const userName = capitalize(displayName);
              const splitOnSpace = userName.split(" ");
              const firstname = splitOnSpace.shift();
              const lastname = splitOnSpace.join(" ");
              setCookie("c1", email);
              setCookie("c2", userName || "New User");
              setCookie("c3", sso_token);
              setCookie("c4", "SSO_USER");
              dispatch(addEmail(email));
              dispatch(addFullName(userName));
              dispatch(addFirstName(firstname));
              dispatch(addLastName(lastname));
              dispatch(addPhotoURL(photoURL));
              if (res1.data.message) {
                axios
                  .get(API_PATHS.GET_DETAILS + currentUser?.email, {
                    headers: {
                      accepts: "application/json",
                      Authorization: `SSO_USER ${sso_token}`,
                    },
                  })
                  .then((res) => {
                    if (res.data.id) {
                      if (res.data.suspended == "1") {
                        toast("Your Account has been suspended");
                        return false;
                      }
                      res.data.card_activated === 1 ||
                        res.data.card_activated === 2
                        ? navigate("/logged/dashboard")
                        : res.data.jdbCardNumber1
                          ? navigate("/logged/card/cardVerify")
                          : res.data.payment_status === "paid"
                            ? navigate("/logged/card/cardStatus")
                            : res.data.kyc
                              ? navigate("/logged/kycHold")
                              : navigate("/logged/kyc");
                    } else {
                      toast("Something went wrong. Please try again");
                      clearRedux();
                      clearEverything();
                    }
                  });
              } else {
                navigate("/2fa", {
                  state: {
                    firstName: firstname,
                    lastName: lastname,
                    profilePic: photoURL,
                    type: "SSO_USER",
                  },
                });
              }
            });
        } else {
          toast("No user Found");
        }
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        const email = error.customData.email;
        const credential = GoogleAuthProvider.credentialFromError(error);
      });
  };
  const loginUser = (e) => {
    clearRedux();
    clearEverything();
    e.preventDefault();
    const obj = {
      email: values.email,
      password: values.password,
    };
    axios
      .post(API_PATHS.USER_LOGIN, obj, {
        headers: {
          accepts: "application/json",
        },
      })
      .then((res) => {
        if (res.data.token) {
          const headerData = res.data.token.split(" ");
          const token = headerData[1];
          axios
            .get(API_PATHS.GET_DETAILS + obj.email, {
              headers: {
                accepts: "application/json",
                Authorization: `Bearer ${token}`,
              },
            })
            .then((response) => {
              if (response?.data?.suspended == "1") {
                toast("Your Account has been suspended");
                return false;
              }
              dispatch(setFirstLogin(response.data.pep === "1" ? true : false));
              toast(res.data.message);
              const data = response.data;
              setCookie("c1", obj.email);
              if (data.first_name) {
                dispatch(
                  addFullName(
                    data.first_name
                      ? data.first_name + " " + data.last_name
                      : "new user"
                  )
                );
                dispatch(addId(data.id));
                dispatch(addPhotoURL(data.profile_pic));
                dispatch(addKYC(data.kyc));
              }
              setCookie(
                "c2",
                data.first_name
                  ? data.first_name + " " + data.last_name
                  : " new user"
              );
              setCookie("c3", token);
              setCookie("c4", "Bearer");
              dispatch(addEmail(obj.email));

              if (response.data.ascii_value === ("" || null)) {
                navigate("/verify2fa", {
                  state: {
                    email: values.email,
                    type: "Bearer",
                  },
                });
                return false;
              }

              data.payment_status === "pending"
                ? navigate("/logged/card/pending")
                : data.card_activated === 1 || data.card_activated === 2
                  ? navigate("/logged/dashboard")
                  : data.jdbCardNumber1
                    ? navigate("/logged/card/cardVerify")
                    : data.payment_status === "paid"
                      ? navigate("/logged/card/cardStatus")
                      : data.kyc
                        ? navigate("/logged/kycHold")
                        : navigate("/logged/kyc");
            })
            .catch((err) => {
              console.log(err.response.data.message);
              toast(err.response.data.message);
            });
        } else if (res.data.message === "Email is not Registered") {
          toast(res.data.message);
        } else {
          toast(res.data.message);
        }
      });
  };
  const googleSignIn = async () => {
    const provider = await new GoogleAuthProvider();
    provider.addScope("email");
    ssoLogin(provider);
  };
  const appleSignIn = async () => {
    const provider = new OAuthProvider("apple.com");
    provider.addScope("email");
    provider.addScope("name");
    ssoLogin(provider);
  };
  const facebookSignIn = () => {
    const provider = new FacebookAuthProvider();
    provider.addScope("email");
    ssoLogin(provider);
  };
  const openSingPass = () => {
    clearRedux();
    clearEverything();
    let path;
    axios.get(API_PATHS.SINGPASS_CREATE_LINK).then((res) => {
      let paramString = res.data.url.split(".&")[1];
      let queryString = new URLSearchParams(paramString);
      const state = queryString.get("state");
      setCookie("c5", state);
      setCookie("c3", "empty");
      setCookie("c4", "SINGPASS LOGIN");
      Window = window.open(
        res.data.url,
        "_self",
        "width=700, height=500, top=230, left=540"
      );
    });
  };
  return (
    <Container fluid className="myCon">
      <Row>
        <Col sm={12} lg={6}>
          <Image
            src={require("../../assets/log.jpeg")}
            width="100%"
            height="100%"
          />
          {/* <Image
            src={require("../../assets/paymentPlanet/PaymentP.png")}
            width="50%"
            style={{marginTop:"10px"}}
          />
          <Image
            src={require("../../assets/mobile(2).jpeg")}
            width="100%"
            height="100%"
          /> */}
        </Col>
        <Col className="mycol2" sm={12} lg={6} style={{ padding: "50px" }}>
          <h2
            style={{
              fontSize: "3rem ",
              fontWeight: "700",
              lineHeight: "69px",
            }}
          >
            Log In
          </h2>
          <Row style={{ marginTop: "30px", padding: "0.2rem" }}>
            <Col>
              <GoogleLoginComponent googleSignIn={googleSignIn} />
            </Col>

            <Col>
              <FacebookLoginComponent facebookSignIn={facebookSignIn} />
            </Col>
            <Col>
              <AppleLoginComponent appleSignIn={appleSignIn} />
            </Col>
          </Row>
          <Row justifyContent="md-center">
            <Col className="text-center">
              <span
                style={{
                  fontSize: "2vh",
                  color: "gray",
                }}
              >
                - or -
              </span>
            </Col>
          </Row>
          <Row style={{ margin: "5px", fontSize: "17px" }}>
            <Col style={{ textAlign: "left" }}>
              <span className="capitalized">Email</span>
              <Form.Input
                type="text"
                value={values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                id="email"
                name="email"
                className={
                  errors.email && touched.email
                    ? "text-input-error"
                    : "text-input"
                }
              />
              {errors.email && touched.email && (
                <div style={{ color: "red" }}>{errors.email}</div>
              )}
            </Col>
          </Row>
          <br />
          <Row style={{ margin: "5px", fontSize: "17px" }}>
            <Col style={{ textAlign: "left" }}>
              <span className="capitalized">Password</span>
              <Form.Input
                type="password"
                value={values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                id="password"
                name="password"
                className={
                  errors.password && touched.password
                    ? "text-input-error"
                    : "text-input"
                }
              />
              {errors.password && touched.password && (
                <div style={{ color: "red" }}>{errors.password}</div>
              )}
              <div style={{ float: "right" }}>
                <Button
                  link
                  style={{ color: "#de2834 " }}
                  onClick={() => navigate("/forgotPassword")}
                >
                  Forgot Password?{" "}
                </Button>
              </div>
            </Col>
          </Row>
          <Row justifyContent="md-center">
            <Col className="text-center">
              <button
                id="login-button"
                disabled={!(formik.isValid && formik.dirty)}
                onClick={(e) => loginUser(e)}
                style={{
                  opacity: formik.isValid && formik.dirty ? 1 : 0.5,
                }}
              >
                Login
              </button>
            </Col>
          </Row>
          <br />
          <Row justifyContent="md-center">
            <Col className="text-center">
              Don't have any account ?{" "}
              <Button
                link
                style={{ color: "#de2834 " }}
                onClick={() => navigate("/register")}
              >
                Sign Up
              </Button>
            </Col>
          </Row>
          <br />
          <Row justifyContent="md-center">
            <Col className="text-center">
              <Button
                id="singpass-button"
                onClick={() => {
                  // setShow(true);
                  openSingPass();
                }}
              >
                Login with <span style={{ color: "#de2834" }}>SINGPASS</span>
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <ToastContainer />
    </Container>
  );
};

export default Login;
