export const COLUMNS = [
    {
        Header  : "Description",
        accessor : "description",
    },
    {
        Header  : "Black Premium",
        accessor : "black_premium",
    },
    {
        Header : "Metal - Black",
        accessor : "metal_black"
    },
    {
        Header : "Metal - Silver",
        accessor : "metal_silver"
    },
    {
        Header : "Metal - Gold",
        accessor : "metal_gold"
    }

]