import React, { useState, useEffect } from "react";
import Header from "../../../components/header";
import { useLocation, useNavigate } from "react-router-dom";
import { Container, Row, Col, Form, Button } from "bootstrap-4-react";
import axios from "axios";
import API_PATHS from "../../../constants/apiPaths";
import MySelect from "../../../components/mySelect";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./stripe.css";
import { useSelector } from "react-redux";
import { ProgressBar } from "react-loader-spinner";
import { getCookie } from "../../../Utils/cookieHandling";
import { url } from "../../../constants/url";
toast.configure();

const options = [
  { value: 1, label: "01" },
  { value: 2, label: "02" },
  { value: 3, label: "03" },
  { value: 4, label: "04" },
  { value: 5, label: "05" },
  { value: 6, label: "06" },
  { value: 7, label: "07" },
  { value: 8, label: "08" },
  { value: 9, label: "09" },
  { value: 10, label: "10" },
  { value: 11, label: "11" },
  { value: 12, label: "12" },
];

const StripeJDB = (props) => {
  const { email, userType, accessToken } = useSelector((state) => state);
  const { formik } = props;
  const { values, errors, touched } = formik;
  const details = props.data;
  const attachments = props.attachments;
  const location = useLocation();
  const [cvv, setCvv] = useState();
  const [cardNumber, setCardNumber] = useState();
  let card = " ";
  const [amount, setAmount] = useState(0);
  const navigate = useNavigate();
  const [pass, setPass] = useState(false);

  const [month, setMonth] = useState();
  const [year, setYear] = useState();
  const [yearList, setYearList] = useState([]);

  useEffect(() => {
    values.expiryMonth = month;
    values.expiryYear = year;
    card = location.state.applicationDetails.card_type || " ";
    setAmount(card === "JDB Premium Black" ? 130 : 250);
    getDropList();
  }, [location.state.applicationDetails.card_type, month, year]);

  const getDropList = () => {
    const year = new Date().getFullYear();

    for (let i = 0; i <= 6; i++) {
      yearList.push({
        value: year + i,
        label: year + i,
      });
    }
  };

  useEffect(() => {
    values.cardNumber = cardNumber;
    values.cvv = cvv;
  }, [cvv, cardNumber]);

  const handlePayment = () => {
    if (!values.cardNumber) {
      toast.error("Card required");
      return;
    }
    if (values.cardNumber.replace(/\s/g, "").length != 16) {
      toast.error("Card Number Must be Of 16 Digits");
      return;
    }
    if (!values.cvv) {
      toast.error("CVV required");
      return;
    }
    if (!values.expiryMonth || !values.expiryYear) {
      toast.error("Expiry Date Required");
      return;
    }
    if (!values.cardHolderName) {
      toast.error("Card Holder Name required");
      return;
    }
    setPass(true);
    const obj = {
      email: email,
      amount: amount,
      cardNumber: Number(values.cardNumber.replace(/\s+/g, "")),
      month: values.expiryMonth,
      year: values.expiryYear,
      // is_cardVerified: "0",
    };
    axios
      .post(API_PATHS.STRIPE, obj, {
        headers: {
          accepts: "application/json",
          Authorization: `${getCookie("c4")} ${getCookie("c3")}`,
        },
      })
      .then((res) => {
        toast(res.data.message);
        setPass(false);
        if (res.data.message === "Payment Completed") {
          axios
            .put(API_PATHS.JDB_APPLY, details, {
              headers: {
                accepts: "application/json",
                Authorization: `${getCookie("c4")} ${getCookie("c3")}`,
              },
            })
            .then((response) => {
              toast(response.data.message);
              if (response.data.message === "Card Applied Successful") {
                setTimeout(() => {
                  navigate("/logged/card/cardVerify");
                }, 2000);
              } else {
                // toast("Payment Failed");
                setPass(false);
                toast("Error Occured");
              }
            });
        } else {
          toast("Payment Failed");
          return;
        }
      });
  };

  return (
    <>
      <Header title="Payment Process" />
      <Container fluid>
        <span
          style={{
            justifyContent: "center",
            fontSize: "18px",
            lineHeight: "27px",
            fontWeight: "400",
            paddingTop: "30px",
          }}
        >
          JDB Payment Process for {location.state.applicationDetails.card_type}
        </span>
        <Row>
          <Col lg={6} sm={12} style={{ padding: "15px", paddingTop: "50px" }}>
            <span>Amount </span>
            <Form.Input
              type="text"
              value={`${amount} USD`}
              readOnly
              style={{ width: "30%" }}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={6} sm={12} style={{ padding: "15px", paddingTop: "20px" }}>
            <span>Card Number </span>
            <br />
            <input
              type="text"
              // pattern="[0-9]*"
              value={cardNumber}
              onChange={(e) => {
                if (e.target.value.length > 22) {
                } else {
                  setCardNumber(
                    e.target.value
                      .replace(/\D/g, "")
                      .replace(/\s?/g, "")
                      .replace(/(\d{4})/g, "$1  ")
                      .trim()
                  );
                }
              }}
              onBlur={formik.handleBlur}
              style={{
                width: "70%",
                height: "35px",
                borderRadius: "10px",
                padding: "10px",
              }}
              maxLength="22"
              id="cardNumber"
              name="cardNumber"
              className={
                errors.cardNumber && touched.cardNumber
                  ? "text-input-error"
                  : "text-input"
              }
            />
            {errors.cardNumber && touched.cardNumber && (
              <div style={{ color: "red" }}>{errors.cardNumber}</div>
            )}
          </Col>
        </Row>
        <Row>
          <Col lg={4} sm={6} style={{ padding: "15px", paddingTop: "20px" }}>
            <span>CVV</span>
            <br />
            <input
              type="text"
              value={cvv}
              onChange={(e) => {
                if (e.target.value.length > 3) {
                } else {
                  setCvv(e.target.value.replace(/\D/g, ""));
                }
              }}
              onBlur={formik.handleBlur}
              id="cvv"
              maxLength="3"
              style={{
                width: "70%",
                height: "35px",
                borderRadius: "10px",
                padding: "10px",
                width: "40%",
              }}
              name="cvv"
              className={
                errors.cvv && touched.cvv ? "text-input-error" : "text-input"
              }
            />
            {errors.cvv && touched.cvv && (
              <div style={{ color: "red", fontSize: "12px" }}>{errors.cvv}</div>
            )}
          </Col>
        </Row>
        <Row>
          <Col
            lg={2}
            sm={6}
            style={{
              padding: "15px",
              paddingTop: "20px",
              flexDirection: "column",
            }}
          >
            <span>Expiry Date</span>
            <MySelect
              id="expiryMonth"
              options={options}
              value={values.expiryMonth}
              onChange={props.formik.setFieldValue}
              onBlur={props.formik.setFieldTouched}
              error={errors.expiryMonth}
              touched={touched.expiryMonth}
            />
          </Col>

          <Col lg={3} sm={6} style={{ padding: "15px", paddingTop: "45px" }}>
            <MySelect
              id="expiryYear"
              options={yearList}
              value={values.expiryYear}
              onChange={props.formik.setFieldValue}
              onBlur={props.formik.setFieldTouched}
              error={errors.expiryYear}
              touched={touched.expiryYear}
            />
            {/* {errors.expiryYear && touched.expiryYear && (
              <div style={{ color: "red", fontSize: "12px" }}>
                {errors.expiryYear}
              </div>
            )} */}
          </Col>
        </Row>
        <Row>
          <Col lg={6} sm={12} style={{ padding: "15px", paddingTop: "50px" }}>
            <span>Name on the Card </span>
            <Form.Input
              type="text"
              value={values.cardHolderName}
              onChange={props.formik.handleChange}
              onBlur={props.formik.handleBlur}
              style={{ width: "70%" }}
              id="cardHolderName"
              name="cardHolderName"
              className={
                errors.cardHolderName && touched.cardHolderName
                  ? "text-input-error"
                  : "text-input"
              }
            />
            {errors.cardHolderName && touched.cardHolderName && (
              <div style={{ color: "red" }}>{errors.cardHolderName}</div>
            )}
          </Col>
          <Col lg={6} sm={12} style={{ padding: "15px", paddingTop: "70px" }}>
            {pass ? (
              <ProgressBar
                height="80"
                width="80"
                ariaLabel="progress-bar-loading"
                wrapperStyle={{}}
                wrapperClass="progress-bar-wrapper"
                borderColor="#F4442E"
                barColor="#51E5FF"
              />
            ) : (
              <Button
                id="login-button"
                // disabled={!(props.formik.isValid && props.formik.dirty)}
                onClick={(e) => handlePayment(e)}
              >
                Next
              </Button>
            )}
          </Col>
        </Row>
        <ToastContainer />
      </Container>
    </>
  );
};

export default StripeJDB;
