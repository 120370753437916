export const USER_EMAIL = "USER_EMAIL";
export const ACCESS_TOKEN = "ACCESS_TOKEN";
export const FIRST_NAME = "FIRST_NAME";
export const LAST_NAME = "LAST_NAME";
export const FULL_NAME = "FULL_NAME";
export const PHOTO_URL = "PHOTO_URL";
export const ID = "ID";
export const KYC = "KYC";
export const DOB = "DOB";
export const CALLING_CODE = "CALLING_CODE";
export const MOBILE = "MOBILE";
export const UINFIN = "UINFIN";
export const GENDER = "GENDER";
export const USER_TYPE = "USER_TYPE";
export const IS_KYC_APPROVED = "IS_KYC_APPROVED";
export const CARD_PATH = "CARD_PATH";
export const CARD_NO = "CARD_NO";
export const CARD_ACTIVATED = "CARD_ACTIVATED";
export const RESET = "RESET";
export const FIRST_LOGIN = "FIRST_LOGIN";
export const ADDRESS_ONE = "ADDRESS_ONE";
export const ADDRESS_TWO = "ADDRESS_TWO";
export const POSTAL_CODE = "POSTAL_CODE";
export const COUNTRY = "COUNTRY";
export const NATIONALITY = "NATIONALITY";

export const addEmail = (email) => (dispatch) => {
  return dispatch({
    type: USER_EMAIL,
    payload: email,
  });
};
export const addAccessToken = (token) => (dispatch) => {
  return dispatch({
    type: ACCESS_TOKEN,
    payload: token,
  });
};

export const addFirstName = (firstName) => (dispatch) => {
  return dispatch({
    type: FIRST_NAME,
    payload: firstName,
  });
};

export const addLastName = (lastName) => (dispatch) => {
  return dispatch({
    type: LAST_NAME,
    payload: lastName,
  });
};

export const addFullName = (fullName) => (dispatch) => {
  return dispatch({
    type: FULL_NAME,
    payload: fullName,
  });
};
export const addPhotoURL = (photoURL) => (dispatch) => {
  return dispatch({
    type: PHOTO_URL,
    payload: photoURL,
  });
};
export const addId = (id) => (dispatch) => {
  return dispatch({
    type: ID,
    payload: id,
  });
};
export const addKYC = (kyc) => (dispatch) => {
  return dispatch({
    type: KYC,
    payload: kyc,
  });
};
export const addDOB = (dob) => (dispatch) => {
  return dispatch({
    type: DOB,
    payload: dob,
  });
};
export const addCallingCode = (callingCode) => (dispatch) => {
  return dispatch({
    type: CALLING_CODE,
    payload: callingCode,
  });
};
export const addGender = (gender) => (dispatch) => {
  return dispatch({
    type: GENDER,
    payload: gender,
  });
};
export const addMobile = (mobile) => (dispatch) => {
  return dispatch({
    type: MOBILE,
    payload: mobile,
  });
};
export const addUinfin = (uinfin) => (dispatch) => {
  return dispatch({
    type: UINFIN,
    payload: uinfin,
  });
};
export const addUserType = (userType) => (dispatch) => {
  return dispatch({
    type: USER_TYPE,
    payload: userType,
  });
};
export const addIsKycApproved = (is_kyc_approved) => (dispatch) => {
  return dispatch({
    type: IS_KYC_APPROVED,
    payload: is_kyc_approved,
  });
};
export const addCardPath = (card_path) => (dispatch) => {
  return dispatch({
    type: CARD_PATH,
    payload: card_path,
  });
};
export const addCardNo = (card_no) => (dispatch) => {
  return dispatch({
    type: CARD_NO,
    payload: card_no,
  });
};
export const addCardActivated = (card_activated) => (dispatch) => {
  return dispatch({
    type: CARD_ACTIVATED,
    payload: card_activated,
  });
};
export const reset = () => (dispatch) => {
  dispatch({
    type: RESET,
  });
};
export const addAddress1 = (address1) => (dispatch) => {
  return dispatch({
    type: ADDRESS_ONE,
    payload: address1,
  });
};

export const addAddress2 = (address2) => (dispatch) => {
  return dispatch({
    type: ADDRESS_TWO,
    payload: address2,
  });
};
export const addPostalCode = (postalCode) => (dispatch) => {
  return dispatch({
    type: POSTAL_CODE,
    payload: postalCode,
  });
};
export const addCountry = (country) => (dispatch) => {
  return dispatch({
    type: COUNTRY,
    payload: country,
  });
};
export const setFirstLogin = (firstLogin) => (dispatch) => {
  return dispatch({
    type: FIRST_LOGIN,
    payload: firstLogin,
  });
};
export const addNationality = (nationality) => (dispatch) => {
  return dispatch({
    type: NATIONALITY,
    payload: nationality,
  });
};