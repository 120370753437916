import React, { useEffect, useState } from "react";
import { Card, Container, Image } from "react-bootstrap";
import { Row, Col, Button } from "bootstrap-4-react";
import { BiImageAdd } from "react-icons/bi";
import "./profile.css";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { HiOutlineMail } from "react-icons/hi";
import { FiPhone, FiEyeOff } from "react-icons/fi";
import { BsWallet } from "react-icons/bs";
import UpdateEmailUpdate from "../update/email/updateEmailProcess";
import UpdateMobileProcess from "../update/mobile/updateMobileProcess";
import UpdatePasswordProcess from "../update/password/updatePasswordProcess";
import UpdateWalletPinProcess from "../update/walletPin/updateWalletPinProcess";
import Header from "../../components/header";
import axios from "axios";
import API_PATHS from "../../constants/apiPaths";
// import { MdSentimentDissatisfied } from "react-icons/md";
import { useSelector, useDispatch } from "react-redux";
import { addPhotoURL } from "../../redux/actions";
import { getCookie } from "../../Utils/cookieHandling";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
const Profile = () => {
  const dispatch = useDispatch();
  const { email, id, fullName, photoURL, userType } = useSelector((state) => state);
  const [sso, setSso] = useState(false);
  const [singpass, SetSingPass] = useState(false);

  useEffect(() => {
    if ((userType === "ssoUser") || (userType === "SSO_USER")) {
      setSso(true);
    }
    else {
      setSso(false);
    }
    if (userType === "SINGPASS") {
      SetSingPass(true);
    } else {
      SetSingPass(false);
    }
  }, [userType]);
  const CustomTabs = ({ children, otherProps }) => {
    <Tab {...otherProps}>{children}</Tab>;
  };

  CustomTabs.tabsRole = "Tab";
  const pic =
    "https://previews.123rf.com/images/apoev/apoev1904/apoev190400012/124108711-person-gray-photo-placeholder-woman-in-costume-on-white-background.jpg?fj=1";
  const [profilePic, setProfilePic] = useState(photoURL);

  useEffect(() => {
    setProfilePic(photoURL);
  });

  const imageHandler = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        const obj = {
          id: id,
          profile_pic: reader.result,
        };
        axios
          .post(API_PATHS.UPDATE_PROFILE_PIC, obj, {
            headers: {
              accepts: "application/json",
              Authorization: `${getCookie("c4")} ${getCookie("c3")}`,
            },
          })
          .then((res) => {
            setProfilePic(reader.result);
            dispatch(addPhotoURL(reader.result));
          });
      }
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  const reset = () => {
    const obj = {
      id: id,
      profile_pic: "",
    };
    axios
      .post(API_PATHS.UPDATE_PROFILE_PIC, obj, {
        headers: {
          accepts: "application/json",
          Authorization: `${getCookie("c4")} ${getCookie("c3")}`,
        },
      })
      .then((res) => {
        setProfilePic(pic);
        dispatch(addPhotoURL(pic));
      });
  };


  return (
    <>
      <ToastContainer />
      <Header title="Profile" />
      {/* <Container fluid style={{ paddingTop: ".3125rem" }}> */}
      <div className="page">
        <div className="container">
          <br />
          <div className="row">
            <Col lg={3} sm={3}>
              <div style={{ marginBottom: "1.15rem" }}>
                <div className="img-holder">
                  <Image
                    src={profilePic}
                    id="img"
                    className="img"
                    width={145}
                    height={145}
                    style={{ borderRadius: "4.6875rem" }}
                  />
                </div>
              </div>
            </Col>

            <div>
              <Col style={{ marginBottom: "1.25rem" }}>
                <input
                  disabled={sso}
                  type="file"
                  name="image-upload"
                  id="input"
                  accept="image/*"
                  onChange={(e) => imageHandler(e)}
                />
                <h2 style={{ marginTop: "1.875rem" }}>{fullName}</h2>
                <div className="label">
                  <label htmlFor="input" className="image-upload">
                    <BiImageAdd size={20} color="#fff" />
                    <span
                      style={{
                        fontStyle: "normal",
                        fontWeight: "500",
                        fontSize: "1rem",
                        lineHeight: "1.5rem",
                        textTransform: "capitalize",
                        paddingTop: "2.1875rem",
                        color: "#FFFFFF",
                        cursor: "pointer",
                        paddingLeft: ".3125rem",
                        opacity: sso ? 0.7 : 1,
                      }}
                    >
                      Change
                    </span>
                  </label>
                  <button
                    disabled={sso}
                    style={{
                      width: "6.25rem",
                      height: "2.8125rem",
                      margin: ".375rem",
                      border: ".0625rem solid black",
                      borderRadius: ".3125rem",
                      opacity: sso ? 0.7 : 1,
                    }}
                    className="remove-button"
                    onClick={() => reset()}
                  >
                    Remove
                  </button>
                </div>
              </Col>
            </div>
            <br />
            <br />
            <br />
            <br />
            <br />
            <Tabs
              className="tabs"
              forceRenderTabPanel
              defaultIndex={0}
              style={{
                width: "100%",
                marginTop: "2rem",
                justifyContent: "space-between",
              }}
              defaultFocus
            >
              <TabList className="tab-on">
                <Tab
                  // disabled={singpass}
                  style={{
                    width: "25%",
                    justifyContent: "center",
                    textAlign: "center",
                    border: "none",
                    fontSize: "1.375rem",
                  }}
                >
                  <FiPhone size={30} style={{ marginRight: ".3125rem" }} />
                  Mobile
                </Tab>
                <Tab
                  // disabled={sso || singpass}
                  style={{
                    width: "25%",
                    justifyContent: "center",
                    textAlign: "center",
                    border: "none",
                    fontSize: "1.375rem",
                  }}
                >
                  <HiOutlineMail
                    size={30}
                    style={{ marginRight: ".3125rem" }}
                  />
                  Email
                </Tab>

                <Tab
                  // disabled={sso || singpass}
                  style={{
                    width: "25%",
                    justifyContent: "center",
                    textAlign: "center",
                    border: "none",
                    fontSize: "1.375rem",
                  }}
                >
                  <FiEyeOff size={30} style={{ marginRight: "8px" }} /> Password
                </Tab>
                <Tab
                  disabled={true}
                  style={{
                    width: "25%",
                    justifyContent: "center",
                    textAlign: "center",
                    border: "none",
                    fontSize: "1.375rem",
                  }}
                >
                  <BsWallet size={30} style={{ marginRight: "8px" }} />
                  Wallet Pin
                </Tab>
              </TabList>

              <TabPanel>
                <UpdateMobileProcess />
              </TabPanel>
              <TabPanel>
                <UpdateEmailUpdate />
              </TabPanel>
              <TabPanel>
                <UpdatePasswordProcess />
              </TabPanel>
              <TabPanel>
                <UpdateWalletPinProcess />
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </div>
      {/* <Tabs
        style={{marginTop:"-21.875rem",width:"50rem"}}
        defaultActiveKey="profile"
        id="fill-tab-example"
        className="mb-3"
        fill
        justify
      >
        <Tab eventKey="home" title="Home" style={{}}>
          Email
        </Tab>
        <Tab eventKey="profile" title="Profile">
          Mobile
        </Tab>
        <Tab eventKey="longer-tab" title="Loooonger">
          Password
        </Tab>
        <Tab eventKey="contact" title="Contact">
          Wallet Pin
        </Tab>
      </Tabs> */}
      {/* </Container> */}
    </>
  );
};

export default Profile;
