import React, { useState, useEffect, useMemo } from "react";
import { Card, Container, Row, Col, Toast } from "react-bootstrap";
import { Form, Button } from "bootstrap-4-react";
import "react-phone-number-input/style.css";
import { getCountryCallingCode } from "react-phone-number-input/input";
import Select from "react-select";
import countryList from "react-select-country-list";
import "./kyc.css";
import { FileUploader } from "react-drag-drop-files";
import axios from "axios";
import API_PATHS from "../../constants/apiPaths";
import { useNavigate, useLocation } from "react-router-dom";
import Header from "../../components/header";
import ReactSelect from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Kycfields } from "../../data/kycfields";
import { useDispatch, useSelector } from "react-redux";
import { addKYC, addFirstName, addFullName, addEmail, addLastName } from "../../redux/actions";
import { ProgressBar } from "react-loader-spinner";
import { getCookie, setCookie } from "../../Utils/cookieHandling";
import PhoneInput from "react-phone-input-2";
import Modal from "react-bootstrap/Modal";
import MySelect from "../../components/mySelect";
import { clearEverything } from "../../Utils/clearAll";
// import PhoneInput ,{isPossiblePhoneNumber} from "react-phone-number-input";
toast.configure();
const Kyc = (formik) => {
  const {
    userType,
    email,
    mobile,
    callingCode,
    accessToken,
    firstName,
    lastName,
    uinfin
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const location = useLocation();
  const { values, errors, touched } = formik;
  const options = useMemo(() => countryList().getData(), []);
  const navigate = useNavigate();
  const [selfie, setSelfie] = useState(null);
  // const [getGender, setGetGender] = useState({ value: "male", label: " Male" });
  const [photoIdFront, setPhotoIdFront] = useState(null);
  const [photoIdBack, setPhotoIdBack] = useState(null);
  const [addressProof, setAddressProof] = useState(null);
  const [phone, setPhone] = useState(values.phone);
  const [country, setCountry] = useState({ value: "SG", label: "Singapore" });
  const [countryFlag, setCountryFlag] = useState("SG");
  const fileTypes = ["JPG", "PNG", "GIF"];
  const [errorMsg, setErrorMsg] = useState([]);
  const [click, setClick] = useState(false);
  const [pass, setPass] = useState(false);
  const [error, setError] = useState([]);
  const [otpSent, setOtpSent] = useState(false);
  const [alreadyExist, setAlreadyExist] = useState(false);
  const [kycAlreadyExist, setKYCAlreadyExist] = useState(false);
  const [verifyClicked, setVerifyClicked] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const gender = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
  ];
  useEffect(() => {
    if (callingCode && mobile && phone === "+65") {
      setPhone("+" + callingCode + mobile);
    }
  });

  useEffect(() => {
    if (
      userType === "SSO_USER" &&
      values.firstName === "" &&
      firstName !== ""
    ) {
      values.firstName = firstName;
      values.lastName = lastName;
    }
  });

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  // const string = btoa(localStorage.getItem("url"));

  useEffect(() => { }, []);

  useEffect(() => {
    values.selfie = selfie;
    values.photoIdFront = photoIdFront;
    values.photoIdBack = photoIdBack;
    values.addressProof = addressProof;
    values.phone = phone;
    values.country = country;
    const ccode = getCountryCallingCode(country.value);
  }, [selfie, photoIdBack, photoIdFront, addressProof, phone, country]);

  // const check = () => {
  //   const checkfield =
  //     selfie.fileName === "Choose File" ||
  //     photoIdBack.fileName === "Choose File" ||
  //     photoIdFront.fileName === "Choose File" ||
  //     addressProof.fileName === "Choose File"
  //       ? true
  //       : false;
  //   return checkfield;
  // };

  const onRegister = async () => {
    if (errorMsg.length != 0) {
      return false;
    }
    if (values.dob) {
      var today = new Date();
      var birthDate = new Date(values.dob);
      var age = today.getFullYear() - birthDate.getFullYear();
      if (age < 18) {
        toast.error("You should be 18YEARS or older");
        setPass(false);
        return;
      }
    }
    if (pass === false) {
      return false;
    }
    const ccode = getCountryCallingCode(values.country.value);
    const biopic = await convertBase64(selfie);
    const front_id = await convertBase64(photoIdFront);
    const back_id = await convertBase64(photoIdBack);
    const address = await convertBase64(addressProof);

    // localStorage.setItem("name", values.firstName);

    const obj = {
      first_name: values.firstName,
      last_name: values.lastName,
      dob: values.dob,
      email: values.email,
      address1: values.address1,
      address2: values.address2,
      city: values.city,
      state: values.state,
      pincode: values.postalCode,
      passport_file_signature_biopic: biopic,
      contactNumber: Number(String(values.phone).substring(3, 13)),
      countryCode: Number(String(values.phone).substring(0, 3)),
      countryName: values.country.label,
      countryFlag: countryFlag,
      d_country: values.country.value,
      kyc: true,
      photo_id_front: front_id,
      photo_id_back: back_id,
      photo_address: address,
      gender: values.gender,
      nationality: values.nationality,
      uinfin: values.uinfin,
    };

    axios
      .post(API_PATHS.KYC_REGISTER, obj, {
        headers: {
          accepts: "application/json",
          Authorization: `${getCookie("c4")} ${getCookie("c3")}`,
        },
      })
      .then(async (res) => {
        toast(res.data.message);
        dispatch(addKYC(true));
        dispatch(addFullName(`${values?.firstName} ${values?.lastName}`));
        dispatch(addFirstName(values?.firstName));
        dispatch(addLastName(values?.lastName));
        dispatch(addEmail(values?.email));
        await localStorage.clear()
        setCookie("c1", values.email)
        setCookie("c6", "")
        navigate("/logged/kycHold");
      })
      .catch((error) => console.log("Error", error));
  };

  useEffect(() => {
    if (click == true) {
      setErrorMsg([]);
      Kycfields.map((entry) =>
        values[entry.value]
          ? null
          : setErrorMsg((oldArray) => [
            ...oldArray,
            `Please select the ${entry.label}`,
          ])
      );
    }
  }, [errorMsg, click]);

  useEffect(() => {
    setError([]);
    Kycfields.map((entry) =>
      values[entry.value]
        ? null
        : setError((oldArray) => [
          ...oldArray,
          `Please select the ${entry.label}`,
        ])
    );
  });

  useEffect(() => {
    if (pass === true) {
      if (uinfin && !email) {
        setShow1(true)
      }
      else {
        onRegister();
      }
    }
  }, [pass]);
  function start_and_end(str) {
    if (str.length > 35) {
      return (
        str.substr(0, 15) + "..." + str.substr(str.length - 10, str.length)
      );
    }
    return str;
  }
  function checkSize(str) {
    if (Number(str) > 1048575) {
      return `${Math.round(str / 1024) / 1000} MB`;
    }
    if (Number(str) < 1048576) {
      return `${Math.round(str / 1024)} KB`;
    }
  }
  useEffect(() => {
    if (!values.email && !values.uinfin) {
      const loadedState = loadStateFromLocalStorage();
      if (loadedState) {
        values.firstName = loadedState?.firstName;
        values.lastName = loadedState?.lastName;
        values.dob = loadedState?.dob;
        const gender = loadedState?.gender?.toLowerCase();
        values.gender = gender;
        // setGetGender({ value: gender, label: gender?.charAt(0)?.toUpperCase() + gender?.slice(1) })
        values.phone = `+${loadedState?.mobileCode}${loadedState?.mobile}`;
        setPhone(`+${loadedState?.mobileCode}${loadedState?.mobile}`);
        values.address1 = loadedState?.address1;
        values.address2 = loadedState?.address2;
        values.nationality = loadedState?.nationality;
        values.postalCode = loadedState?.postalCode;
        values.country = loadedState?.country;
        values.uinfin = loadedState?.uinfin;
      }
    }
  }, []);

  useEffect(() => {
    if (values.uinfin && !values.email && !email) {
      setShow2(true)
    }
  }, [email])

  const loadStateFromLocalStorage = () => {
    try {
      const serializedState = localStorage.getItem('singpassUserState');
      if (serializedState === null) {
        // If there is no saved state, return undefined
        return undefined;
      }
      return JSON.parse(serializedState);
    } catch (error) {
      console.error('Error loading state from localStorage:', error);
      return undefined;
    }
  };

  const sendOTP = (e) => {
    setVerifyClicked(false)
    setOtpSent(true);
    e.preventDefault();
    const obj = {
      email: values.email,
      singpass: "true"
    };
    axios
      .post(API_PATHS.OTP, obj, {
        headers: {
          accepts: "application/json",
        },
      })
      .then((res) => {
        setOtpSent(false);
        if (res?.data?.message == "User already registered.") {
          setAlreadyExist(true)
          setKYCAlreadyExist(res?.data?.kyc)
        }
        else {
          setAlreadyExist(false)
          setKYCAlreadyExist(false)
        }
        if (res?.data?.message) {
          toast(res.data.message);
        } else {
          toast("Something went wrong");
        }
        setOtpSent(false);
      })
      .catch((err) => console.log(err));
  };
  const verifyEmail = () => {
    let data = JSON.stringify({
      email: values.email,
      OTP: Number(values.otp),
      uinfin: values.uinfin
    });
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: API_PATHS.VERIFY_MAIL,
      headers: {
        'accept': '*/*',
        'Content-Type': 'application/json'
      },
      data: data
    };
    setVerifyClicked(true)
    axios.request(config)
      .then((response) => {
        if (response?.data?.message) {
          toast(response?.data?.message)
        }
        if (response?.data?.status == 200 && response?.data?.email_verified_at && response?.data?.email) {
          if (!alreadyExist && !kycAlreadyExist) {
            registerSingpassUser()
          }
          if (alreadyExist && !kycAlreadyExist) {
            onRegister()
          }
          else if (alreadyExist && kycAlreadyExist) {
            // setVerifyClicked(false)
            setShow2(false)
            setShow1(false)
            setShow3(true)
          }
        }
      })
      .catch((error) => {
        setVerifyClicked(false)
        console.log(error);
      });

  }

  const registerSingpassUser = async () => {
    if (values.email) {
      const object = {
        email: values.email,
        user_type: "SINGPASS",
        first_name: values.firstName,
        last_name: values.lastName,
        dob: values.dob,
        contactNumber: Number(String(values.phone).substring(3, 13)),
        countryCode: Number(String(values.phone).substring(0, 3)),
        uinfin: values.uinfin,
        gender: values.gender,
        mobileCode: values.mobileCode,
        mobile: values.mobile,
        address1: values.address1,
        address2: values.address2,
        pincode: values.postalCode,
        country: values.country.value,
        d_country: values.d_country,
        nationality: values.nationality
      };
      axios
        .post(API_PATHS.SS0_REGISTER, object, {
          headers: {
            Authorization: `${getCookie("c4")} ${getCookie("c3")}`,
          },
        })
        .then(async (response) => {
          setVerifyClicked(false)
          if (response?.data?.message) {
            toast(response.data.message);
          }
          if (response.data.message === "User registrated successfully") {
            setCookie('c1', values.email)
            onRegister()
          }
        }).catch((err) => {
          setVerifyClicked(false)
          if (err?.response?.data?.message) {
            alert(JSON.stringify(err?.response?.data?.message))
          }
        })

    }
  }
  const handleLogout = () => {
    setCookie("c1", "")
    setCookie("c6", "")
    clearEverything()
    navigate("/login")
  }
  return (
    <>
      <Header title="KYC Registration" />
      <br />

      <Container fluid style={{ padding: "20px" }}>
        <Row justifyContent="md-center">
          <Col lg={6} sm={12} style={{ padding: "15px" }}>
            <span> First Name</span>
            <Form.Input
              type="text"
              value={values.firstName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              id="firstName"
              name="firstName"
              className={
                errors.firstName && touched.firstName
                  ? "text-input-error"
                  : "text-input"
              }
            />
            {errors.firstName && touched.firstName && (
              <div style={{ color: "red" }}>{errors.firstName}</div>
            )}
          </Col>
          <Col lg={6} sm={12} style={{ padding: "15px" }}>
            <span>Last Name</span>
            <Form.Input
              type="text"
              value={values.lastName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              id="lastName"
              name="lastName"
              className={
                errors.lastName && touched.lastName
                  ? "text-input-error"
                  : "text-input"
              }
            />
            {errors.lastName && touched.lastName && (
              <div style={{ color: "red" }}>{errors.lastName}</div>
            )}
          </Col>
        </Row>
        <Row justifyContent="md-center">
          <Col lg={6} sm={12} style={{ padding: "15px" }}>
            <span>Date of Birth</span>
            <Form.Input
              type="date"
              label="dd/mm/yyyy"
              placeholder="dd/mm/yyyy"
              dateFormat="dd/mm/yyyy"
              locale="en"
              value={values.dob}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              id="dob"
              name="dob"
              className={
                errors.dob && touched.dob ? "text-input-error" : "text-input"
              }
            />
            {errors.dob && touched.dob && (
              <div style={{ color: "red" }}>{errors.dob}</div>
            )}
          </Col>
          <Col lg={6} sm={12} style={{ padding: "15px" }}>
            <span>Mobile Number</span>
            {/* <PhoneInput
              // international 
              country={"sg"}
              value={phone}
              // error={phone ? (isPossiblePhoneNumber(phone) ? undefined : 'Invalid phone number') : 'Phone number required'}
              limitMaxLength={10}
              onChange={setPhone}
              onBlur={formik.handleBlur}
              countryCallingCodeEditable={false}
              onCountryChange={(value)=>{setCountryFlag(value) ; console.log("KAJSHD",value)}}
              style={{
                lineHeight: "30px",
                border: "0px",
                borderRadius: "25px",
              }}
              id="phone"
              name="phone"
              className={
                errors.phone && touched.phone
                  ? "text-input-error"
                  : "text-input"
              }
            /> */}
            <PhoneInput
              style={{
                lineHeight: "30px",
                border: "0px",
                borderRadius: "25px",
              }}
              country={"sg"}
              name="contactNumber"
              className={"inputtype form-control-2"}
              // component={TextField}
              // onChange={(e, f) => {

              // 	// console.log("EVENT", f.countryCode);
              // 	setPhone(e)

              // }}
              value={phone}
              onChange={(e, f) => {
                // console.log(e,f)
                setPhone(e);
                setCountryFlag(f.countryCode);
              }}
            />
            {errors.phone && touched.phone && (
              <div style={{ color: "red" }}>{errors.phone}</div>
            )}
            {/* <p>Test {phone} & {countryFlag}</p> */}
          </Col>
        </Row>
        <Row justifyContent="md-center">
          <Col lg={6} sm={12} style={{ padding: "15px" }}>
            <span>Email Address</span>
            <Form.Input
              type="email"
              readonly={email ? true : false}
              value={values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              id="email"
              name="email"
            />
          </Col>
          <Col lg={6} sm={12} style={{ padding: "15px" }}>
            <span>Sex</span>
            <MySelect
              id="gender"
              options={gender}
              value={values.gender}
              onChange={formik.setFieldValue}
              onBlur={formik.setFieldTouched}
              error={errors.gender}
              touched={touched.gender}
            />
            {errors.gender && touched.gender && (
              <div style={{ color: "red" }}>{errors.gender}</div>
            )}
          </Col>
        </Row>
        <Row>
          <Col lg={6} sm={12} style={{ padding: "15px" }}>
            <span>Address 1 :</span>
            <Form.Input
              type="text"
              value={values.address1}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              id="address1"
              name="address1"
              className={
                errors.address1 && touched.address1
                  ? "text-input-error"
                  : "text-input"
              }
            />
            {errors.address1 && touched.address1 && (
              <div style={{ color: "red" }}>{errors.address1}</div>
            )}
          </Col>
          <Col lg={6} sm={12} style={{ padding: "15px" }}>
            <span>Address 2 :</span>
            <Form.Input
              type="text"
              value={values.address2}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              id="address2"
              name="address2"
              className={
                errors.address2 && touched.address2
                  ? "text-input-error"
                  : "text-input"
              }
            />
            {errors.address2 && touched.address2 && (
              <div style={{ color: "red" }}>{errors.address2}</div>
            )}{" "}
          </Col>
        </Row>
        <Row>
          <Col lg={6} sm={12} style={{ padding: "15px" }}>
            <span>City</span>
            <Form.Input
              type="text"
              value={values.city}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              id="city"
              name="city"
              className={
                errors.city && touched.city ? "text-input-error" : "text-input"
              }
            />
            {errors.city && touched.city && (
              <div style={{ color: "red" }}>{errors.city}</div>
            )}{" "}
          </Col>
          <Col lg={6} sm={12} style={{ padding: "15px" }}>
            <span>State</span>
            <Form.Input
              type="text"
              value={values.state}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              id="state"
              name="state"
              className={
                errors.state && touched.state
                  ? "text-input-error"
                  : "text-input"
              }
            />
            {errors.state && touched.state && (
              <div style={{ color: "red" }}>{errors.state}</div>
            )}
          </Col>
        </Row>
        <Row>
          <Col lg={6} sm={12} style={{ padding: "15px" }}>
            <span>Country</span>
            <Select
              options={options}
              value={country}
              onChange={setCountry}
              onBlur={formik.handleBlur}
              id="country"
              name="country"
              className={
                errors.country && touched.country
                  ? "text-input-error"
                  : "text-input"
              }
            />
            {errors.country && touched.country && (
              <div style={{ color: "red" }}>{errors.country}</div>
            )}
          </Col>
          <Col lg={6} sm={12} style={{ padding: "15px" }}>
            Postal Code
            <Form.Input
              type="number"
              value={values.postalCode}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              id="postalCode"
              name="postalCode"
              className={
                errors.postalCode && touched.postalCode
                  ? "text-input-error"
                  : "text-input"
              }
            />
            {errors.postalCode && touched.postalCode && (
              <div style={{ color: "red" }}>{errors.postalCode}</div>
            )}
          </Col>
        </Row>
        <Row>
          <Col lg={6} sm={12} style={{ padding: "15px" }}>
            <span>Nationality</span>
            <Form.Input
              type="text"
              value={values.nationality}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              id="nationality"
              name="nationality"
              className={
                errors.nationality && touched.nationality
                  ? "text-input-error"
                  : "text-input"
              }
            />
            {errors.nationality && touched.nationality && (
              <div style={{ color: "red" }}>{errors.nationality}</div>
            )}{" "}
          </Col>
          {values.uinfin ? (
            <Col lg={6} sm={12} style={{ padding: "15px" }}>
              <span>NRIC Number</span>
              <Form.Input
                type="text"
                readOnly
                value={values.uinfin}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                id="uinfin"
                name="uinfin"
                className={
                  errors.uinfin && touched.uinfin
                    ? "text-input-error"
                    : "text-input"
                }
              />
              {errors.uinfin && touched.uinfin && (
                <div style={{ color: "red" }}>{errors.uinfin}</div>
              )}{" "}
            </Col>
          ) : null}

          {/* <Col lg={6} sm={12} style={{ padding: "15px" }}>
            <span>NRIC/FIN</span>
            <Form.Input
              type="text"
              value={values.nric_fin}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              id="nric_fin"
              name="nric_fin"
              className={
                errors.nric_fin && touched.nric_fin
                  ? "text-input-error"
                  : "text-input"
              }
            />
            {errors.nric_fin && touched.nric_fin && (
              <div style={{ color: "red" }}>{errors.nric_fin}</div>
            )}{" "}
          </Col> */}
        </Row>
        <Row>
          <Col lg={6} sm={12} style={{ padding: "15px" }}>
            <span>Selfie Image with passport biopage</span>
            <FileUploader
              style={{
                maxWidth: "100%",
                minWidth: "100%",
                width: "100%",
                border: "1px solid black",
              }}
              classes="file-upload"
              handleChange={(file) => {
                setSelfie(file);
              }}
              name="file"
              maxSize={2}
              types={fileTypes}
              onSizeError={(file) => setSelfie(null)}
              onTypeError={(file) => setSelfie(null)}
            />
            <div className="document">
              <p className="docName">
                {selfie ? start_and_end(selfie.name) : ""}
              </p>
              <p className="docSize">{selfie ? checkSize(selfie.size) : ""}</p>
            </div>
            <p className="maxSize" style={{ bottom: selfie ? "51px" : "26px" }}>
              Max Size : 2 MB
            </p>
          </Col>
          <Col lg={6} sm={12} style={{ padding: "15px" }}>
            <span>Photo ID Front</span>
            <FileUploader
              style={{ maxWidth: "100%", minWidth: "100%", width: "100%" }}
              classes="file-upload"
              handleChange={(file) => {
                setPhotoIdFront(file);
              }}
              name="file"
              maxSize={2}
              types={fileTypes}
              onSizeError={(file) => setPhotoIdFront(null)}
              onTypeError={(file) => setPhotoIdFront(null)}
            />
            <div className="document">
              <p className="docName">
                {photoIdFront ? start_and_end(photoIdFront.name) : ""}
              </p>
              <p className="docSize">
                {photoIdFront ? checkSize(photoIdFront.size) : ""}
              </p>
            </div>
            <p
              className="maxSize"
              style={{ bottom: photoIdFront ? "51px" : "26px" }}
            >
              Max Size : 2 MB
            </p>
          </Col>
        </Row>
        <Row>
          <Col lg={6} sm={12} style={{ padding: "15px" }}>
            <span>Photo ID Back</span>
            <FileUploader
              style={{ maxWidth: "100%", minWidth: "100%", width: "100%" }}
              classes="file-upload"
              handleChange={(file) => {
                setPhotoIdBack(file);
              }}
              name="file"
              maxSize={2}
              types={fileTypes}
              onSizeError={(file) => setPhotoIdBack(null)}
              onTypeError={(file) => setPhotoIdBack(null)}
            />
            <div className="document">
              <p className="docName">
                {photoIdBack ? start_and_end(photoIdBack.name) : ""}
              </p>
              <p className="docSize">
                {photoIdBack ? checkSize(photoIdBack.size) : ""}
              </p>
            </div>
            <p
              className="maxSize"
              style={{ bottom: photoIdBack ? "51px" : "26px" }}
            >
              Max Size : 2 MB
            </p>
          </Col>
          <Col lg={6} sm={12} style={{ padding: "15px" }}>
            <span>Proof of Address</span>
            <FileUploader
              style={{ maxWidth: "100%", minWidth: "100%", width: "100%" }}
              classes="file-upload"
              handleChange={(file) => {
                setAddressProof(file);
              }}
              name="file"
              maxSize={2}
              types={fileTypes}
              onSizeError={(file) => setAddressProof(null)}
              onTypeError={(file) => setAddressProof(null)}
            />
            <div className="document">
              <p className="docName">
                {addressProof ? start_and_end(addressProof.name) : ""}
              </p>
              <p className="docSize">
                {addressProof ? checkSize(addressProof.size) : ""}
              </p>
            </div>
            <p
              className="maxSize"
              style={{ bottom: addressProof ? "51px" : "26px" }}
            >
              Max Size : 2 MB
            </p>
          </Col>
        </Row>
        <div>
          {errorMsg.map((entry) => (
            <div>
              <span style={{ color: "red", lineHeight: "0.5px" }}>{entry}</span>
            </div>
          ))}
        </div>
        <Row className="text-center">
          <Col style={{ padding: "15px" }}>
            {pass && errorMsg.length === 0 ? (
              <ProgressBar
                height="80"
                width="80"
                ariaLabel="progress-bar-loading"
                wrapperStyle={{}}
                wrapperClass="progress-bar-wrapper"
                borderColor="#F4442E"
                barColor="#51E5FF"
              />
            ) : (
              <Button
                id="register-button"
                //disabled={!(formik.isValid && formik.dirty)}
                onClick={() => {
                  if (error.length === 0) {
                    setPass(true);
                  } else {
                    setClick(true);
                  }
                }}
              >
                Update
              </Button>
            )}
          </Col>
        </Row>
      </Container>

      <Modal show={show2} onHide={() => setShow2(false)}>
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ textAlign: "center" }}>
          Hi{" "}{values.firstName || " "}{" "}{values.lastName || " "}{" "}<p></p>
          <p></p>
          Apologies for the inconvenience, but it seems that we couldn't find the email linked to your SingPass account.
          <p></p>
          Not to worry, you can still proceed without it.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => { handleLogout() }}>
            Logout
          </Button>
          <Button variant="secondary" onClick={() => setShow2(false)}>
            Continue
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show2} onHide={() => setShow2(false)}>
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ textAlign: "center" }}>
          Hi{" "}{values.firstName || " "}{" "}{values.lastName || " "}{" "}<p></p>
          <p></p>
          Apologies for the inconvenience, but it seems that we couldn't find the email linked to your SingPass account.
          <p></p>
          Not to worry, you can still proceed without it.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => { navigate("/login") }}>
            Logout
          </Button>
          <Button variant="secondary" onClick={() => setShow2(false)}>
            Continue
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show2} onHide={() => setShow2(false)}>
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ textAlign: "center" }}>
          Hi{" "}{values.firstName || " "}{" "}{values.lastName || " "}{" "}<p></p>
          <p></p>
          Apologies for the inconvenience, but it seems that we couldn't find the email linked to your SingPass account.
          <p></p>
          Not to worry, you can still proceed without it.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => { navigate("/login") }}>
            Logout
          </Button>
          <Button variant="secondary" onClick={() => setShow2(false)}>
            Continue
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={show1} onHide={() => { setPass(false); setClick(false); setShow1(false) }}>
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ textAlign: "center" }}>
          Hi{" "}{values.firstName || " "}{" "}{values.lastName || " "}{" "}<p></p>
          <p></p>
          Apologies, we couldn't locate the email associated with your SingPass account.
          <p></p>
          Please take a moment to verify your email address.

          <Row
            justifyContent="left"
            style={{ margin: "10px", fontSize: "10px" }}
          >
            <Col style={{ textAlign: "left" }}>
              <span style={{ float: "right", padding: 4 }}>
                {values.email && (
                  <Button id="otp-button" onClick={(e) => sendOTP(e)}>
                    Send OTP
                  </Button>
                )}
              </span>
            </Col>
          </Row>
          <Row
            justifyContent="left"
            style={{ margin: "10px", fontSize: "10px" }}
          >
            <Col style={{ textAlign: "left" }}>
              <span>Email Verification OTP</span>
              <Form.Input
                type="number"
                value={values.otp}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="otp"
                className={
                  errors.otp && touched.otp ? "text-input-error" : "text-input"
                }
              />

              {errors.otp && touched.otp && (
                <div style={{ color: "red" }}>{errors.otp}</div>
              )}
            </Col>
          </Row>
          {alreadyExist && (
            <div style={{ justifyContent: "center", width: "100%" }}>
              <p style={{ textAlign: "center", paddingLeft: 0, marginTop: 10, color: 'red', fontSize: 12 }}>
                Note: This email is already registered in our database {kycAlreadyExist ?
                  <>and <span style={{ fontWeight: 'bold', color: 'black' }}>KYC already completed</span></> :
                  <>but <span style={{ fontWeight: 'bold', color: 'black' }}>KYC not completed</span></>}.
                Please verify this email. Once verified successfully, we will update the NRIC number associated with this email for your future logins.
              </p>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => { setPass(false); setClick(false); setShow1(false) }}>
            Cancel
          </Button>
          <Button variant="secondary" onClick={() => { values.otp ? verifyEmail() : toast('4 digits otp required') }}>
            Verify
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={show3} onHide={() => handleLogout()}>
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ textAlign: "center" }}>
          Hi{" "}{values.firstName || " "}{" "}{values.lastName || " "}{" "}<p></p>
          <p></p>
          Your existing account [ {values.email} ] linked with NRIC number: [ {values.uinfin} ].
          <p></p>
          Please log in again to continue.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => { handleLogout() }}>
            Log IN
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer />
    </>
  );
};

export default Kyc;
