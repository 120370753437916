import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import Kyc from "./kyc";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

const KycProcess = () => {
  const location = useLocation()
  const {
    email,
    photoURL,
    fullName,
    firstName,
    lastName,
    dob,
    gender,
    callingCode,
    mobile,
    uinfin,
    userType,
    kyc,
    address1,
    address2,
    postalCode,
    country,
    nationality
  } = useSelector((state) => state);
  const navigate = useNavigate();

  useEffect(() => {
    if (kyc) {
      navigate("/logged/kycHold");
    }
  });

  const initialValues = {
    firstName: firstName,
    lastName: lastName,
    dob: dob ? dob : "",
    gender: gender ? gender.toLowerCase() : "male",
    phone: "+65",
    address1: address1 ? address1 : "",
    address2: address2 ? address2 : "",
    email: email,
    nationality: nationality,
    city: "",
    state: "",
    postalCode: postalCode ? postalCode : "",
    country: country,
    selfie: " ",
    photoIdFront: "",
    photoIdBack: "",
    addressProof: "",
    uinfin: uinfin,
    props: location?.state,
    otp: ""
  };

  const validationSchema = Yup.object({
    firstName: Yup.string()
      .matches(/^[A-Za-z ]*$/, "Please enter valid First name")
      .required("First Name cannot be blank"),
    lastName: Yup.string()
      .matches(/^[A-Za-z ]*$/, "Please enter valid Last name")
      .required("Last Name cannot be blank"),
    nationality: Yup.string().required("Nationality cannot be blank"),
    gender: Yup.string().required("Please Enter the gender"),
    phone: Yup.string().nullable().required("Mobile cannot be blank"),
    dob: Yup.date().required("Date not selected"),
    address1: Yup.string().required("Please enter the correct address"),
    postalCode: Yup.number()
      .min(5, "Minimum 5 characters as a pin code")
      .required("Postal Code is required"),
    address2: Yup.string().required("Please enter the correct address"),
    city: Yup.string().required("Please enter the city"),
    state: Yup.string().required("Please enter the state"),
    country: Yup.string().ensure().required("Please select the country"),
    selfie: Yup.mixed().required("File is required"),
    photoIdFront: Yup.mixed().required("File is required"),
    photoIdBack: Yup.mixed().required("File is required"),
    addressProof: Yup.mixed().required("File is required"),
    otp: Yup.number()
      .typeError("you must specify a number")
      .test(
        "len",
        "Must be exactly 4 digits",
        (val) => val && val.toString().length === 4
      )
      .required("Enter the verification code"),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {(formik) => <Kyc {...formik} />}
    </Formik>
  );
};

export default KycProcess;
