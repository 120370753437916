import React, { useState } from "react";
import Header from "../../components/header";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import { url } from "../../constants/url";
import { getCookie } from "../../Utils/cookieHandling";
const Pending = () => {
  const { email } = useSelector((state) => state);
  const [payment, setPayment] = useState(true);
  const navigate = useNavigate();

  // const handlePayment = () => {
  //   setPayment(false);
  //   const obj = {
  //     email: email,
  //   };
  //
  // };
  const handlePayment = () => {
    axios
      .get(`${url}/get_userdetails/${email}`, {
        headers: {
          accepts: "application/json",
          Authorization: `${getCookie("c4")} ${getCookie("c3")}`,
        },
      })
      .then((res) => {
        const obj = {
          email: email,
          card_type: res.data.card_type,
        };
        setPayment(false);
        navigate("/logged/card/pendingPayment", {
          state: { object: obj },
        });
      });
  };

  return (
    <>
      <Header title="JDB Program" />
      <Modal show={payment}>
        <Modal.Header>
          <Modal.Title>Payment Required</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          We have applied the JDB card for you, please proceed to the payment
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handlePayment}>
            Proceed to Payment
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Pending;
