import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Container, Col, Row, Image } from "react-bootstrap";
import { Form, Button } from "bootstrap-4-react";
import QRCode from "react-qr-code";
import "./gAuth/google.css";
import API_PATHS from "../../constants/apiPaths";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CopyToClipboard } from "react-copy-to-clipboard";
import "react-tippy/dist/tippy.css";
// import { Tooltip } from "react-tippy";
import { getCookie } from "../../Utils/cookieHandling";
import { useDispatch } from "react-redux";
import { addEmail, addFullName } from "../../redux/actions";
import { setCookie } from "../../Utils/cookieHandling";

const GoogleAuthenticator = (formik) => {
  const dispatch = useDispatch();

  const email = getCookie("c1");
  const navigate = useNavigate();
  const { values, errors, touched } = formik;
  const [string, setString] = useState(" ");
  const [ascii, setAscii] = useState();
  const [key, setKey] = useState();
  const [isLoaded, setIsLoaded] = useState(false);
  const [copyText, setCopyText] = useState(false);
  const token = getCookie("c3");

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  useEffect(() => {
    if (isLoaded) {
      const obj = {
        email: email,
      };
      axios.post(API_PATHS.SECRET_KEY_GOOGLE, obj).then((res) => {
        setCopyText(false);
        const { qrcodes } = res.data;
        const { ascii } = res.data.secret;
        setKey(res.data.secret.base32);
        setString(qrcodes);
        setAscii(ascii);
      });
    }
  }, [isLoaded]);

  const validateUser = () => {
    if (values.email && values.otp.length === 6) {
      const obj = {
        asci: ascii,
        otp: Number(values.otp),
      };
      axios
        .post(API_PATHS.VERIFY_GOOGLE_OTP, obj, {
          headers: {
            Authorization: `${values.type} ${token}`,
          },
        })
        .then((res) => {
          if (res.data === true) {
            const asci = {
              ascii_value: ascii,
            };
            axios
              .put(API_PATHS.UPDATE_ASCII + values.email, asci, {
                headers: {
                  accepts: "application/json",
                    Authorization: `${values.type} ${token}`,
                },
              })
              .then((res) => {
                axios
                  .get(`${API_PATHS.GET_DETAILS}${values.email}`, {
                    headers: {
                      accepts: "application/json",
                      Authorization: `${values.type} ${token}`,
                    },
                  })
                  .then((res2) => {
                    dispatch(addEmail(values.email));
                    setCookie("c1", values.email);
                    setCookie(
                      "c2",
                      res2.data.first_name
                        ? res2.data.first_name + " " + res2.data.last_name
                        : "new user"
                    );
                    // setCookie("c3", token);
                    // setCookie("c4", "Bearer");
                    dispatch(addFullName("user"));
                    toast("User Validated");
                    res2.data.payment_status === "pending"
                      ? navigate("/logged/card/pending")
                      : res2.data.card_activated === 1 ||
                        res2.data.card_activated === 2
                      ? navigate("/logged/dashboard")
                      : res2.data.jdbCardNumber1
                      ? navigate("/logged/card/cardVerify")
                      : res2.data.payment_status === "paid"
                      ? navigate("/logged/card/cardStatus")
                      : res2.data.kyc
                      ? navigate("/logged/kycHold")
                      : navigate("/logged/kyc");
                  });
              });
          } else {
            toast("Invalid OTP");
          }
        });
    } else {
      toast("Please try again");
    }
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col lg={6} sm={12}>
            <Image
              src={require("../../assets/paymentPlanet/forgot(2).jpeg")}
              width="100%"
            />
          </Col>
          <Col
            sm={12}
            lg={6}
            style={{
              marginTop: "30px",
              textAlign: "center",
              justifyContent: "center",
            }}
          >
            <h2
              style={{
                fontSize: "3rem",
                fontWeight: "700",
              }}
              className="capitalized"
            >
              2FA Authentication
            </h2>
            <span className="capitalized">
              Please scan the below QRCode for verification from the Google
              Authenticator App in your Mobile
            </span>

            <Row style={{ marginTop: "10px", justifyContent: "center" }}>
              <Image
                src={string}
                style={{
                  width: "200px",
                  height: "200px",
                  marginBottom: "5px",
                }}
              />
            </Row>
            <span className="capitalized">
              For Manual Process, Please copy and Paste the Key for verification
              from the Google Authenticator App in your Mobile
            </span>
            <Row
              style={{
                marginTop: "20px",
                justifyContent: "center",
                width: "100%",
                flexDirection: "row",
              }}
            >
              <span
                style={{
                  textAlign: "center",
                  fontSize: 20,
                  alignSelf: "center",
                  backgroundColor: "#f5f5f5",
                  padding: 5,
                }}
              >
                {key}
              </span>
              <CopyToClipboard text={key}>
                <Button
                  style={{
                    marginLeft: 20,
                    marginRight: 20,
                    width: 100,
                  }}
                  onClick={() => {
                    setCopyText(true);
                  }}
                >
                  {copyText ? "Copied" : "Copy"}
                </Button>
              </CopyToClipboard>
            </Row>
            <Row style={{ justifyContent: "center", textAlign: "center" }}>
              <Col lg={6} sm={12} style={{ marginTop: "20px" }}>
                <span style={{ fontSize: "20px" }}>OTP</span>
                <Form.Input
                  type="text"
                  style={{ width: "50%", marginLeft: "25%" }}
                  value={formik.values.otp}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  id="otp"
                  name="otp"
                  className={
                    formik.errors.otp && formik.touched.otp
                      ? "text-input-error"
                      : "text-input"
                  }
                />
                {formik.errors.otp && formik.touched.otp && (
                  <div
                    style={{
                      color: "red",
                      fontSize: "12px",
                    }}
                  >
                    {formik.errors.otp}
                  </div>
                )}

                <Button
                  id="login-button"
                  disabled={!(formik.isValid && formik.dirty)}
                  onClick={(e) => validateUser(e)}
                >
                  Verify
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row justifyContent="md-center"></Row>
      </Container>
      <ToastContainer />
    </>
  );
};

export default GoogleAuthenticator;
