import React from "react";
import "./ppolicy.css";
import { Container } from "react-bootstrap";
function Privacypolicy() {
  return (
    <div
      className="App"
      style={{
        padding: "1.5rem",
        margin: "5px",
      }}
    >
      <div className="pp1">
        <h4 className="titlle">1- Introduction</h4>
        <br />
        <p className="pp">
          At Seven7 Perfection Pte Ltd (“Payment Planet”) we respect the privacy
          and confidentiality of the perso nal data of our Customers, Associates
          and others whom we interact with in the course of providing our servi
          ces. We are committed to implementing policies, practices and
          processes to safeguard the collection, use and disclosure of the
          personal data you provide us, in compliance with the{" "}
          <a> Singapore Personal Data Protection Act ( PDPA) 2012.</a>
        </p>

        <p className="pp">
          We have developed this Data Privacy Notice to assist you in
          understanding how w e collect, use, disclose, process, protect and
          retain your personal data that is in our possession.
        </p>
      </div>
      <div className="pp1">
        <h4 className="titlle">2 - How We Collect Your Personal Data</h4>
        <p className="pp">
          Personal data refers to any information that can uniquely identify an
          individ ual person (a) on its own, or (b) when combined with other
          information. Under the PDPA, business contact information (e.g. full
          name, business address, business telephone number) is not considered
          as personal data so long as it is used strictly for
          business-to-business (B2B) transactions <br />
        </p>
        <p className="pp">We collect your personal data when you:</p>
        <ul style={{ listStyle: "circle", left: "5%", position: "relative" }}>
          <li>
            {" "}
            Register for an account on our Payment Planet applications (Web, iOS
            and Android), via manual registration or SingPass authentication{" "}
          </li>
          <li>
            {" "}
            Respond to our electronic direct mails (EDMs) sent by us as part of
            our marketing or promotion campaigns through an authorised third
            party EDM service provider
          </li>
          <li>
            {" "}
            Are referred to us for our services by our partners or clients
          </li>
          <li>Unsolicitly enquire about our range of services</li>
          <li>
            {" "}
            Visit our website and provide your contact information through our
            contact channels
          </li>
          <li>
            {" "}
            Communicate with us via emails, written or verbal correspondences
          </li>
        </ul>
      </div>
      <div className="pp1">
        <h4 className="titlle">
          3 - Types of Personal Data We Collect About You
        </h4>
        <p className="pp">
          The types of personal data we collect about you from our applications
          include:
        </p>
        <ul style={{ listStyle: "circle", left: "5%", position: "relative" }}>
          <li>
            {" "}
            Your contact information (Name, Email Address. Mobile number)
          </li>
          <li>
            {" "}
            Your residential and mailing address (to send your purchased card to
            you)
          </li>
          <li>
            {" "}
            Your personal details (Name, Gender, Date of Birth, Nationality)
          </li>
          <li>
            {" "}
            Your Passport image and details for prepaid/debit card application
            and processing
          </li>
          <li> Your selfie image for Know-Your-Customer (KYC) verification</li>
          <li> Your selfie image for Know-Your-Customer (KYC) verification</li>
        </ul>
      </div>
      <div className="pp1">
        <h4 className="titlle">4 - How We Use Your Personal Data</h4>{" "}
        <p>
          We use the personal data you provide us for one or more of the
          following purposes:
        </p>
        <ul style={{ listStyle: "circle", left: "5%", position: "relative" }}>
          <li>
            {" "}
            Enrolling you onto your selected prepaid/debit card programme
          </li>
          <li> Process billing, payment and other credit-related activities</li>
          <li> Conduct direct marketing and lead generation activities</li>
          <li>
            {" "}
            Conduct joint marketing with other companies and service providers
          </li>
          <li> Communicate with customers, members and website visitors</li>
          <li>
            {" "}
            Respond to your inquiries and feedback to improve our quality of
            service
          </li>
          <li> Analyse the use of our products, services or websites</li>
          <li>
            {" "}
            Carry out our obligations arising from any contracts entered into
            between you and us{" "}
          </li>
          <li>
            {" "}
            Comply with or fulfil legal obligations and regulatory requirements{" "}
          </li>
        </ul>
      </div>
      <div className="pp1">
        <h4 className="titlle">5 - Who We Disclose Your Personal Data To</h4>
        <p>
          We disclose some of the personal data you provide us to the following
          entities or organisations outside Payment Planet in order to fulfil
          our services to you:
        </p>
        <ul style={{ listStyle: "circle", left: "5%", position: "relative" }}>
          <li>
            {" "}
            Card Programme Technology partner and/or Card issuing Financial
            institution
          </li>
          <li> External professional service providers (Book-keepers)</li>
          <li> EDM/Email Service Vendors </li>
          <li>Cloud Service Providers</li>
        </ul>
        <p>
          Where required to do so by law, we may disclose personal data about
          you to the relevant authorities or to law enforcement agencies.
        </p>
      </div>
      <div className="pp1">
        <h4 className="titlle">
          6 - How We Manage the Collection, Use and Disclosure of Your Personal
          Data
        </h4>
        <h4 className="titlle">6.1 Obtaining Consent</h4>{" "}
        <p>
          Before we collect, use or disclose your personal data, we will notify
          you of the purpose why we are doing so. We will obtain written
          confirmation from you on your expressed consent. We will not collect
          more personal data than is necessary for the stated purpose. We will
          seek fresh consent from you if the original purpose for the
          collection, use or disclosure of your personal data has changed.
        </p>
        <p>
          Under certain circumstances, we may assume deemed consent from you
          when you voluntarily provide your personal data for the stated
          purpose, e.g. when you apply for a job with us by sending in your
          resume/CV containing personal information
        </p>
        <p>
          We may rely on exceptions to the need for consent under the PDPA for
          the collection, use or disclosure of your personal data under the
          following circumstances (only those relevant to Winovr are included):
        </p>
        <ul style={{ listStyle: "circle", left: "5%", position: "relative" }}>
          <li> The personal data is publicly available</li>
          <li>
            The personal data is disclosed by a public agency or disclosed to a
            public agency
          </li>
          <li>
            The personal data is necessary for any investigation or proceedings
          </li>
          <li>
            {" "}
            The personal data is necessary for evaluative purposes (e.g.
            determining the suitability of a job applicant for the job applied
            for)
          </li>
          <li>
            {" "}
            The personal data is necessary for the purpose of managing or
            terminating an employment relationship
          </li>
          <li>
            The personal data is necessary for a business asset transaction
          </li>
        </ul>
        <h4 className="titlle">6.2 Withdrawal of Consent</h4>{" "}
        <p>
          If you wish to withdraw consent, you should give us reasonable advance
          notice. We will advise you of the likely consequences of your
          withdrawal of consent, e.g. without your personal contact information
          we may not be able to inform you of future services offered by us.
        </p>
        <p>
          Your request for withdrawal of consent can take the form of an email
          or letter to us, or through the “Unsubscribe” feature in an online
          service.
        </p>
        <h4 className="titlle">6.3 Use of Cookies</h4>
        <p>
          We use “cookies” to collect information about your online activity on
          our website. A cookie is a small text file created by the website that
          is stored in your computer to provide a way for the website to
          recognise you and keep track of your preferences. The cookie makes it
          convenient for you such that you do not have to retype the same
          information again when you revisit the website or in filling
          electronic forms.
        </p>
        <p>
          Most cookies we use are “session cookies”, which will be deleted
          automatically from the hard disk of your computer at the end of the
          session.
        </p>
        <p>
          You may choose not to accept cookies by turning off this feature in
          your web browser. Note that by doing so, you may not be able to use
          some of the features and functions in our web applications.
        </p>
        <h4 className="titlle">6.4 Third-Party Consent</h4>{" "}
        <p>
          We do not get consent on behalf of another individual. We only get
          consent from the individual who will be dealing directly with us
        </p>
      </div>
      <div className="pp1">
        <h4 className="titlle">
          7 - How We Ensure the Accuracy of Your Personal Data
        </h4>{" "}
        <p>
          We will take reasonable steps to ensure that the personal data we
          collect about you is accurate, complete, not misleading and kept
          up-to-date.
        </p>
        <p>
          From time to time, we may do a data verification exercise for you to
          update us on any changes to the personal dat a we hold about you. If
          we are in an ongoing relationship with you, it is important that you
          update us of any changes to your personal data (such as a change in
          your mailing address)
        </p>
      </div>
      <div className="pp1">
        <h4 className="titlle">8 - How We Protect Your Personal Data</h4>
      </div>{" "}
      <p>
        We have implemented appropriate information security and technical
        measures to protect the personal data we hold about you against loss;
        misuse; destruction; unauthorised alteration/modification, access,
        disclosure; or similar risks.
      </p>
      <p>
        We have also put in place reasonable and appropriate organisational
        measures to maintain the confidentiality and integrity of your personal
        data, and will only share your data with authorised persons on a ‘need
        to know’ basis.
      </p>
      <p>
        When we engage third-party data processors to process personal data on
        our behalf, we will ensure that they provide sufficient guarantees to us
        to have implemented the necessary organisational and technical security
        measures, and have taken reasonable steps to comply with these measures.
      </p>
      <div className="pp1">
        <h4 className="titlle">9 - How We Retain Your Personal Data</h4>{" "}
        <p>
          We have a document retention policy that keeps track of the retention
          schedules of the personal data you provide us, in paper or electronic
          forms. We will not retain any of your personal data when it is no
          longer needed for any business or legal purposes.
        </p>
        <p>
          We will dispose of or destroy such documents containing your personal
          data in a proper and secure manner when the retention limit is
          reached.
        </p>
      </div>
      <div className="pp1">
        <h4 className="titlle">
          10 How You Can Access and Make Correction to Your Personal Data
        </h4>{" "}
        <p>
          You may write in to us to find out how we have been using or
          disclosing your personal data over the past one year. Before we accede
          to your request, we may need to verify your identity by checking your
          NRIC or other legal identification document. We will respond to your
          request as soon as possible, or within 30 days from the date we
          receive your request. If we are unable to do so within the 30 days, we
          will let you know and give you an estimate of how much longer we
          require. We may also charge you a reasonable fee for the cost involved
          in processing your access request.
        </p>
        <p>
          If you find that the personal data we hold about you is inaccurate,
          incomplete, misleading or not up-to-date you may ask us to correct the
          data. Where we are satisfied on reasonable grounds that a correction
          should be made, we will correct the data as soon as possible, or
          within 30 days from the date we receive your request.
        </p>
      </div>
      <div className="pp1">
        <h4 className="titlle">11 - Transfer of Personal Data</h4>{" "}
        <p>
          Where there is a need to transfer your personal data to another
          country outside Singapore, w e will ensure that the standard of data
          protection in the recipient country is comparable to that of
          Singapore's PDPA. If this is not so, we will enter into a contractual
          agreement with the receiving party to accord similar levels of data
          protection as those in Singapore.
        </p>
      </div>
      <div className="pp1">
        <h4 className="titlle">12 - Mandatory Data Breach Notification</h4>{" "}
        <p>
          In the unlikely event that we suffer a data breach pertaining to
          unauthorised access or disclosure of personal data being stored or
          processed by us, we will meet the PDPA's breach notification timelines
          an d requirements to perform the needful, including but not limited to
          informing relevant authorities and affected individuals, based on the
          Significant Harm or Significant Scale definitions as set out by the
          PDPA.
        </p>
      </div>
      <div className="pp1">
        <h4 className="titlle">13 - Contacting Us</h4>{" "}
        <p>
          If you have any query or feedback regarding this Notice, or any
          complaint you have relating to how we manage your personal data, you
          may contact our Data Protection Officer (DPO) at:
          product_support@seven7perfection.com
        </p>
        <p>
          Any query or complaint should include, at least, the following
          details:
        </p>
        <ul style={{ listStyle: "circle", left: "5%", position: "relative" }}>
          <li> Your full name and contact information</li>
          <li> Brief description of your query or complaint</li>
        </ul>
        <p>
          We treat such queries and feedback seriously and will deal with them
          confidentially and within reasonable time.
        </p>
      </div>
      <div className="pp1">
        <h4 className="titlle">14 - Changes to this Data Privacy Notice</h4>{" "}
        <br />
        <p>
          We may update this Data Privacy Notice from time to time. We will
          notify you of any changes by posting the latest Notice on our website.
          Please visit our website periodically to note any changes.
          <p>
            Changes to this Notice take effect when they are posted on our
            website.
          </p>
          <p>Last updated: 30 May 2023</p>
        </p>
      </div>
    </div>
  );
}
export default Privacypolicy;
