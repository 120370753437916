import { Container, Row, Col, Form } from "bootstrap-4-react";
import React, { useState, useEffect } from "react";
import "./updateWalletPin.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import API_PATHS from "../../../constants/apiPaths";
import { Modal, Image, Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { ProgressBar } from "react-loader-spinner";
import { getCookie } from "../../../Utils/cookieHandling";
// toast.configure();

const UpdatePassword = (formik) => {
  const { values, errors, touched } = formik;
  const { email, id, accessToken, userType } = useSelector((state) => state);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [string, setString] = useState(" ");
  const [ascii, setAscii] = useState();
  const [userAscii, setUserAscii] = useState();
  const [pass, setPass] = useState(false);

  const handleClose = () => {
    setPass(false);
    setShow(false);
  };

  useEffect(() => {
    axios
      .get(API_PATHS.GET_DETAILS + email, {
        headers: {
          accepts: "application/json",
          Authorization: `${getCookie("c4")} ${getCookie("c3")}`,
        },
      })
      .then((res) => {
        setUserAscii(res.data.ascii_value);
      });
  }, []);

  const handleShow = () => {
    if (values.newWalletPin && values.confirmWalletPin) {
      setPass(true);
      const obj = {
        email: email,
      };
      axios
        .post(API_PATHS.SECRET_KEY_GOOGLE, obj, {
          headers: {
            accepts: "application/json",
            Authorization: `${getCookie("c4")} ${getCookie("c3")}`,
          },
        })
        .then((res) => {
          const { qrcodes } = res.data;
          const { ascii } = res.data.secret;
          setString(qrcodes);
          setAscii(ascii);
          setShow(true);
        });
    } else {
      toast.error("Please enter all the fields");
    }
  };

  const validateUser = () => {
    const obj = {
      asci: ascii,
      otp: Number(values.otp),
    };
    if (!userAscii) {
      const asci = {
        ascii_value: ascii,
      };
      axios
        .put(API_PATHS.UPDATE_ASCII + email, asci, {
          headers: {
            accepts: "application/json",
            Authorization: `${getCookie("c4")} ${getCookie("c3")}`,
          },
        })
        .then((res) => {});
    }
    axios
      .post(API_PATHS.VERIFY_GOOGLE_OTP, obj, {
        headers: {
          accepts: "application/json",
          Authorization: `${getCookie("c4")} ${getCookie("c3")}`,
        },
      })
      .then((res) => {
        const obj = {
          email: email,
        };
        if (res.data) {
          axios
            .post(API_PATHS.SS0_REGISTER, obj, {
              headers: {
                accepts: "application/json",
                Authorization: `${getCookie("c4")} ${getCookie("c3")}`,
              },
            })
            .then((response) => {
              // toast("User Validated");
              updateWalletPin();
            });
        } else {
          toast("Invalid OTP");
        }
      });
  };

  const updateWalletPin = () => {
    const obj = {
      id: id,
      wallet_pin: values.newWalletPin,
      OTP: values.otp,
    };

    axios
      .request({
        method: "post",
        url: API_PATHS.UPDATE_WALLET_PIN,
        data: obj,
        crossDomain: "true",
        headers: {
          accepts: "application/json",
          Authorization: `${getCookie("c4")} ${getCookie("c3")}`,
        },
      })
      .then((res) => {
        toast(res.data.message);
        handleClose();
      });
  };

  return (
    <>
      {/* <ToastContainer /> */}
      <Container fluid>
        <Row style={{ paddingBottom: "20px" }}>
          <Col lg={6} sm={6} style={{ paddingTop: "30px" }}>
            <span>Wallet Pin</span>
            <Form.Input
              type="password"
              value={values.newWalletPin}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={
                errors.newWalletPin && touched.newWalletPin
                  ? "text-input-error"
                  : "text-input"
              }
              name="newWalletPin"
            />
            {errors.newWalletPin && touched.newWalletPin && (
              <div style={{ color: "red" }}>{errors.newWalletPin}</div>
            )}
          </Col>
          <Col lg={6} sm={6} style={{ paddingTop: "30px" }}>
            <span>Confirm Wallet Pin</span>
            <Form.Input
              type="password"
              value={values.confirmWalletPin}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="confirmWalletPin"
              className={
                errors.confirmWalletPin && touched.confirmWalletPin
                  ? "text-input-error"
                  : "text-input"
              }
            />
            {errors.confirmWalletPin && touched.confirmWalletPin && (
              <div style={{ color: "red" }}>{errors.confirmWalletPin}</div>
            )}
          </Col>
        </Row>
        <Row>
          <Col className="text-center" style={{ paddingTop: "30px" }}>
            {pass ? (
              <ProgressBar
                height="80"
                width="80"
                ariaLabel="progress-bar-loading"
                wrapperStyle={{}}
                wrapperClass="progress-bar-wrapper"
                borderColor="#F4442E"
                barColor="#51E5FF"
              />
            ) : (
              <button
                style={{
                  background:
                    "linear-gradient(180deg, #F74D58 0%, #E93944 54.91%, #DE2834 100%)",
                  border: "1px solid #DE2834",
                  boxshadow: "2px 3px 28px rgba(222, 40, 52, 0.45)",
                  borderRadius: "60px",
                  color: "#fff",
                  width: "100px",
                  cursor: "pointer",
                  padding:8,
                  opacity: formik.isValid && formik.dirty ? 1 : 0.5,
                }}
                disabled={!(formik.isValid && formik.dirty)}
                onClick={() => handleShow()}
              >
                Update
              </button>
            )}
          </Col>
        </Row>
      </Container>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Verify</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span>
            Please enter the OTP for verification from the Google Authenticator
            App in your Mobile
          </span>

          {string && (
            <>
              <Row style={{ marginTop: "10px", justifyContent: "center" }}>
                {userAscii === "" ? (
                  <Image
                    src={string}
                    style={{ width: "200px", height: "200px" }}
                  />
                ) : (
                  " "
                )}
              </Row>
              <Row style={{ justifyContent: "center", textAlign: "center" }}>
                <Col lg={3} sm={12} style={{ marginTop: "10px" }}>
                  <span style={{ fontSize: "20px", padding: "15px" }}>OTP</span>
                  <Form.Input
                    type="text"
                    value={formik.values.otp}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    id="otp"
                    name="otp"
                    style={{ width: "100%" }}
                    className={
                      formik.errors.otp && formik.touched.otp
                        ? "text-input-error"
                        : "text-input"
                    }
                  />
                  {formik.errors.otp && formik.touched.otp && (
                    <div style={{ color: "red", fontSize: "12px" }}>
                      {formik.errors.otp}
                    </div>
                  )}
                </Col>
              </Row>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            disabled={!values.otp}
            onClick={validateUser}
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UpdatePassword;
