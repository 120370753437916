import { Container, Row, Col, Form } from "bootstrap-4-react";
// import { Button } from "bootstrap-4-react/lib/components";
import React, { useState, useEffect } from "react";
import "./updateMobile.css";
import { url } from "../../../constants/url";
import axios from "axios";
// import PhoneInput, { parsePhoneNumber } from "react-phone-number-input";
import PhoneInput from "react-phone-input-2";

import { Image } from "react-bootstrap";
import API_PATHS from "../../../constants/apiPaths";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import { CopyToClipboard } from "react-copy-to-clipboard";
import "react-tippy/dist/tippy.css";
import { Button } from "bootstrap-4-react";
import { useSelector, useDispatch } from "react-redux";
import { ProgressBar } from "react-loader-spinner";
import { getCookie } from "../../../Utils/cookieHandling";

import { addCallingCode, addMobile } from "../../../redux/actions";

const UpdateMobile = (formik) => {
  const dispatch = useDispatch();
  const { fullName, photoURL, callingCode, mobile, userType } = useSelector(
    (state) => state
  );
  const email = getCookie("c1");
  const token = getCookie("c3");
  const userTypeCookie = getCookie("c4");
  const { values, errors, touched } = formik;
  const [show, setShow] = useState(false);
  const [string, setString] = useState(" ");
  const [ascii, setAscii] = useState();
  const [userAscii, setUserAscii] = useState();
  const [phone, setPhone] = useState();
  const [confirm, setConfirm] = useState();
  const [newPhone, setNewPhone] = useState();
  const [countryFlag, setCountryFlag] = useState("");
  const [key, setKey] = useState();
  const [copyText, setCopyText] = useState(false);
  const [pass, setPass] = useState(false);
  const [id, setId] = useState("");
  const [sso, setSso] = useState(false);
  const [singpass, SetSingPass] = useState(false);
  useEffect(() => {
    if ((userType === "ssoUser") || (userType === "SSO_USER")) {
      setSso(true);
    }
    else {
      setSso(false);
    }
    if (userType === "SINGPASS") {
      SetSingPass(true);
    } else {
      SetSingPass(false);
    }
  }, [userType]);

  useEffect(() => {
    getDetails();
  }, []);
  const getDetails = () => {
    axios
      .get(API_PATHS.GET_DETAILS + email, {
        headers: {
          accepts: "application/json",
          Authorization: `${userTypeCookie} ${token}`,
        },
      })
      .then((res) => {
        setPhone(
          res.data.countryCode
            ? `+${res.data.countryCode}${res.data.contactNumber}`
            : "+65"
        );
        setUserAscii(res.data.ascii_value);
        setId(res.data.id);
      });
  };

  useEffect(() => {
    if (!show) {
      setPass(false);
    }
    setPhone(callingCode ? "+" + callingCode + mobile : "+65");
  });

  const handleClose = () => {
    values.otp = "";
    setCopyText(false);
    setPass(false);
    setShow(false);
  };
  const handleShow = () => {
    setPass(true);
    if (values.phone && values.newPhone && values.confirmPhone) {
      if (values.newPhone != values.confirmPhone) {
        toast.error(
          "New Mobile Number and Confirm New Mobile Number Should be same"
        );
        return;
      } else {
        if (!userAscii) {
          const obj = {
            email: email,
          };
          axios
            .post(API_PATHS.SECRET_KEY_GOOGLE, obj, {
              headers: {
                accepts: "application/json",
                Authorization: `${userTypeCookie} ${token}`,
              },
            })
            .then((res) => {
              const { qrcodes } = res.data;
              const { ascii } = res.data.secret;
              setString(qrcodes);
              setAscii(ascii);
              setKey(res.data.secret.base32);
              setShow(true);
            });
        } else {
          setAscii(userAscii);
          setShow(true);
        }
      }
    } else {
      toast.error("Please enter all the fields");
    }
  };

  const validateUser = () => {
    const obj = {
      asci: ascii,
      otp: Number(values.otp),
    };
    if (!userAscii) {
      const asci = {
        ascii_value: ascii,
      };
      axios
        .put(API_PATHS.UPDATE_ASCII + email, asci, {
          headers: {
            accepts: "application/json",
            Authorization: `${userTypeCookie} ${token}`,
          },
        })
        .then((res) => { });
    }
    axios
      .post(API_PATHS.VERIFY_GOOGLE_OTP, obj, {
        headers: {
          accepts: "application/json",
          Authorization: `${userTypeCookie} ${token}`,
        },
      })
      .then((res) => {
        const obj = {
          email: email,
        };
        if (res.data) {
          axios
            .post(API_PATHS.SS0_REGISTER, obj, {
              headers: {
                accepts: "application/json",
                Authorization: `${userTypeCookie} ${token}`,
              },
            })
            .then((response) => {
              updateMobile();
            });
        } else {
          toast("Invalid OTP");
        }
      });
  };

  const updateMobile = () => {
    const obj = {
      id: id,
      countryCode: Number(String(values.newPhone).substring(0, 3)),
      contactNumber: Number(String(values.newPhone).substring(3, 13)),
      countryFlag: countryFlag,
      OTP: " ",
    };
    axios
      .request({
        method: "post",
        url: API_PATHS.UPDATE_MOBILE,
        data: obj,
        crossDomain: "true",
        headers: {
          accepts: "application/json",
          Authorization: `${userTypeCookie} ${token}`,
        },
      })
      .then((res) => {
        toast.success(res.data.message);
        dispatch(addCallingCode(obj.countryCode));
        dispatch(addMobile(obj.contactNumber));
        setConfirm("+65");
        setNewPhone("+65");
        values.otp = "";
        handleClose();
      });
  };

  useEffect(() => {
    values.phone = phone;
    values.newPhone = newPhone;
    values.confirmPhone = confirm;
  }, [phone, newPhone, confirm]);

  return (
    <>
      {/* <ToastContainer /> */}
      <Container fluid>
        <Row>
          <Col lg={6} sm={12} style={{ marginTop: "30px" }}>
            <span>Current Mobile Number</span>
            {/* <PhoneInput
              international
              defaultCountry="SG"
              value={phone}
              limitMaxLength={10}
              onChange={setPhone}
              onBlur={formik.handleBlur}
              countryCallingCodeEditable={false}
              readOnly
              style={{
                lineHeight: "30px",
                border: "0px",
                borderRadius: "25px",
              }}
              id="phone"
              name="phone"
              className={
                errors.phone && touched.phone
                  ? "text-input-error"
                  : "text-input"
              }
            /> */}
            <PhoneInput
              style={{
                lineHeight: "30px",
                border: "0px",
                borderRadius: "25px",
              }}
              inputStyle={{ backgroundColor: "#D3D3D3" }}
              disabled={true}
              country={"sg"}
              name="contactNumber"
              className={"inputtype form-control-2"}
              // component={TextField}
              // onChange={(e, f) => {

              // 	setPhone(e)

              // }}
              value={phone}
              onChange={(e, f) => {
                setPhone(e);
                setCountryFlag(f.countryCode);
              }}
            />
            {errors.phone && touched.phone && (
              <div style={{ color: "red" }}>{errors.phone}</div>
            )}
            {errors.phone && touched.phone && (
              <div style={{ color: "red" }}>{errors.phone}</div>
            )}
          </Col>
          <Col lg={6} sm={12} style={{ marginTop: "30px" }}>
            <span>New Mobile Number</span>
            {/* <PhoneInput
              international
              defaultCountry="SG"
              value={newPhone}
              limitMaxLength={10}
              onChange={setNewPhone}
              onBlur={formik.handleBlur}
              // countryCallingCodeEditable={false}
              style={{
                lineHeight: "30px",
                border: "0px",
                borderRadius: "25px",
              }}
              id="phone"
              name="phone"
              className={
                errors.newPhone && touched.newPhone
                  ? "text-input-error"
                  : "text-input"
              }
            /> */}
            <PhoneInput
              style={{
                lineHeight: "30px",
                border: "0px",
                borderRadius: "25px",
              }}
              // disableDropdown = {true}
              inputStyle={{ backgroundColor: "transparent" }}
              // disabled={singpass}
              country={"sg"}
              name="contactNumber"
              className={"inputtype form-control-2"}
              // component={TextField}
              // onChange={(e, f) => {

              // 	setPhone(e)

              // }}
              value={newPhone}
              onChange={(e, f) => {
                setNewPhone(e);
                setCountryFlag(f.countryCode);
              }}
            />

            {errors.newPhone && touched.newPhone && (
              <div style={{ color: "red" }}>{errors.newPhone}</div>
            )}
          </Col>
          <Col lg={6} sm={12} style={{ marginTop: "30px" }}>
            <span>Confirm New Mobile Number</span>
            {/* <PhoneInput
              international
              defaultCountry="SG"
              value={confirm}
              limitMaxLength={10}
              onChange={setConfirm}
              onBlur={formik.handleBlur}
              onCountryChange={(value)=>{setCountryFlag(value)}}
              // countryCallingCodeEditable={false}
              style={{
                lineHeight: "30px",
                border: "0px",
                borderRadius: "25px",
              }}
              id="values.confirmPhone"
              name="phone"
              className={
                errors.confirmPhone && touched.confirmPhone
                  ? "text-input-error"
                  : "text-input"
              }
            /> */}
            <PhoneInput
              style={{
                lineHeight: "30px",
                border: "0px",
                borderRadius: "25px",
              }}
              inputStyle={{ backgroundColor: "transparent" }}
              // disabled={singpass}
              country={"sg"}
              name="contactNumber"
              className={"inputtype form-control-2"}
              // component={TextField}
              // onChange={(e, f) => {

              // 	setPhone(e)

              // }}
              value={confirm}
              onChange={(e, f) => {
                setConfirm(e);
                setCountryFlag(f.countryCode);
              }}
            />

            {errors.confirmPhone && touched.confirmPhone && (
              <div style={{ color: "red" }}>{errors.confirmPhone}</div>
            )}
          </Col>
        </Row>
        <Row justifyContent="md-center" style={{ padding: "20px" }}>
          <Col lg={4} className="text-center">
            {pass ? (
              <ProgressBar
                height="80"
                width="80"
                ariaLabel="progress-bar-loading"
                wrapperStyle={{}}
                wrapperClass="progress-bar-wrapper"
                borderColor="#F4442E"
                barColor="#51E5FF"
              />
            ) : (
              <button
                style={{
                  background:
                    "linear-gradient(180deg, #F74D58 0%, #E93944 54.91%, #DE2834 100%)",
                  border: "1px solid #DE2834",
                  boxshadow: "2px 3px 28px rgba(222, 40, 52, 0.45)",
                  borderRadius: "60px",
                  color: "#fff",
                  width: "100px",
                  padding: 8,
                  opacity:
                    newPhone && confirm && newPhone === confirm ? 1 : 0.5,
                }}
                disabled={!(newPhone && confirm && newPhone === confirm)}
                onClick={() => handleShow()}
              >
                Update
              </button>
            )}
          </Col>
        </Row>
      </Container>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Verify</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span className="capitalized">
            Please enter the OTP for verification from the Google Authenticator
            App in your Mobile
          </span>

          {string && (
            <>
              <Row style={{ marginTop: "10px", justifyContent: "center" }}>
                {userAscii === null || userAscii === "" ? (
                  <>
                    <Image
                      src={string}
                      style={{ width: "200px", height: "200px" }}
                    />
                    <span className="capitalized">
                      For manual process, Please copy and paste the key for
                      verification from the Google Authenticator App in your
                      Mobile
                    </span>
                    <Row
                      style={{
                        marginTop: "20px",
                        justifyContent: "center",
                        width: "100%",
                        flexDirection: "row",
                      }}
                    >
                      <span
                        style={{
                          textAlign: "center",
                          fontSize: 20,
                          alignSelf: "center",
                          backgroundColor: "#f5f5f5",
                          padding: 5,
                        }}
                      >
                        {key}
                      </span>
                      <CopyToClipboard text={key}>
                        <Button
                          style={{
                            marginLeft: 20,
                            marginRight: 20,
                            width: 100,
                          }}
                          onClick={() => {
                            setCopyText(true);
                          }}
                        >
                          {copyText ? "Copied" : "Copy"}
                        </Button>
                      </CopyToClipboard>
                    </Row>
                  </>
                ) : (
                  " "
                )}
              </Row>
              <Row style={{ justifyContent: "center", textAlign: "center" }}>
                <Col lg={3} sm={12} style={{ marginTop: "10px" }}>
                  <span style={{ fontSize: "20px", padding: "15px" }}>OTP</span>
                  <Form.Input
                    type="text"
                    value={formik.values.otp}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    id="otp"
                    name="otp"
                    className={
                      formik.errors.otp && formik.touched.otp
                        ? "text-input-error"
                        : "text-input"
                    }
                  />
                  {formik.errors.otp && formik.touched.otp && (
                    <div style={{ color: "red", fontSize: "12px" }}>
                      {formik.errors.otp}
                    </div>
                  )}
                </Col>
              </Row>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            disabled={!values.otp}
            onClick={validateUser}
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UpdateMobile;
