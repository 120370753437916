import React from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import axios from "axios";
import NIUMApplication from "./nium";
import API_PATHS from "../../../constants/apiPaths";
import { useSelector } from "react-redux";
import { getCookie } from "../../../Utils/cookieHandling";
const NiumProcess = () => {
  const { email, accessToken, userType } = useSelector((state) => state);
  const [data, setData] = useState([]);
  const initialValues = {
    title: "",
    email: email,
    firstName: data.first_name ? data.first_name : "",
    lastName: data.last_name ? data.last_name : "",
    nationality: data.nationality ? data.nationality : "",
    dob: data.dob ? data.dob : "",
    phone: data.contactNumber ? data.contactNumber : 0,
    address1: data.address1 ? data.address1 : "",
    address2: data.address2 ? data.address2 : "",
    postalCode: data.pincode ? data.pincode : "",
    unitNumber: "",
  };

  useEffect(() => {
    axios
      .get(API_PATHS.GET_DETAILS + email, {
        headers: {
          accepts: "application/json",
          Authorization: `${getCookie("c4")} ${getCookie("c3")}`,
        },
      })
      .then((res) => {
        setData(res.data);
      });
  }, [email]);

  const validationSchema = Yup.object({
    title: Yup.string().required("Title is required"),
    firstName: Yup.string().required("First Name is required"),
    floor: Yup.string().required("Please enter floor"),
    lastName: Yup.string().required("Last name is required"),
    nationality: Yup.string().required("Nationality is required"),
    dob: Yup.date().required("Date is required"),
    phone: Yup.number().required("Mobile is required"),
    address1: Yup.string().required("Address is required"),
    address2: Yup.string().required("Address is required"),
    postalCode: Yup.string().required("Postal Code is required"),
    unitNumber: Yup.string().required("Unit Number is required"),
  });
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {(formik) => <NIUMApplication {...formik} />}
    </Formik>
  );
};

export default NiumProcess;
