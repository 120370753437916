import { getAuth } from "firebase/auth";
import firebase from "firebase/compat/app";

const firebaseConfig = {
  apiKey: "AIzaSyDq-SuEwE1V0mngd_0Bc_9ZfdNiUJojk2k",
  authDomain: "baas-mobile.firebaseapp.com",
  projectId: "baas-mobile",
  storageBucket: "baas-mobile.appspot.com",
  messagingSenderId: "220873093196",
  appId: "1:220873093196:web:9e7afaadfdfdb1046344e9",
};

const app = firebase.initializeApp(firebaseConfig);

export const auth = getAuth(app);
export { firebase };
