import React, { useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import UpdateWalletPin from "./updateWalletPin"

const UpdateWalletPinProcess = () => {
  const initialValues = {
    newWalletPin: "",
    confirmWalletPin: "",
    otp: "",
  };

  const validationSchema = Yup.object({
    //walletPin: Yup.string().required("Wallet Pin is required"),
    newWalletPin: Yup.string()
      .min(4, "The pin should be 4 digits")
      .required("Please enter the new Wallet Pin"),
    confirmWalletPin: Yup.string()
      .oneOf([Yup.ref("newWalletPin"), null], "Pin must match")
      .required("Confirm the Wallet Pin"),
    otp: Yup.number().required("Otp is required"),
  });
  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema}>
      {(formik) => <UpdateWalletPin {...formik} />}
    </Formik>
  );
};

export default UpdateWalletPinProcess;
