import React from "react";
import { Figure, Button } from "bootstrap-4-react";

const AppleLoginComponent = ({ appleSignIn }) => {
  const handleSignIn = async () => {
    try {
      await appleSignIn();
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Button
      style={{
        background: " #FFFFFF",
        border: "1px solid #F3DEDC",
        boxShadow: "2px 3px 26px rgba(225, 225, 225, 0.17)",
        borderRadius: "12px",
      }}
      onClick={(e) => {
        e.persist();
        e.stopPropagation();
        handleSignIn(e);
      }}
    >
      <Figure.Image
        src={require("../../../assets/apple.png")}
        width="25"
        style={{ padding: "1px" }}
      />
      <span style={{ fontSize: "1rem", paddingLeft: "3px", fontWeight: "500",color:"black" }}>
        Apple
      </span>
    </Button>
  );
};

export default AppleLoginComponent;
