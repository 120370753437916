import React from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import API_PATHS from "../../../constants/apiPaths";
import { useEffect, useState } from "react";
import axios from "axios";
import atob from "atob";
import JDBApplication from "./jdb";
import moment from "moment";
import { getCode, getName } from "country-list";
import { useSelector } from "react-redux";
import { getCookie } from "../../../Utils/cookieHandling";
const JDBProcess = () => {
  const { email, accessToken, userType } = useSelector((state) => state);
  const [data, setData] = useState();
  const initialValues = {
    cardType: "",
    title: "",
    firstName: "",
    lastName: "",
    phone: "",
    dob: "",
    email: email,
    gender: "",
    mStatus: "",
    ePerson: "",
    ephone: null,
    passportNo: "",
    pIssuedDate: "",
    pExpiryDate: "",
    IDType: "Passport",
    IDCountry: "",
    address: "",
    state: "",
    city: "",
    postalCode: "",
    country: "",
    nationality: "",
    mailAddress: "",
    mailCity: "",
    mailState: "",
    mailPostalCode: "",
    mailCountry: "",
    passport_biopage: "",
    selfie_passport_biopage: "",
    selfie: "",
    trimmedDataURL: "",
  };

  useEffect(() => {
    axios
      .get(API_PATHS.GET_DETAILS + email, {
        headers: {
          accepts: "application/json",
          Authorization: `${getCookie("c4")} ${getCookie("c3")}`,
        },
      })
      .then((res) => {
        setData(res.data);
        initialValues.firstName = res.data.first_name;
        initialValues.lastName = res.data.last_name;
        initialValues.dob = moment(res.data.dob).format("YYYY-MM-DD");
        initialValues.address = `${res.data.address1}, ${res.data.address2}`;
        initialValues.city = res.data.city;
        initialValues.state = res.data.state;
        initialValues.postalCode = res.data.pincode;
        initialValues.gender = res.data.gender.toLowerCase();
        initialValues.nationality = res.data.nationality;
        initialValues.country = getCode(res.data.countryName);
        initialValues.phone = `+${res.data.countryCode}${res.data.contactNumber}`;
      });
  }, []);

  const validationSchema = Yup.object({
    // // cardType: Yup.array()
    //   .min(1, "Pick atleast one card type")
    //   .of(
    //     Yup.object().shape({
    //       label: Yup.string().required(),
    //       value: Yup.string().required(),
    //     })
    //   ),
    title: Yup.string().required("Please enter a title"),
    firstName: Yup.string().required("Please enter a First Name"),
    lastName: Yup.string().required("Please enter a Last Name"),
    phone: Yup.string().required("Mobile cannot be blank"),
    dob: Yup.date().required("Date not selected"),
    // email: Yup.string().email().required("Please enter correct email"),
    gender: Yup.string().required("Please enter a gender"),
    mStatus: Yup.string().required("Please enter the status"),
    ePerson: Yup.string().required("Please enter the Emergency Contact"),
    ephone: Yup.string().required("Emergency Mobile cannot be blank"),
    passportNo: Yup.string().required("Passport Number cannot be blank"),
    pIssuedDate: Yup.date().required("Date not selected"),
    IDType: Yup.string().required("ID is required"),
    pExpiryDate: Yup.date().required("Date not selected"),
    IDCountry: Yup.string().required("Country cannot be blank"),
    address: Yup.string().required("Address cannot be blank"),
    city: Yup.string().required("City cannot be blank"),
    state: Yup.string().required("State cannot be blank"),
    postalCode: Yup.string()
      .test(
        "len",
        "Must be exactly 6 digits",
        (val) => val && val.toString().length === 6
      )
      .required("Postal Code cannot be blank"),
    country: Yup.string().required("Country cannot be blank"),
    mailAddress: Yup.string().required("Address cannot be blank"),
    mailCity: Yup.string().required("City cannot be blank"),
    mailState: Yup.string().required("State cannot be blank"),
    mailPostalCode: Yup.string().required("Postal Code cannot be blank"),
    nationality: Yup.string().required("Nationality cannot be blank"),
    mailCountry: Yup.string().required("Country cannot be blank"),
    // passport_biopage: Yup.mixed().required("Passport Biopage is required"),
    // selfie_passport_biopage: Yup.mixed().required("Selfie is required"),
  });
  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema}>
      {(formik) => <JDBApplication {...formik} />}
    </Formik>
  );
};

export default JDBProcess;
