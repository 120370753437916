import React from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import Dashboard from "./dashboard";

const validationSchema = Yup.object({
  amount: Yup.string().test(
    "len",
    "Minimum $100",
    (val) => val >= 100).required("Please enter valid amount"),
    
  otp: Yup.string().test(
    "len",
    "Minimum 6 digits",
    (val) => val && val.length() === 6).required("Please enter valid otp"),
});

const DashboardProcess = () => {
  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        amount: "",
        fee: 5,
        otp:""
      }}
    >
      {(formik) => <Dashboard {...formik} />}
    </Formik>
  );
};

export default DashboardProcess;
