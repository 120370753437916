import React, { useEffect, useState } from "react";
import Header from "../../components/header";
import { Container, Row, Col } from "bootstrap-4-react/lib/components/layout";
import { Image } from "react-bootstrap";
import { Button } from "bootstrap-4-react/lib/components";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import API_PATHS from "../../constants/apiPaths";
import { useSelector, useDispatch } from "react-redux";
import { getCookie } from "../../Utils/cookieHandling";
import { addIsKycApproved } from "../../redux/actions";
const KycHoldingPage = () => {
  const { email, is_kyc_approved } = useSelector((state) => state);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [accept, setAccept] = useState(is_kyc_approved);

  useEffect(() => {
    if(is_kyc_approved !=="1" || is_kyc_approved !=="0") {
    axios
      .get(API_PATHS.GET_DETAILS + email, {
        headers: {
          accepts: "application/json",
          Authorization: `${getCookie("c4")} ${getCookie("c3")}`,
        },
      })
      .then((res) => {
        const data = res.data;
        setAccept(data.is_kyc_approved);
        dispatch(addIsKycApproved(data.is_kyc_approved));
      });
    }
  },[]);
  return (
    <>
      <Header title="KYC Verification" />
      <Container fluid style={{ paddingTop: "8.125rem", paddingLeft: "3.125rem" }}>
        <Row>
          <Col>
            <span
              style={{ fontSize: "1.8125rem", fontWeight: "800", textAlign: "left" }}
            >
              {accept === null ? (
                <div>Your KYC Details Received Successfully</div>
              ) : accept === "1" ? (
                <div>Congratulations!!!</div>
              ) : accept === "0" ? (
                <div>Oops! Your KYC Has Been Rejected</div>
              ) : (
                <div></div>
              )}
            </span>
            <br />
            <br />
            <span
              style={{
                fontSize: "1.25rem",
                fontWeight: "500",
                textAlign: "left",
                paddingTop: "12.5rem",
              }}
            >
              {accept === null ? (
                <div> We are reviewing your profile</div>
              ) : accept === "1" ? (
                <div>Your KYC has been accepted.</div>
              ) : accept === "0" ? (
                <div>Your KYC has been rejected.</div>
              ) : (
                <div></div>
              )}
            </span>
            <br />
            {/* <span
              style={{ fontSize: "1.0625rem", color: "#585858", textAlign: "left" }}
            >
              {accept == null ? (
                <div>
                  {" "}
                  You'll be notified once your account is ready. <br />
                  This may take Up To 24 hours.
                </div>
              ) : accept == 1 ? (
                <div>Congratulations. Your KYC has been approved. </div>
              ) : (
                <div>
                  Your KYC has been rejected. Please contact us for further
                  details
                </div>
              )}
            </span> */}
          </Col>
          <Col>
            <Image src={require("../../assets/girl_dance.png")} width={300} style={{marginTop: "-6.125rem", marginRight:-20 }}/>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default KycHoldingPage;
