import { url } from "./url";

const API_PATHS = {
  OTP: `${url}/send-otp`,
  USER_REGISTER: `${url}/register`,
  USER_LOGIN: `${url}/login`,
  FORGOT_PASSWORD: `${url}/sendForgotPasswordEmail`,
  RESET_PASSWORD: `${url}/changePassword`,
  GET_DETAILS: `${url}/get_userdetails/`,
  NIUM_USER_DETAILS: `${url}/get_NiumUserDetails/`,
  KYC_REGISTER: `${url}/kyc`,
  UPDATE_OTP: `${url}/sendupdateprofile_OTP`,
  UPDATE_EMAIL: `${url}/update-email`,
  UPDATE_MOBILE: `${url}/update-mobile`,
  UPDATE_PASSWORD: `${url}/update-password`,
  UPDATE_WALLET_PIN: `${url}/update-wallet-pin`,
  UPDATE_PROFILE_PIC: `${url}/update-profile-pic`,
  JDB_APPLY: `${url}/applyForCard`,
  NIUM_APPLICATION_SUBMIT: `${url}/applyForNuimCard`,
  STRIPE: `${url}/stripe-integration`,
  CARD_LOAD: `${url}/stripe-cardload`,
  USER_VERIFY: `${url}/getemail/`,
  SECRET_KEY_GOOGLE: `${url}/signup_googleAuth`,
  VERIFY_GOOGLE_OTP: `${url}/verify_googleAuth`,
  VERIFY_CARD_NUMBER: `${url}/verifyCard/`,
  SS0_REGISTER: `${url}/ssoregister`,
  UPDATE_ASCII: `${url}/updateascii/`,
  ADMIN_USER: `${url}/admin-user/`,

  SINGPASS_CREATE_LINK: `${url}/singpass/url`,
  SINGPASS_GET_DETAILS: `${url}/singpass/my-info?state=`,
  SINGPASS_USER: `${url}/signpass-user/`,
  VERIFY_MAIL: `${url}/verify-email`,

  BOUNDLESS_BALANCE: `${url}/boundless_getCardBalance/`,
  GET_TRANSACTIONS: `${url}/user-transaction/`,
  GET_CARD_TRANSACTIONS: `${url}/boundless_getCardTransactions/`,
};

export default API_PATHS;
