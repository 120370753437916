import React, { useState, useEffect } from "react";
import { MdOutlineDashboard } from "react-icons/md";
import { AiOutlineCreditCard } from "react-icons/ai";
import { CgProfile } from "react-icons/cg";
import { BsPeople } from "react-icons/bs";
import { FaBars } from "react-icons/fa";
import { Navigate, NavLink, useNavigate } from "react-router-dom";
import { Image } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { IoIosArrowUp } from "react-icons/io";
// import { createBrowserHistory } from "history";
import { Row, Col, Container } from "bootstrap-4-react";
import Tooltip from "react-bootstrap/Tooltip";
import { useSelector } from "react-redux";
// const history = createBrowserHistory();

const SideBar = ({ children }) => {
  const { kyc, card_path } = useSelector((state) => state);
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const [isSubMenu, setIsSubMenu] = useState(false);
  // const [kycPath, setKycPath] = useState(
  //   kyc == true ? "/logged/kycHold" : "/logged/kyc"
  // );
  // useEffect(() => {
  //   setKycPath(kyc == true ? "/logged/kycHold" : "/logged/kyc");
  // });

  const [isApplied, setIsApplied] = useState("");

  const renderTooltip = (card) => (
    <Tooltip id="button-tooltip">
      {card === "NIUM" ? "Coming Soon" : card}
    </Tooltip>
  );

  const menuItems = [
    {
      path: "/logged/dashboard",
      name: "Dashboard",
      icon: <MdOutlineDashboard size={20} />,
    },
    {
      path: card_path ? card_path : "/logged/cardsProgram",
      name: "Cards",
      icon: <AiOutlineCreditCard size={20} />,
      contact: [
        {
          card: "JDB",
          path1: card_path ? card_path : "/logged/card/jdb",
        },
        {
          card: "NIUM",
          path1: "/logged/card/nium",
        },
      ],
    },
    {
      path: "/logged/profile",
      name: "Profile",
      icon: <CgProfile size={20} />,
    },
    {
      path: kyc === true ? "/logged/kycHold" : "/logged/kyc",
      name: "KYC",
      icon: <BsPeople size={20} />,
    },
  ];

  const handleClick = () => {
    setIsSubMenu(!isSubMenu);
  };

  const handleCard = (cardPath) => {
    navigate("/logged/card/jdb");
  };

  const handleCllick = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col lg={isOpen ? 2 : 1} sm={isOpen ? 3 : 1}>
            <div style={{ width: isOpen ? "50%" : "20%" }} className="sidebar">
              <div className="top_section"
                    onClick={toggle}>
                <h1
                  style={{ display: isOpen ? "block" : "none" }}
                  className="logo"
                >
                  <Image
                    src={require("../assets/paymentPlanet/PaymentP.png")}
                    width={160}
                  />
                </h1>
                <div
                  style={{ marginLeft: isOpen ? "3%" : "0%" }}
                  className="bars"
                >
                  <FaBars
                    style={{ cursor: "pointer" }}
                    size={25}
                  />
                </div>
              </div>
              {menuItems.map((item, index) => (
                <NavLink
                  to={item.path}
                  key={index}
                  className="link"
                  activeclassname="active"
                >
                  <div className="icon">{item.icon}</div>
                  <div
                    style={{ display: isOpen ? "block" : "none" }}
                    className="link_text"
                  >
                    {item.name}
                  </div>
                  {item.contact ? (
                    <div style={{ display: isOpen ? "block" : "none" }}>
                      <IoIosArrowUp
                        size={35}
                        style={{ paddingTop: "-5px", paddingLeft: "15px" }}
                        onClick={() => handleClick(item.contact)}
                      />
                      {item.contact && (
                        <div
                          style={{
                            display: isSubMenu ? "block" : "none",
                          }}
                          className="submenu"
                        >
                          {item.contact.map((a, i) => (
                            <>
                              <OverlayTrigger
                                placement="right"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltip(a.card)}
                              >
                                <NavLink
                                  onClick={
                                    a.card === "NIUM" ? handleCllick : null
                                  }
                                  to={a.path1}
                                  key={i}
                                  className="sub-options"
                                  style={{
                                    cursor: "pointer",
                                    padding: "12px",
                                    color: a.card === "NIUM" ? "grey" : "",
                                  }}
                                >
                                  {a.card}
                                  <br />
                                </NavLink>
                              </OverlayTrigger>
                            </>
                          ))}
                        </div>
                      )}
                    </div>
                  ) : null}
                </NavLink>
              ))}
            </div>
          </Col>
          <Col
            lg={isOpen ? 10 : 11}
            sm={isOpen ? 9 : 11}
            style={{ right: 0, margin: 0, padding: 0 }}
          >
            <main className="container-fluid">{children}</main>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default SideBar;
