import React, { useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";

const GlobalFilter = ({ filter, setFilter }) => {
  const [value, setValue] = useState(filter);
  const onChange = (value) => {
    setFilter(value);
  };
  return (
    <div
      style={{
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%",
        display: "flex",
      }}
    >
      <h3>Transaction History</h3>
      <Form.Control
        placeholder="Search Here"
        type="text"
        style={{
          width: "250px",
        }}
        value={filter || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
      />
    </div>
  );
};

export default GlobalFilter;
