export const Kycfields = [
  { value: "firstName", label: "First Name" },
  { value: "lastName", label: "Last Name" },
  { value: "dob", label: "Date Of Birth" },
  { value: "phone", label: "Mobile Number" },
  { value: "email", label: "Email Address" },
  { value: "gender", label: "Gender" },
  { value: "address1", label: "Address 1" },
  { value: "address2", label: "Address 2" },
  { value: "city", label: "City" },
  { value: "state", label: "State" },
  { value: "country", label: "Country" },
  { value: "postalCode", label: "Postal Code" },
  { value: "nationality", label: "Nationality" },
  { value: "selfie", label: "Selfie Image With Passport Biopage" },
  { value: "photoIdFront", label: "Photo ID Front" },
  { value: "photoIdBack", label: "Photo ID Back" },
  { value: "addressProof", label: "Proof Of Address" },
];
