import { useState, useEffect, useMemo } from "react";
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useSortBy,
} from "react-table";
import { Container, Col, Row, Modal, Button } from "react-bootstrap";
import GlobalFilter from "./globalFilter";
import { Form } from "bootstrap-4-react";
import "./dashboard.css";
import ActiveCards from "../activecards/activeCards";
import Header from "../../components/header";
import SvgCard from "../../assets/cardsvg.jsx";
import API_PATHS from "../../constants/apiPaths";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { getCookie } from "../../Utils/cookieHandling";
import moment from "moment";
import { DOTS, useCustomPagination } from "./useCustomPagination";
import {
  BsFillArrowUpSquareFill,
  BsFillArrowDownSquareFill,
} from "react-icons/bs";
toast.configure();

const Dashboard = (formik) => {
  const { email, userType, accessToken } = useSelector((state) => state);
  const navigate = useNavigate();
  const { values, touched, dirty, isValid, errors } = formik;
  const [isActive, setIsActive] = useState(false);
  const [data, setData] = useState();
  const [show, setShow] = useState(false);
  const [balance, setBalance] = useState(0);
  const [tableData, setTableData] = useState([]);

  const COLUMNS = [
    {
      Header: "Name Of Transaction",
      accessor: "description",
      Cell: (d) => {
        return (
          <div style={{ width: "70%" }}>
            {`${d.data[d.row.id].description}`}
          </div>
        );
      },
    },
    {
      Header: "Date",
      accessor: "executed_at",
      Cell: (d) => {
        return (
          <div>{moment(d.data[d.row.id].executed_at).format("DD/MM/YYYY")}</div>
        );
      },
    },
    {
      Header: "Time",
      Cell: (d) => {
        return (
          <div>{moment(d.data[d.row.id].executed_at).format("HH:MMA")}</div>
        );
      },
    },
    {
      Header: "Debit",
      accessor: "dr_amount",
      Cell: (d) => {
        return (
          <div style={{ textAlign: "left", paddingLeft: 10 }}>
            {d.data[d.row.id].dr_amount === 0
              ? ""
              : `${d.data[d.row.id].dr_amount}`}
          </div>
        );
      },
    },
    {
      Header: "Credit",
      accessor: "cr_amount",
      Cell: (d) => {
        return (
          <div style={{ textAlign: "left", paddingLeft: 10 }}>
            {d.data[d.row.id].cr_amount === 0
              ? ""
              : `${d.data[d.row.id].cr_amount}`}
          </div>
        );
      },
    },
    {
      Header: "Balance",
      accessor: "end_bal",
      Cell: (d) => {
        return (
          <div style={{ textAlign: "left", paddingLeft: 10 }}>
            {`${d.data[d.row.id].end_bal}`}
          </div>
        );
      },
    },
  ];
  const columns = useMemo(() => COLUMNS, []);
  const {
    getTableProps,
    defaultColumn,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    prepareRow,
    state,
    setGlobalFilter,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
  } = useTable(
    {
      columns,
      data: tableData,
      initialState: {
        sortBy: [
          {
            id: "executed_at",
            desc: true,
          },
        ],
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const { pageSize, globalFilter } = state;

  const { pageIndex } = state;
  const paginationRange = useCustomPagination({
    totalPageCount: pageCount,
    currentPage: pageIndex,
  });

  useEffect(() => {
    axios
      .get(API_PATHS.GET_DETAILS + email, {
        headers: {
          accepts: "application/json",
          Authorization: `${getCookie("c4")} ${getCookie("c3")}`,
        },
      })
      .then((res) => {
        if (
          res.data.jdbCardNumber1 &&
          (res.data.card_activated === 1 || res.data.card_activated === 2)
        ) {
          setData(res.data);
          setIsActive(true);
          axios
            .get(API_PATHS.BOUNDLESS_BALANCE + res.data.jdbCardNumber1, {
              headers: {
                Authorization: `${getCookie("c4")} ${getCookie("c3")}`,
              },
            })
            .then((res1) => {
              if (res1?.data?.balance) {
                setBalance(res1.data.balance);
              }
            });
          axios
            .get(API_PATHS.GET_CARD_TRANSACTIONS + res.data.jdbCardNumber1, {
              headers: {
                accepts: "application/json",
                Authorization: `${getCookie("c4")} ${getCookie("c3")}`,
              },
            })
            .then((res2) => {
              if (res2.data[0]) {
                setTableData(res2.data);
              }
            });
        }
      });
  }, []);
  const handleClose = () => {
    values.amount = "";
    setShow(false);
  };
  const handleConfirm = () => {
    if (values.amount < 100) {
      toast.error("Minimum amount is 100");
      return;
    }
    const obj = {
      amount: Math.round(Number(values.amount) * (100 + values.fee)) / 100,
      load_amount: values.amount,
      percentage_amount: Math.round(Number(values.amount * values.fee)) / 100,
    };
    navigate("/logged/card/cardLoad", { state: { applicationDetails: obj } });
    setShow(false);
  };
  const getCellValue = (cell) => {
    // console.log(cell.original);
  };

  return (
    <>
      <Header title="Dashboard" />
      <Container fluid>
        <h3 style={{ padding: "15px" }}>Active Cards</h3>
        {!isActive && <ActiveCards />}
        <div style={{ textAlign: "center" }}>
          {isActive && (
            <>
              <Col>
                <SvgCard />
              </Col>
              <Col style={{ top: "-40px", justifyContent: "space-between" }}>
                Card Balance &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {balance === 0 || balance === null || balance === undefined ? (
                  <span style={{ color: "grey" }}>
                    {balance === null ? 0 : balance} USD
                  </span>
                ) : (
                  <span style={{ color:"black" }}>{balance === null ? 0 : balance} USD</span>
                )}
              </Col>
              <Col style={{ top: "-20px", left: 10 }}>
                <Button
                  id="Load-button"
                  onClick={(e) => setShow(true)}
                  style={{ marginBottom: "2.5rem" }}
                >
                  Load
                </Button>
              </Col>
            </>
          )}
          {/* <h3 style={{ marginBottom: "50px", textAlign: "left" }}>
            Transaction History
          </h3> */}
          {/* <div className="transdata">No data </div> */}
          {tableData.length != 0 ? (
            <div>
              <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
              <div
                className="container"
                style={{
                  fontSize: "1.2vw",
                  border: ".1875rem solid #dddddd",
                  minWidth: "none",
                  maxWidth: "none",
                  width: "100%",
                }}
              >
                <table {...getTableProps()}>
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                            style={{
                              padding: "10px",
                              fontSize: "1.3vw",
                              fontWeight: "bold",
                              minWidth: "none",
                              maxWidth: "none",
                              textAlign: "left",
                            }}
                          >
                            {column.render("Header")}
                            <span
                              style={{
                                marginLeft: "2px",
                                fontSize: "1.3vw",
                                textAlign: "left",
                              }}
                            >
                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <BsFillArrowUpSquareFill />
                                ) : (
                                  <BsFillArrowDownSquareFill />
                                )
                              ) : (
                                ""
                              )}
                            </span>
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell) => {
                            return (
                              <td
                                {...cell.getCellProps()}
                                onClick={() => getCellValue(row)}
                              >
                                {cell.render("Cell")}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="py-3 flex items-center text-center pt-10 all-buttons">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    justifyContent: "center",
                  }}
                >
                  <button
                    onClick={() => previousPage()}
                    disabled={!canPreviousPage}
                    id="inactive-Button"
                    style={{
                      color: "#E02E39",
                      fontSize: 21,
                    }}
                  >
                    {"<"}
                  </button>
                  <div
                    // className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between"
                    aria-label="Pagination"
                  >
                    <div
                      // className="relative z-0 inline-flex items-center ml-auto mr-auto rounded-md shadow-sm space-x-10"
                      aria-label="Pagination"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                      }}
                    >
                      {paginationRange?.map((pageNumber, index) => {
                        if (pageNumber === DOTS) {
                          return (
                            <div
                              key={index}
                              style={{
                                width: 30,
                                paddingLeft: 10,
                              }}
                            >
                              ...
                            </div>
                          );
                        }

                        if (pageNumber - 1 === pageIndex) {
                          return (
                            <div
                              key={index}
                              // className=" active:bg-gray-500 active:border-gray-300"
                              onClick={() => gotoPage(pageNumber - 1)}
                              style={{
                                width: 30,
                                paddingLeft: 10,
                                paddingRight: 10,
                              }}
                              id="page-Button"
                            >
                              {pageNumber}
                            </div>
                          );
                        }

                        return (
                          <div
                            key={index}
                            className="active:bg-gray-500 active:border-gray-300"
                            onClick={() => gotoPage(pageNumber - 1)}
                            style={{
                              width: 30,
                              paddingLeft: 10,
                              paddingRight: 10,
                            }}
                            id="inactive-Button"
                          >
                            {pageNumber}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <button
                    onClick={() => nextPage()}
                    disabled={!canNextPage}
                    id="inactive-Button"
                    style={{ color: "#E02E39", fontSize: 21 }}
                  >
                    {">"}
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="transdata">No Transaction History</div>
          )}
          <div style={{ marginBottom: 50 }}></div>
        </div>
      </Container>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Verify</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Row style={{ margin: "5px", fontSize: "17px" }}>
              <Col style={{ textAlign: "left" }}>
                <p>Card Number :</p>
              </Col>
              <Col>
                <p>
                  {data ? data.jdbCardNumber1.replace(/(\d{4})/g, "$1  ") : ""}
                </p>
              </Col>
            </Row>
            <Row style={{ margin: "5px", fontSize: "17px" }}>
              <Col style={{ textAlign: "left" }}>
                <p>USD To Load : </p>
              </Col>
              <Col>
                <Form.Input
                  type="number"
                  value={values.amount}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  id="amount"
                  name="amount"
                  className={
                    errors.amount && touched.amount
                      ? "text-input-error"
                      : "text-input"
                  }
                />
                {errors.amount && touched.amount && (
                  <div style={{ color: "red", fontSize: 14 }}>
                    {errors.amount}
                  </div>
                )}
              </Col>
            </Row>
            <Row style={{ margin: "5px", fontSize: "17px" }}>
              <Col style={{ textAlign: "left" }}>
                <p>Total Amount :</p>
              </Col>
              <Col>
                <p style={{ textAlign: "right" }}>
                  {values.amount
                    ? Math.round(Number(values.amount) * (100 + values.fee)) /
                      100
                    : 0}
                </p>
              </Col>
            </Row>
            <Row style={{ margin: "5px", fontSize: "17px" }}>
              <Col style={{ textAlign: "center", color: "red" }}>
                <p>Note : Card Load Fee of 5% applies</p>
              </Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleConfirm}
            // disabled={values.amount < 100}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer />
    </>
  );
};

export default Dashboard;
