import React, { useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import UpdateEmail from "./updateEmail";
import { useSelector } from "react-redux";

const UpdateEmailUpdate = () => {
  const { email } = useSelector((state) => state);
  const initialValues = {
    email: email,
    newEmail: "",
    confirmEmail: "",
    otp: "",
  };

  const validationSchema = Yup.object({
    email: Yup.string().email().required("Email is required"),
    newEmail: Yup.string().email().required("Please enter new email"),
    confirmEmail: Yup.string()
      .oneOf([Yup.ref("newEmail"), null], "Email must match")
      .required("Confirm the Email"),
    otp: Yup.number()
      .typeError("you must specify a number")
      .test(
        "len",
        "Must be exactly 4 digits",
        (val) => val && val.toString().length === 4
      )
      .required("Enter the verification code"),
  });
  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema}>
      {(formik) => <UpdateEmail {...formik} />}
    </Formik>
  );
};

export default UpdateEmailUpdate;
