import React, { useState, useEffect } from "react";
import { Card, Container, Row, Col } from "react-bootstrap";
import axios from "axios";
import API_PATHS from "../../constants/apiPaths";
import Header from "../../components/header";
import { NIUMPrograms } from "../../data/nium";
import CardProgramDisplay from "../../components/cardDisplay";
import { useNavigate } from "react-router-dom";
import CardFullDetails from "../../components/cardFullDetails";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";

const NIUMProgram = () => {
  const { email } = useSelector((state) => state);
  const [cardPurchased, setCardPurchased] = useState(false);
  const [jdbData, setJdbData] = useState([]);
  const [path, setPath] = useState();
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const navigate = useNavigate();

  const handleClose = () => {
    setShow(false);
    navigate("/logged/kyc");
  };
  const handleClose1 = () => {
    setShow1(false);
    navigate("/logged/cardsProgram");
  };
  useEffect(() => {
    setShow1(true);
  });

  return (
    <>
      <Header title="NIUM Program" />
      <Container fluid>
        <CardProgramDisplay
          data={NIUMPrograms}
          pathName={path}
          purchased={{ cardPurchased }}
        />
        <CardFullDetails purchased={{ cardPurchased }} />
      </Container>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Kyc not Registered</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Please register your Kyc before proceeding further
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ textAlign: "center" }}>
          .NIUM Cards will be available soon.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default NIUMProgram;
