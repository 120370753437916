import React from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import ResetPassword from "./resetPassword"

const ResetPasswordProcess = () => {


  const validationSchema = Yup.object({
    password: Yup.string()
      .min(6, "Password must be 6 characters")
      .required("Please enter password"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Password must match")
      .required("Confirm the password"),
    emailVerify: Yup.string().required("Enter the verification code"),
  });

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        password: "",
        confirmPassword: "",
        emailVerify: "",
      }}
    >
      {(formik) => <ResetPassword {...formik} />}
    </Formik>
  );
};

export default ResetPasswordProcess;
