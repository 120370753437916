import React, { useState, useEffect } from "react";
import { Card, Container, Row, Col } from "react-bootstrap";
import Header from "../../components/header";
import { JDBPrograms } from "../../data/jdbPrograms";
import CardProgramDisplay from "../../components/cardDisplay";
import CardFullDetails from "../../components/cardFullDetails";
import axios from "axios";
import API_PATHS from "../../constants/apiPaths";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getCookie } from "../../Utils/cookieHandling";
const JDBProgram = () => {
  const { email, userType, accessToken, card_activated, kyc } = useSelector(
    (state) => state
  );
  const [cardPurchased, setCardPurchased] = useState(card_activated);
  const [path, setPath] = useState();
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  const handleClose = () => {
    setShow(false);
    navigate("/logged/kyc");
  };
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (card_activated) {
      setCardPurchased(true);
    }
    if (!kyc) {
      setShow(true)
    }
  });

  useEffect(() => {
    if (email) {
      axios
        .get(API_PATHS.GET_DETAILS + email, {
          headers: {
            accepts: "application/json",
            Authorization: `${getCookie("c4")} ${getCookie("c3")}`,
          },
        })
        .then((res) => {
          const data = res.data;
          // if (data.card_activated === (1 || 2)) {
          //   setCardPurchased(true);
          // }
          const path12 = data.kyc ? setShow(false) : setShow(true);
          data.payment_status === "paid"
            ? data.is_kyc_approved == "1"
              ? data.card_activated === 1 || data.card_activated === 2
                ? setPath("/logged/card/cardSuccess1")
                : setPath("/logged/card/cardVerify")
              : setPath("/logged/kycHold")
            : data.payment_status === "pending"
              ? setPath("/logged/card/pendingPayment")
              : setPath("/logged/card/jdbApply");
        });
    }
  }, []);

  return (
    <>
      <Header title="JDB Program" />
      <Container fluid>
        <CardProgramDisplay
          data={JDBPrograms}
          pathName={path}
          purchased={{ cardPurchased }}
        />
        <CardFullDetails purchased={{ cardPurchased }} />
      </Container>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Kyc not Registered</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Please register your Kyc before proceeding further
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default JDBProgram;
