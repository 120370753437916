import React from "react";
import "./termsCondition.css";
import { Container } from "react-bootstrap";
function TermsConditions() {
  return (
    <div
      className="App"
      style={{
        padding: "1.5rem",
        margin: "5px",
      }}
    >
      <div className="pp1">
        <h4 className="titlle">Terms and Conditions will be attached soon.</h4>
      </div>
    </div>
  );
}
export default TermsConditions;
